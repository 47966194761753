import { useEffect, useRef, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Spinner from "../../feature/Spinner";
import Translated from "../../feature/Translated";
import Cart from "../../feature/shop/Cart";
import CustomerInput from "../../feature/shop/CustomerInput";
import PaymentMethods from "../../feature/shop/PaymentMethods";
import Button from "react-bootstrap/Button";
import ContactForm from "../../feature/ContactForm";
import LocalStorageUtil from "../../../utils/localStorageUtil";
import Collapse from "react-bootstrap/Collapse";
import TrackerAB from "../../../utils/trackerAB";
import Scroll from "react-scroll";
import ProductDetails from "../../feature/shop/ProductDetails";
import ShopPaymentTermsNote from "../../feature/shop/ShopPaymentTermsNote";
import ShopCancellingTermsNote from "../../feature/shop/ShopCancellingTermsNote";
import { Navigate, useNavigate } from "react-router-dom";
import { useTranslation } from "../../feature/TranslationProvider";
import { useGlobalData } from "../../../context/GlobalContext";
import usePendingOrderCheck from "../../../hooks/usePendingOrderCheck";
import { usePurchaseProducts } from "../../../api-new";

export const PRODUCT_PAGE_ID = "product_page";
const SCROLL_OPTIONS = { smooth: false, duration: 1000 };

const ProductPage = () => {
  usePendingOrderCheck();
  const { pageData } = useGlobalData();
  const { lang } = useTranslation();
  const navigate = useNavigate();
  const [cart, setCart] = useState([]);
  const [customerData, setCustomerData] = useState(null);
  const [isPurchaseStarted, setIsPurchaseStarted] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [paymentMethodsError, setPaymentMethodsError] = useState(null);
  const [order, setOrder] = useState(null);
  const [showContactForm, setShowContactForm] = useState(false);
  const [contactFormSubmitted, setContactFormSubmitted] = useState(false);
  const [showPaymentTypes, setShowPaymentTypes] = useState(true);
  const [paymentBannerUrl, setPaymentBannerUrl] = useState("");
  const paymentBannerTimeoutRef = useRef(null);
  const product = pageData[PRODUCT_PAGE_ID];
  const productMutation = usePurchaseProducts();

  useEffect(() => {
    window.scrollTo({ top: 0 });
    paymentBannerTimeoutRef.current = setTimeout(
      () =>
        setPaymentBannerUrl(
          "https://www.paytrail.com/hs-fs/hub/335946/file-493287103.png?width=300&name=file-493287103.png"
        ),
      5000
    );
    return () => clearTimeout(paymentBannerTimeoutRef.current);
  }, []);

  useEffect(() => {
    if (customerData) {
      startPurchase();
    }
  }, [customerData]);

  const startPurchase = () => {
    TrackerAB.track("webshop-start-purchase");
    setIsPurchaseStarted(true);
    setPaymentMethodsError(null);
    window.scrollTo({ top: 0 });


    productMutation.mutate(
      {
        filledProducts: cart,
        customerData,
        lang,
      },
      {
        onSuccess: (data) => {
          LocalStorageUtil.setForUser("anon", "pending-order", {
            id: data.order.id,
            token: data.order.token,
          });

          setPaymentMethods(data.paymentMethods);
          setOrder(data.order);
        },
        onError: (error) => {
          setPaymentMethodsError(error.message);
          setIsPurchaseStarted(false);
        },
      }
    );
  };

  const cancelPurchase = () => {
    TrackerAB.track("webshop-cancel-purchase");
    setIsPurchaseStarted(false);
  };

  const closePage = () => {
    navigate("/kauppa");
  };

  const onSetCustomerInput = (customerData) => {
    TrackerAB.track("webshop-customer-data-input");
    setCustomerData(customerData);
  };

  const onAddToCart = (product, inputs) => {
    TrackerAB.track("webshop-product-added-to-cart");
    const filledProduct = Object.assign({}, product, { inputs });
    setCart((prev) => [...prev, filledProduct]);
    Scroll.animateScroll.scrollTo("webshop-products", SCROLL_OPTIONS);
  };

  const onRemoveFromCart = (product) => {
    TrackerAB.track("webshop-product-removed-from-cart");
    const newCartState = cart.slice();
    for (let i = newCartState.length - 1; i >= 0; i--) {
      let p = newCartState[i];
      if (p.id === product.id) {
        newCartState.splice(i, 1);
      }
    }
    setCart(newCartState);
  };

  const renderContactForm = () => {
    const buttonKey = showContactForm ? "cancel" : "contact";
    let content;
    if (showContactForm) {
      content = (
        <div className="top-margin contact-wrapper white-box slide-in">
          <ContactForm
            email
            onSubmitted={() => {
              setContactFormSubmitted(true);
              setShowContactForm(false);
            }}
          />
        </div>
      );
    } else if (contactFormSubmitted) {
      content = <Translated translationKey="message_sent" />;
    }

    return (
      <div className="large-top-margin webshop-contact-wrapper">
        <Button
          aria-label="Send feedback"
          role="presentation"
          onClick={() => {
            setShowContactForm((prev) => !prev);
            setContactFormSubmitted(false);
          }}
        >
          <Translated translationKey={buttonKey} />
        </Button>
        {content}
      </div>
    );
  };

  const renderProducts = () => {
    return (
      <div className="medium-top-margin medium-bottom-margin">
        <ProductDetails
          className="slide-in"
          product={product}
          onCancel={closePage}
          onAddToCart={onAddToCart}
        />
      </div>
    );
  };

  const renderStateCart = () => {
    let error = null;
    if (paymentMethodsError) {
      error = (
        <Alert bsstyle="danger">
          <Translated translationKey={paymentMethodsError} />
        </Alert>
      );
    }
    return (
      <div>
        <Cart products={cart} onRemoveFromCart={onRemoveFromCart} />
        <CustomerInput
          className="slide-in"
          setInput={onSetCustomerInput}
          data={customerData}
        />
        {error}
      </div>
    );
  };

  const renderStatePayment = () => {
    if (paymentMethodsError) {
      return (
        <Alert bsstyle="danger">
          <Translated translationKey={paymentMethodsError} />
        </Alert>
      );
    }
    return (
      <div>
        {paymentMethods && order ? (
          <PaymentMethods
            className="slide-in"
            paymentMethods={paymentMethods}
            order={order}
          />
        ) : (
          <Spinner />
        )}
        <Button
          aria-label="Cancel"
          role="presentation"
          onClick={cancelPurchase}
        >
          <Translated translationKey="cancel" />
        </Button>
      </div>
    );
  };

  const renderPaymentBanner = () => {
    if (showContactForm || isPurchaseStarted) {
      return null;
    }

    const banner = (
      <div className="semi-dark-inner-box tight fade-in delay top-margin-small">
        {showPaymentTypes ? null : (
          <div className="centered">
            <Button
              bsstyle="link"
              aria-label="Show payment types"
              role="presentation"
              onClick={() => {
                TrackerAB.track("webshop-show-payment-types");
                setShowPaymentTypes(true);
              }}
            >
              <Translated translationKey="payment_types" />
              ...
            </Button>
          </div>
        )}
        <Collapse in={showPaymentTypes}>
          <div className="top-margin">
            <div className="centered fade-in">
              <Translated translationKey="payment_types" className="strong" />
              <br />
              {paymentBannerUrl && (
                <img
                  src={paymentBannerUrl}
                  style={{ width: 120, height: 120 }}
                  className="fade-in slide-in"
                />
              )}
              <br />
              <Translated translationKey="payment_operator_info" />
              <ShopPaymentTermsNote /> <ShopCancellingTermsNote />
            </div>
          </div>
        </Collapse>
      </div>
    );

    return banner;
  };

  const renderCompany = () => {
    return (
      <div
        className="centered large-top-margin semi-transparent touchable"
        onClick={() => navigate("/privacy-policy")}
      >
        <strong>Ajokaista Oy</strong>
        <br />
        Katajapolku 14
        <br />
        00780 Helsinki
      </div>
    );
  };

  const hasItemsInCart = () => {
    return cart && cart.length > 0;
  };

  const renderSplittedInfo = () => {
    let stateContent;

    if (isPurchaseStarted) {
      // Has filled information and is making a payment
      stateContent = renderStatePayment();
    } else if (hasItemsInCart()) {
      // Has something in cart, show cart and customer details form
      stateContent = renderStateCart();
    } else {
      // Has not chosen product(s), show all products
      stateContent = renderProducts();
    }

    return (
      <div id="shop-page" className="page-container max-width-container">
        <Scroll.Element name={"webshop-products"}>
          {stateContent}
        </Scroll.Element>
        {renderPaymentBanner()}
        <div className="vertical-middle even phone-column">
          {renderContactForm()}
          {renderCompany()}
        </div>
      </div>
    );
  };

  if (!product) {
    return <Navigate to="/kauppa" replace={true} />;
  }

  return renderSplittedInfo();
};

export default ProductPage;
