class LocalStorageUtil {
  static getForUser(userId, propName) {
    try {
      let catObj = JSON.parse(localStorage.getItem(propName));
      if (!catObj) {
        return null;
      }
      const cat = catObj[userId];
      return cat ? cat : null;
    } catch (err) {
      LocalStorageUtil.setForUser(propName, userId, null);
      return null;
    }
  }

  static setForUser(userId, propName, value) {
    let catObj;
    try {
      catObj = JSON.parse(localStorage.getItem(propName));
    } catch (e) {
      catObj = {};
    }

    if (!catObj) {
      catObj = {};
    }

    try {
      catObj[userId] = value;
      localStorage.setItem(propName, JSON.stringify(catObj));
    } catch (error) {
      // Do nothing, probably in private browser mode...
    }
  }

  static setToken(token) {
    try {
      if (!token) {
        return localStorage.removeItem("token");
      }
      localStorage.setItem("token", token);
    } catch (error) {
      // Most likely private browsing
      console.warn(
        "Error setting token (Please avoid private browsing): " + error
      );
    }
  }

  static getToken() {
    try {
      return localStorage.getItem("token");
    } catch (error) {
      // Most likely private browsing
      console.warn("Error getting token from local storage " + error);
      return null;
    }
  }

  /**
   * Vehicle category is stored locally, so that users do not need to select the category on every login.
   * Multiple users share same object
   */
  static getLocallyStoredCategory(userId) {
    try {
      let catObj = JSON.parse(localStorage.getItem("vehicle_category"));
      if (!catObj) {
        return null;
      }
      const cat = catObj[userId];
      return cat ? cat : null;
    } catch (err) {
      setLocallyStoredCategory(null, userId);
      return null;
    }
  }

  static setLocallyStoredCategory(category, userId) {
    let catObj;
    try {
      catObj = JSON.parse(localStorage.getItem("vehicle_category"));
    } catch (e) {
      catObj = {};
    }

    if (!catObj) {
      catObj = {};
    }

    try {
      catObj[userId] = category;
      localStorage.setItem("vehicle_category", JSON.stringify(catObj));
    } catch (error) {
      // Do nothing, probably in private browser mode...
    }
  }

  static getPersistedLocaleCode = () => {
    return localStorage.getItem("localeCode");
  };

  static setPersistedLocaleCode(localeCode) {
    try {
      localStorage.setItem("localeCode", localeCode);
    } catch (error) {
      // Most likely private browsing
    }
  }

  static getAllLocalSettingsObj() {
    try {
      let catObj = JSON.parse(localStorage.getItem('vehicle_category'));
      if (!catObj){
        return {};
      }
      return catObj;
    } catch (err) {
      return {}
    }
  }
}

export default LocalStorageUtil;

