import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";

import LoginFormEmail from "../feature/LoginFormEmail";
import Translated from "../feature/Translated";
import News from "../feature/News";
import LicenseAgreementNote from "../feature/LicenseAgreementNote";
import { useUserData } from "../../context/UserDataContext";

const LoginTeacherPage = () => {
  const { token } = useUserData();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (token) {
      navigate("/", { state: location.state, replace: true });
    }
  }, [token]);

  return (
    <div
      id="teacher-login-page"
      className="page-container page-gutter max-width-container"
    >
      <div className="front-center-wrapper top-margin">
        <h1>
          <Translated translationKey="teacher" />
        </h1>
        <div className="login-form-container fade-in">
          <LoginFormEmail
            key="email-form"
            header={<Translated translationKey="login_teacher" />}
          />
          <br />
          <div className="semi-dark-inner-box medium-top-margin">
            <Button
              aria-label="Request trial"
              role="button"
              bsstyle="link"
              onClick={() => navigate("/trial")}
            >
              <Translated translationKey="trial_request" />
            </Button>
          </div>
        </div>
        <News simple className="large-top-margin fade-in long-delay" />
      </div>
      <LicenseAgreementNote className="semi-transparent large-top-margin bottom-margin self-centered" />
    </div>
  );
};

export default LoginTeacherPage;
