import { useState } from "react";
import Translated from "../feature/Translated";
import Card from "react-bootstrap/Card";
import { IoMail } from "react-icons/io5";
import Button from "react-bootstrap/Button";
import ContactForm from "../feature/ContactForm";
import DataPrivacyNote from "../feature/DataPrivacyNote";
import { useUserData } from "../../context/UserDataContext";
import { useNavigate } from "react-router-dom";

const ContactPage = () => {
  const { token } = useUserData();
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  const renderForm = () => {
    const header = (
      <div className="centered">
        <Translated translationKey="contact" />
      </div>
    );

    const trial = !token && (
      <div className="semi-dark-inner-box large-top-margin">
        <div>
          <Translated translationKey="contact_description" />
        </div>
        <Button
          className="medium-top-margin"
          aria-label="Request trial"
          role="button"
          onClick={() => navigate("/trial")}
        >
          <Translated translationKey="trial_request" />
        </Button>
      </div>
    );

    return (
      <div id="contact-page" className="contact-wrapper fade-in">
        <Card className="contact-panel" header={header}>
          <Card.Body>
            <ContactForm
              name
              phone
              email
              onSubmitted={() => setSubmitted(true)}
            />
          </Card.Body>
        </Card>
        {trial}
      </div>
    );
  };

  const renderSubmittedMessage = () => {
    return (
      <div id="contact-submitted" className="contact-wrapper fade-in">
        <Card className="blue-box">
          <h3 className="centered">
            <IoMail />
            &nbsp;&nbsp;
            <Translated translationKey="message_sent" />
          </h3>
          <Button
            aria-label="ok"
            role="button"
            onClick={() => setSubmitted(false)}
          >
            <Translated translationKey="ok" />
          </Button>
        </Card>
      </div>
    );
  };

  let content;
  if (submitted) {
    content = renderSubmittedMessage();
  } else {
    content = renderForm();
  }

  return (
    <div className="page-container max-width-container">
      <h1 className="centered">
        <Translated translationKey="feedback_placeholder" />
      </h1>
      <div className="centered">
        <Translated translationKey="send_feedback_info" />
      </div>
      <div className="front-center-wrapper medium-top-margin">{content}</div>
      <DataPrivacyNote className="semi-dark-inner-box centered" />
    </div>
  );
};

export default ContactPage;
