import ShopCategorySelector from "../../feature/shop/ShopCategorySelector";
import { useNavigate } from "react-router-dom";

import Translated from "../../feature/Translated";
import usePendingOrderCheck from "../../../hooks/usePendingOrderCheck";

const ShopFrontPage = () => {
  usePendingOrderCheck();
  const navigate = useNavigate();

  const selectCategory = (category) => {
    switch (category) {
      case "b":
        return navigate("/opetuslupa");
      case "t":
        return navigate("/traktorikortti");
      case "m":
        return navigate("/mopokortti");
      case "be":
        return navigate("/be-kortti-pikku-e");
      case "a":
        return navigate("/moottoripyorakortti");
      case "am":
        return navigate("/mopoautokortti");
    }
  };

  return (
    <div className="page-container max-width-container">
      <h1 className="phone-centered">
        <Translated translationKey="webshop_header" />
      </h1>
      <div className="blue-inner-box tight">
        <Translated translationKey="webshop_front_info" html={true} />
      </div>
      <ShopCategorySelector onSelect={selectCategory} />
    </div>
  );
};

export default ShopFrontPage;
