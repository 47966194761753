import React from 'react';
import {useNavigate} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Translated from '../feature/Translated';
import {IoSearch, IoPersonAdd} from 'react-icons/io5';
import PropTypes from 'prop-types';
import ProfileTeacher from '../feature/ProfileTeacher';
import News from '../feature/News';
import LatestAcceptedUsers from '../feature/LatestAcceptedUsers';
import LicenseAgreementNote from '../feature/LicenseAgreementNote';

export const MeTeacherPage = props => {

  const navigate = useNavigate();

  const renderQuickLinks = () => {
    return (
      <div className="blue-box tight">
        <h4>
          <Translated translationKey="quick_links"/>
        </h4>
        <div className="vertical-middle">
          <Button
            className="large-right-margin"
            onClick={() => {
              navigate('/users/add')
            }}
          >
            <IoPersonAdd/>
            <Translated translationKey="add_student"/>
          </Button>
          <Button onClick={() => {
            navigate('/users/search')
          }}>
            <IoSearch/>
            <Translated translationKey="search"/>
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div id="me-teacher-page" className="page-container no-page-gutter">
      <div className="user-profile-bg fade-in">
        <ProfileTeacher allowFacebook={props.allowFacebook}
                        className="user-profile-wrapper max-width-container page-gutter"/>
      </div>
      <div className="max-width-container page-gutter">
        <News/>
        <br/>
        {renderQuickLinks()}
        <LatestAcceptedUsers/>
        <LicenseAgreementNote className="semi-transparent large-top-margin"/>
      </div>
    </div>
  );

}

MeTeacherPage.propTypes = {
  allowFacebook: PropTypes.bool
};

export default MeTeacherPage;
