import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Translated from './Translated';
import ImageSet from './ImageSet';

class UserCategoryDisplay extends Component {

  static propTypes = {
    category: PropTypes.string.isRequired
  };

  render() {
    const category = this.props.category;
    if (!category) {
      return null;
    }

    const categoryKey = 'category_' + category.toLowerCase();
    const categoryId = category.toLowerCase();
    const imageSrc = '../../assets/category/' + categoryId + '.png';

    return (
      <div id="user-status-container" className={this.props.className}>
        <div>
          <ImageSet src={imageSrc}/>
        </div>
        <div>
          <Translated className="user-category-title" translationKey={categoryKey}/>
        </div>
      </div>
    );
  }
}

export default UserCategoryDisplay;
