import Button from "react-bootstrap/Button";
import { FiBook } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import MarkdownUtil from "../../utils/markdown";
import PreloadMiddlePage from "./PreloadMiddlePage";
import Translated from "../feature/Translated";
import { useUserData } from "../../context/UserDataContext";

/**
 * Menu page to select a book from all available books.
 * Books in different languages show up as different books.
 */
const BookSelectionPage = () => {
  const { userSettings } = useUserData();
  const navigate = useNavigate();

  const selectBook = (bookId, language) => {
    navigate(`/books/${bookId}/${language}`);
  };

  const renderBookItem = (book) => {
    const key = book.id + book.lang;
    return (
      <div
        key={key}
        className="book-content centered cover medium-bottom-margin"
      >
        {MarkdownUtil.render(book.markdown)}
        <Button onClick={() => selectBook(book.id, book.lang)}>
          <FiBook />
          <Translated translationKey="read_book" />
        </Button>
      </div>
    );
  };

  if (!userSettings.data) {
    return <PreloadMiddlePage />;
  }

  const books = userSettings.data.availableBooks;
  const booksWithLicense = books.filter((book) => book.hasLicense === true);
  const bookItems = [];
  booksWithLicense.forEach((book) => {
    bookItems.push(renderBookItem(book));
  });

  return (
    <div id="book-page" className="page-container">
      <div className="max-width-container centered">{bookItems}</div>
    </div>
  );
};

export default BookSelectionPage;
