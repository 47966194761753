import { useEffect } from "react";
import LocalStorageUtil from "../utils/localStorageUtil";
import { useNavigate } from "react-router-dom";

const usePendingOrderCheck = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const pendingOrder = LocalStorageUtil.getForUser("anon", "pending-order");
    if (pendingOrder) {
      const orderId = pendingOrder.id;
      const token = pendingOrder.token;
      navigate(`/order/${orderId}/${token}`);
    }
  }, [navigate]);
};

export default usePendingOrderCheck;
