import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { IoAlertOutline, IoMail } from "react-icons/io5";
import Alert from "react-bootstrap/Alert";

import Translated from "./../feature/Translated";
import TextInput from "./../feature/TextInput";
import dateFormat from "../../utils/date";
import { useTranslation } from "./TranslationProvider";
import { useConnectEmail } from "../../api-new";
import { useUserData } from "../../context/UserDataContext";

const EmailConnect = () => {
  const { email } = useUserData();
  const { lang, translate } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [inputEmail, setInputEmail] = useState("");
  const [error, setError] = useState(null);
  const emailMutation = useConnectEmail();

  const connectEmail = (forceConnect = false) => {
    const email = inputEmail;

    if (!email) {
      setError("invalid_input");
      return;
    }
    setError(null);

    emailMutation.mutate(
      { email: inputEmail, lang, forceConnect },
      {
        onError: (error) => {
          setError(error.message);
        },
      }
    );
  };

  const updateEmail = (email) => {
    setInputEmail(email);
    setError(null);
    emailMutation.reset();
  };

  const renderError = () => {
    if (!error) {
      return null;
    }
    let errorElem;
    switch (error) {
      case "email-already-connected":
        const errorData = emailMutation.error;
        errorElem = (
          <div>
            <Translated translationKey={error} />
            <br />
            <Translated translationKey="school_name" />: {errorData.schoolName}
            <br />
            <Translated translationKey="creation_date" />:{" "}
            {dateFormat.date(errorData.createdDate)}
            <br />
            <div className="large-top-margin centered">
              <div className="bottom-margin">
                <strong>
                  <Translated translationKey="email_force_connect" />
                </strong>
              </div>
              <div className="top-margin">
                <Button bsstyle="primary" onClick={() => setShowModal(false)}>
                  <Translated translationKey="no" />
                </Button>
                <Button
                  onClick={() => connectEmail(true)}
                  className="left-margin"
                >
                  <Translated translationKey="yes" />
                </Button>
              </div>
            </div>
          </div>
        );
        break;

      default:
        errorElem = (
          <div>
            <IoAlertOutline />
            <Translated translationKey={error} />
          </div>
        );
    }

    return <Alert>{errorElem}</Alert>;
  };

  const renderSentRequest = () => {
    return (
      <Alert bsstyle="success" className="top-margin">
        <Translated
          translationKey="user_email_request_sent"
          values={{ email: emailMutation.data.data.email }}
        />
      </Alert>
    );
  };

  const renderForm = () => {
    const emailText = translate("email");
    return (
      <div>
        <div>
          <Translated translationKey="connect_email_instructions" />
        </div>
        <TextInput
          listener={(email) => updateEmail(email)}
          placeholder={emailText}
          type="email"
          value={inputEmail}
          className="long-input-field top-margin"
        />
        {renderError()}
        <Button
          onClick={() => connectEmail()}
          disabled={emailMutation.isPending}
          className="top-margin"
        >
          <Translated translationKey="send" />
        </Button>
      </div>
    );
  };

  const renderModal = () => {
    return (
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Translated translationKey={"connect_email_credentials"} />
        </Modal.Header>
        <Modal.Body>
          {emailMutation.isSuccess ? renderSentRequest() : renderForm()}
        </Modal.Body>
      </Modal>
    );
  };

  if (email) {
    return null;
  }

  return (
    <div className="white-box">
      <Button onClick={() => setShowModal(true)} size="lg">
        <IoMail />
        <Translated translationKey="connect_email" />
      </Button>
      <div className="top-margin">
        <Translated translationKey={"connect_email_info"} />
      </div>
      {renderModal()}
    </div>
  );
};

export default EmailConnect;
