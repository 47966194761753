import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import { IoAlertOutline } from "react-icons/io5";
import queryString from "query-string";

import Spinner from "./../feature/Spinner";
import Translated from "./../feature/Translated";
import Logger from "../../utils/logger";
import EmailSetPassword from "../feature/EmailSetPassword";
import DateUtil from "../../utils/date";
import { useUserEmailRequestAction } from "../../api-new";

/**
 * Landing page from links in email requests (create user, connect email, reset password...)
 * Resolves action from server and displays page content according to that.
 */
const EmailRequestPage = () => {
  const location = useLocation();
  const [reqToken, setReqToken] = useState(null);
  const reqActionQuery = useUserEmailRequestAction(reqToken);
  console.log(reqActionQuery.data)

  useEffect(() => {
    let token = queryString.parse(location.search).token;
    if (!token) {
      // If redirected from FB login, token is the state URL fragment
      const hashParams = queryString.parse(location.hash);
      token = hashParams.state;
    }

    setReqToken(token);
  }, []);

  const renderEmailOverrideWarning = () => {
    const data = reqActionQuery.data.data;
    const existingUserData = data.existingUserData;
    if (!existingUserData) {
      return null;
    }
    return (
      <Alert>
        <Translated
          translationKey="email_override_warning"
          values={{
            email: data.email,
            userName: existingUserData.userName,
            schoolName: existingUserData.schoolName,
            createdDate: DateUtil.date(existingUserData.createdDate),
            newSchoolName: data.schoolName,
            newUserName: data.userName,
          }}
        />
      </Alert>
    );
  };

  const renderSetPassword = () => {
    return (
      <div>
        {renderEmailOverrideWarning()}
        <EmailSetPassword
          email={reqActionQuery.data.data.email}
          requestToken={reqToken}
        />
      </div>
    );
  };

  const renderCreateUser = () => {
    return (
      <div className="centered top-margin">
        <h2>
          <Translated translationKey="welcome_to_service" />
        </h2>
        {renderEmailOverrideWarning()}
          <EmailSetPassword
            email={reqActionQuery.data.data.email}
            requestToken={reqToken}
            className="large-top-margin"
          />
      </div>
    );
  };

  const renderError = () => {
    let error;

    switch (reqActionQuery.error.message) {
      case "invalid-input":
        error = "invalid_request_token";
        break;
      case "token-expired":
        error = "expired_request_token";
        break;
      case "token-consumed":
        error = "consumed_request_token";
        break;
      default:
        error = reqActionQuery.error.message;
    }
    return (
      <Alert>
        <IoAlertOutline /> <Translated translationKey={error} />
      </Alert>
    );
  };

  const renderFetching = () => {
    return <Spinner />;
  };

  const resolvePageContent = () => {
    if (reqActionQuery.isPending) {
      return renderFetching();
    }

    if (reqActionQuery.isError) {
      return renderError();
    }

    let content;
    switch (reqActionQuery.data.data.action) {
      case null:
        content = null;
        break;
      case "create-user-with-email":
      case "finalize-user-with-email":
        content = renderCreateUser();
        break;
      case "reset-email-password":
      case "connect-email":
        content = renderSetPassword();
        break;
      default:
        let err = new Error(
          "Unknown email action: " + reqActionQuery.data.data.action
        );
        Logger.error(err);
        content = null;
    }
    return content;
  };

  const content = resolvePageContent();

  return (
    <div
      id="email-request-page"
      className="page-container max-width-container fade-in"
    >
      {content}
    </div>
  );
};

export default EmailRequestPage;
