import { useState } from "react";
import classNames from "classnames";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";

import Translated from "../feature/Translated";
import Spinner from "./../feature/Spinner";
import { useUserData } from "../../context/UserDataContext";
import { useKeyLogin } from "../../api-new";
import { useTranslation } from "./TranslationProvider";

const LoginForm = (props) => {
  const { login } = useUserData();
  const { translate } = useTranslation();
  const [name, setName] = useState("");
  const [schoolId, setSchoolId] = useState("");
  const [oneTimeKey, setOneTimeKey] = useState("");
  const loginMutation = useKeyLogin();

  const handleLoginTap = (e) => {
    e.preventDefault();
    if (!name || !schoolId || !oneTimeKey) {
      return;
    }
    loginMutation.mutate(
      { username: name, schoolId, key: oneTimeKey },
      {
        onSuccess: ({ token }) => {
          login({ token });
        },
      }
    );
  };

  const renderError = () => {
    if (!loginMutation.isError) {
      return;
    }

    let errorContent;
    const errorData = loginMutation.error.data;

    if (loginMutation.error.message === "one-time-key-used") {
      if (errorData.length > 0) {
        errorContent = (
          <Translated
            translationKey="one-time-key-used"
            values={{ hint: errorData[0].toUpperCase() }}
          />
        );
      } else {
        errorContent = <Translated translationKey="all-one-time-keys-used" />;
      }
    } else {
      errorContent = (
        <Translated translationKey={loginMutation.error.message} />
      );
    }
    return (
      <Alert>
        <Translated className="strong-text" translationKey="error-login" />
        <br />
        {errorContent}
      </Alert>
    );
  };

  const shake = loginMutation.isError && !loginMutation.isPending;
  const formClasses = classNames("login-form-well", { shake }, props.className);

  let nameLabel;
  let schoolLabel;
  let codeLabel;
  if (loginMutation.isError) {
    nameLabel = (
      <Translated className="input-label" translationKey="login_student_name" />
    );
    schoolLabel = (
      <Translated className="input-label" translationKey="school_code" />
    );
    codeLabel = (
      <Translated className="input-label" translationKey="login_code" />
    );
  }
  const inputEmpty = !name || !schoolId || !oneTimeKey;

  return (
    <Card id="login-form" className={formClasses}>
      <Card.Header>{props.header}</Card.Header>
      <Card.Body>
        <Form onSubmit={handleLoginTap} method="post">
          <Form.Group onSubmit={handleLoginTap}>
            {nameLabel}
            <Form.Control
              id="login-form-name"
              disabled={loginMutation.isPending}
              type="text"
              name="name"
              placeholder={translate("login_student_name")}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <div className="field-spacer"></div>
            {schoolLabel}
            <Form.Control
              id="login-form-id"
              disabled={loginMutation.isPending}
              type="text"
              name="schoolId"
              placeholder={translate("school_code")}
              value={schoolId}
              onChange={(e) => setSchoolId(e.target.value)}
              className="uppercase"
            />

            <div className="field-spacer"></div>
            {codeLabel}
            <Form.Control
              id="login-form-code"
              disabled={loginMutation.isPending}
              type="text"
              name="oneTimeKey"
              placeholder={translate("login_code")}
              value={oneTimeKey}
              onChange={(e) => setOneTimeKey(e.target.value)}
              autoComplete="off"
              className="uppercase"
            />
            <div className="field-spacer"></div>
            {renderError()}
          </Form.Group>
          <Button
            id="login-form-submit"
            type="submit"
            disabled={loginMutation.isPending || inputEmpty}
            className="submit-login-button strong-text"
            onClick={handleLoginTap}
          >
            {loginMutation.isPending ? <Spinner /> : translate("login")}
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default LoginForm;
