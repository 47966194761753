import { useContext, createContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

import {
  setApiDecodedToken,
  clearCaches,
  hasApiDecodedTokenData,
  useUserSettings,
  setApiLogoutFn,
  setApiRequiredTos,
  setApiUpdateTokenDataFn,
} from "../api-new";
import LocalStorageUtil from "../utils/localStorageUtil";
import { useGlobalData } from "./GlobalContext";
import { useTranslation } from "../components/feature/TranslationProvider";

function decodeToken(token) {
  try {
    return jwtDecode(token);
  } catch (e) {
    return null;
  }
}

export const UserDataContext = createContext({
  token: null,
  userId: null,
  role: null,
  email:null,
  loginMethod:null,
  integrationPlatform: null,
  schoolName: null,
  schoolId:null,
  isLoadingUserData: null,
  requiredTos: null,
  userSettings: { data: null, vehicleCategory: null },
  login: (userData) => {},
  logout: (navigate) => {},
  setVehicleCategory: (category) => {},
  setRequiredTos: (tosVersion) => {}
});

export const useUserData = () => {
  return useContext(UserDataContext);
};

const UserContextProvider = ({ children }) => {
  const { setLoggedOutMessage } = useGlobalData();
  const { translate } = useTranslation();
  const [token, setToken] = useState(null);
  const [decodedToken, setDecodedToken] = useState(null);
  const [isLoadingUserData, setIsLoadingUserData] = useState(true);
  const [vehicleCategory, setVehicleCategory] = useState(null);
  const [requiredTos, setRequiredTos] = useState(null);
  const userSettingsQuery = useUserSettings(decodedToken?.userId);
  const userSettings = {
    data: userSettingsQuery.isSuccess ? userSettingsQuery.data : null,
    vehicleCategory,
  };

  useEffect(() => {
    // Get stored data
    const token = LocalStorageUtil.getToken();
    if (token) {
      setToken(token);
      const decodedToken = decodeToken(token);
      if (!decodedToken) {
        logout();
        return;
      }

      setDecodedToken(decodedToken);
      setApiDecodedToken({ ...decodedToken, encoded: token });
      setApiLogoutFn(logout);
      setApiRequiredTos(setRequiredTos);
      setApiUpdateTokenDataFn(updateTokenData);

      const storedCategory = LocalStorageUtil.getLocallyStoredCategory(
        decodedToken.userId
      );
      setVehicleCategory(storedCategory);
    } else {
      setApiDecodedToken(null);
    }

    setIsLoadingUserData(false);
  }, []);

  const login = async (userData) => {
    try {
      const token = userData.token;
      const decodedToken = decodeToken(token);
      setApiDecodedToken({ ...decodedToken, encoded: token });
      setApiLogoutFn(logout);
      setApiRequiredTos(setRequiredTos);
      setApiUpdateTokenDataFn(updateTokenData);

      setDecodedToken(decodedToken);
      setToken(token);
      LocalStorageUtil.setToken(token);
      const storedCategory = LocalStorageUtil.getLocallyStoredCategory(
        decodedToken.userId
      );
      setVehicleCategory(storedCategory);
    } catch (err) {
      // TODO Sentry?
      console.error("Error logging in.", err);
    }
  };

  const logout = (navigate) => {
    try {
      setApiDecodedToken(null);
      setToken(null);
      setDecodedToken(null);
      LocalStorageUtil.setToken(null);
      setVehicleCategory(null);
      setRequiredTos(null);
      setIsLoadingUserData(false);
      clearCaches();
      setLoggedOutMessage(translate("logged_out"));
      if (navigate) {
        navigate("/");
      }
    } catch (err) {
      // TODO Sentry?
      console.err("Error logging out.");
    }
  };

  const updateTokenData = (newToken) => {
    const decodedToken = decodeToken(newToken);

    if (!decodedToken) {
      logout();
    } else {
      setToken(newToken);
      setDecodedToken(decodedToken);
    }
  };

  const switchVehicleCategory = (category) => {
    if (category === userSettings.vehicleCategory) {
      return;
    }

    setVehicleCategory(category);
    LocalStorageUtil.setLocallyStoredCategory(category, decodedToken?.userId);
  };

  const contextValue = {
    token,
    userId: decodedToken?.userId,
    role: decodedToken?.role,
    email: decodedToken?.email,
    schoolName: decodedToken?.schoolName,
    schoolId: decodedToken?.schoolId,
    loginMethod: decodedToken?.loginMethod,
    integrationPlatform: decodedToken?.integrationPlatform,
    userSettings,
    setVehicleCategory: switchVehicleCategory,
    requiredTos,
    setRequiredTos,
    isLoadingUserData: isLoadingUserData || !hasApiDecodedTokenData,
    login,
    logout,
  };

  return (
    <UserDataContext.Provider value={contextValue}>
      {children}
    </UserDataContext.Provider>
  );
};

export default UserContextProvider;
