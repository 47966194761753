import React from "react";
import Button from "react-bootstrap/Button";
import Translated from "../feature/Translated";
import { useUserData } from "../../context/UserDataContext";

const ResetVehicleCategoryButton = (props) => {
  const { setVehicleCategory } = useUserData();
  return (
    <Button
      className={props.className}
      bsstyle={props.bsStyle}
      onClick={() => {
        setVehicleCategory(null);
      }}
    >
      <Translated
        translationKey={props.labelKey ? props.labelKey : "change_category"}
      />
    </Button>
  );
};

export default ResetVehicleCategoryButton;
