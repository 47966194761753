import Translated from "./Translated";
import { useNavigate } from "react-router-dom";

const DataPrivacyNote = (props) => {
  const navigate = useNavigate();

  return (
    <div className={props.className}>
      <Translated
        translationKey="data_privacy_label"
        className="touchable"
        onClick={() => navigate("/privacy-policy")}
      />
    </div>
  );
};

export default DataPrivacyNote;
