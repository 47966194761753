import { createContext, useContext, useEffect, useState } from "react";
import { setApiHasConnectionFn, setApiHasMaintenanceFn } from "../api-new";

export const GlobalDataContext = createContext({
  pageState: null,
  pageData: null,
  hasMaintenance: null,
  hasConnection: null,
  loggedOutMessage: null,
  setSelectedRuleIndex: (pageId, ruleIndex) => {},
  setMenuIndex: (containerId, index) => {},
  setPageData: (pageId, data) => {},
  setHasMaintenance: (hasMaintenance) => {},
  setHasConnection: (hasConnection) => {},
  setLoggedOutMessage: (message) => {},
});

export const useGlobalData = () => {
  return useContext(GlobalDataContext);
};

// Try to minimize usage of data in global state as it re renders any currently mounted components upon state changes.
const GlobalContextProvider = ({ children }) => {
  const [pageState, setPageState] = useState({});
  const [pageData, setPageData] = useState({});
  const [hasMaintenance, setHasMaintenance] = useState(false);
  const [hasConnection, setHasConnection] = useState(true);
  const [loggedOutMessage, setLoggedOutMessage] = useState("");

  useEffect(() => {
    setApiHasMaintenanceFn(setHasMaintenance);
    setApiHasConnectionFn(setHasConnection);
  }, []);

  const setMenuIndex = (containerId, index) => {
    setPageState((prev) => ({
      ...prev,
      [containerId]: {
        ...prev[containerId],
        selectedMenuIndex: index,
      },
    }));
  };

  const setSelectedRuleIndex = (pageId, ruleIndex) => {
    setPageState((prev) => ({
      ...prev,
      [pageId]: { ...prev[pageId], selectedRuleIndex: ruleIndex },
    }));
  };

  const pageDataSetter = (pageId, data) => {
    setPageData((prev) => ({
      ...prev,
      [pageId]: data,
    }));
  };

  const contextValue = {
    pageState,
    pageData,
    setMenuIndex,
    setSelectedRuleIndex,
    setPageData: pageDataSetter,
    hasMaintenance,
    setHasMaintenance,
    hasConnection,
    setHasConnection,
    loggedOutMessage,
    setLoggedOutMessage
  };
  return (
    <GlobalDataContext.Provider value={contextValue}>
      {children}
    </GlobalDataContext.Provider>
  );
};

export default GlobalContextProvider;
