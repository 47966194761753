import { useParams } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import { IoAlertCircle } from "react-icons/io5";

import PreloadMiddlePage from "../pages/PreloadMiddlePage";
import EditUser from "../feature/EditUser";
import UserCategoryStatus from "./../feature/UserCategoryStatus";
import TestResultsList from "./../feature/results/TestResultsList";
import Translated from "./../feature/Translated";
import UserBookStatuses from "../feature/UserBookStatuses";
import { Roles, fillsRole } from "../../data/roles";
import {
  invalidateQueries,
  QUERY_KEY,
  useSaveProfile,
  useUserProfile,
} from "../../api-new";

const EditUserPage = () => {
  const { userId } = useParams();
  const userProfileQuery = useUserProfile(userId);
  const saveProfileMutation = useSaveProfile();

  const saveUserData = (userData, callback) => {
    saveProfileMutation.mutate(userData, {
      onSuccess: () => {
        invalidateQueries(QUERY_KEY.USER_PROFILE, userId);
        if (callback) {
          callback();
        }
      },
    });
  };

  const renderError = (error) => {
    return (
      <Alert id="edit-student-error">
        <Translated translationKey={error} />
      </Alert>
    );
  };

  const renderCategoryStatus = (categoryStatuses) => {
    if (categoryStatuses.length === 0) {
      return (
        <Alert>
          <IoAlertCircle />
          &nbsp;&nbsp;&nbsp;
          <Translated translationKey="no_exercises_done" />
        </Alert>
      );
    }
    const userId = userProfileQuery.data.userData.id;
    return categoryStatuses.map((catStatus) => {
      return (
        <UserCategoryStatus
          userId={userId}
          category={catStatus.category}
          acceptedAmount={catStatus.acceptedTestAmount}
          totalAcceptedRequired={catStatus.acceptedTestsRequired}
          rejectedAmount={catStatus.rejectedTestAmount}
          simple={true}
          key={catStatus.category}
        />
      );
    });
  };

  const renderStatus = () => {
    const profile = userProfileQuery.data;
    const isTeacher = fillsRole(profile.userData.role, Roles.TEACHER);
    if (isTeacher) {
      return null;
    }
    return (
      <div>
        {renderCategoryStatus(profile.status.categoryStatus)}
        <UserBookStatuses userId={profile.userData.id} />
        {profile.status.categoryStatus.length === 0 ? null : (
          <TestResultsList userId={profile.userData.id} />
        )}
      </div>
    );
  };

  const renderUser = () => {
    const profile = userProfileQuery.data;
    const isTeacher = fillsRole(profile.userData.role, Roles.TEACHER);
    return (
      <div id="edit-student-page">
        {isTeacher && (
          <h2>
            <Translated translationKey="teacher" />
          </h2>
        )}
        <EditUser
          profile={profile}
          saveUserMethod={saveUserData}
          isSaving={saveProfileMutation.isPending}
        />
        <br />
        {profile.userData.activationDate && renderStatus()}
      </div>
    );
  };

  if (userProfileQuery.isPending) {
    return <PreloadMiddlePage />;
  }

  return (
    <div id="edit-student-page" className="page-container max-width-container">
      {userProfileQuery.isError && renderError(userProfileQuery.error.message)}
      {saveProfileMutation.isError &&
        renderError(saveProfileMutation.error.message)}
      {userProfileQuery.isSuccess && renderUser()}
    </div>
  );
};

export default EditUserPage;
