import classNames from "classnames";
import { useTranslation } from "./TranslationProvider";
import { IoLanguage } from "react-icons/io5";
import { availableLangsData } from "../../data/availableLangsData";

const LanguageSelectorForm = (props) => {
  const { lang, switchLanguage } = useTranslation();

  const onChange = (e) => {
    e.target.blur(); // Mobile Safari cannot do unbinding without blurring.
    switchLanguage(e.target.value);
    props.closeNavigation();
  };

  const langCodes = Object.keys(availableLangsData);
  if (langCodes.length <= 1) {
    return null;
  }
  return (
    <div
      id="language-selector-form"
      className={classNames("lang-selector-form-container", props.className)}
    >
      <span className="larger">
        <IoLanguage />
        &nbsp;
        {availableLangsData[lang]}
      </span>
      <select id="language-selector-select" onChange={onChange} value={lang}>
        {langCodes.map((langCode, index) => (
          <option key={index} value={langCode}>
            {availableLangsData[langCode]}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LanguageSelectorForm;
