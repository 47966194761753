class SettingsUtil {

  static hasBookForCurrentCategory(settings) {
    let selectedCategory = settings.vehicleCategory;
    if (selectedCategory === null) {
      return false;
    } else {
      selectedCategory = selectedCategory.toLocaleLowerCase();
    }

    const data = settings.data;
    if (!data) {
      return null;
    }
    const books = data.availableBooks;

    return books.some(book => {
      if (book.hasLicense) {
        const bookCategory = book.category.toLowerCase();
        return SettingsUtil.categoryContainsBookCategory(selectedCategory, bookCategory);
      } else {
        return false;
      }
    });
  };

  static categoryContainsBookCategory(selectedCategory, bookCategory) {
    // Book's category must match the selected category (OLB can use B book though, ugly bypass here).
    return bookCategory === selectedCategory || (bookCategory === 'b' && selectedCategory === 'olb');
  }

  static hasLicensedBooks(settings) {
    const data = settings.data;
    if (!data) {
      return null;
    }
    const books = data.availableBooks;
    if (!books) {
      return null;
    }
    return books.some(book => {
      return book.hasLicense === true;
    });
  }
}

export default SettingsUtil;

