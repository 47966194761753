import { useState } from "react";
import DateUtil from "../../../utils/date";
import Alert from "react-bootstrap/Alert";

import Spinner from "../Spinner";
import { FcApproval, FcHighPriority } from "react-icons/fc";
import Button from "react-bootstrap/Button";
import Translated from "../Translated";
import ImageSet from "../ImageSet";
import { useTestResultsOverview } from "../../../api-new";
import { useNavigate } from "react-router-dom";

const MAX_RESULTS_LIMIT = 50;

const TestResultsList = (props) => {
  const [isShowingAll, setIsShowingAll] = useState(false);
  const testsQuery = useTestResultsOverview(props.userId);
  const navigate = useNavigate();

  const onResultClicked = (resultId, canShowResult) => {
    if (!canShowResult) {
      return;
    }
    navigate("/result/" + resultId);
  };

  const renderResultItem = (entry, canShowTestResult, entryIndex) => {
    const qualificationIcon = entry.isAccepted ? (
      <span>
        <FcApproval fontSize="large" className="results-list-accepted" />
      </span>
    ) : (
      <span>
        <FcHighPriority fontSize="large" className="results-list-rejected" />
      </span>
    );
    const categoryIcon = `../../assets/category/${entry.category}.png`;
    const rowClass = canShowTestResult
      ? "user-result-row touchable"
      : "user-result-row not-allowed semi-transparent";

    return (
      <div
        key={entry.id}
        onClick={() => onResultClicked(entry.id, canShowTestResult)}
        className={rowClass}
      >
        <div className="user-results-data">
          <strong>{entryIndex}.</strong>&nbsp;
          {DateUtil.dateTime(entry.endDate)}&nbsp;
          <ImageSet src={categoryIcon} alt={"category-icon"} />
        </div>
        <div>
          {getFaultsString(entry)}&nbsp;
          {qualificationIcon}
        </div>
      </div>
    );
  };

  const getFaultsString = (result) => {
    const faults = [];
    if (result.literalIds || result.signIds) {
      faults.push(result.aggregatedLiteralFaultAmount);
    }
    if (result.riskIds) {
      faults.push(result.risksFaultAmount);
    }
    if (result.imageIds) {
      faults.push(result.imagesFaultAmount);
    }
    return faults.join("+");
  };

  if (testsQuery.isPending) {
    return <Spinner />;
  }

  if (testsQuery.isError) {
    return (
      <Alert>
        <Translated translationKey={testsQuery.error.message} />
      </Alert>
    );
  }

  const results = [...testsQuery.data.results];
  results.sort((a, b) => (a.endDate > b.endDate ? -1 : 1));
  let resultItems = [];

  if (results) {
    resultItems = results
      .slice(0, isShowingAll ? results.length : MAX_RESULTS_LIMIT)
      .map((entry, i) => {
        let canShowTestResult = DateUtil.getYear(entry.endDate) >= 2016; // Older results have photographs that are not online anymore
        return renderResultItem(entry, canShowTestResult, i + 1);
      });
  }

  const resultsView = <div className="user-results">{resultItems}</div>;

  return (
    <div id="test-results-list-container" className="white-box">
      {resultsView}
      {!isShowingAll && results.length > MAX_RESULTS_LIMIT && (
        <Button onClick={() => setIsShowingAll(true)}>
          <Translated translationKey="show_all" />
        </Button>
      )}
    </div>
  );
};

export default TestResultsList;
