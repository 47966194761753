import { useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";

import Spinner from "../feature/Spinner";
import Translated from "../feature/Translated";
import { useUserData } from "../../context/UserDataContext";
import {
  useLoginAutoliittoIntegration,
  useLoginAutoliittoSSO,
} from "../../api-new";

const LoginAutoliittoPage = () => {
  const { token, login } = useUserData();
  const navigate = useNavigate();
  const location = useLocation();
  const loginIntegrationMutation = useLoginAutoliittoIntegration();
  const loginSsoMutation = useLoginAutoliittoSSO();
  const isPending =
    loginSsoMutation.isPending || loginIntegrationMutation.isPending;
  const isError = loginSsoMutation.isError || loginIntegrationMutation.isError;
  const errorMessage =
    loginSsoMutation.error?.message || loginIntegrationMutation.error?.message;

  useEffect(() => {
    let params = queryString.parse(location.search);
    if (params.code) {
      const authCode = decodeURIComponent(params.code);
      loginSsoMutation.mutate(
        {
          authCode,
        },
        {
          onSuccess: (data) => {
            login({ token: data.token });
          },
        }
      );
    } else {
      let externalUserId = decodeURIComponent(params.user);
      let hash = decodeURIComponent(params.hash);
      let externalTime = decodeURIComponent(params.stamp);
      loginIntegrationMutation.mutate(
        { externalUserId, hash, externalTime },
        {
          onSuccess: (data) => {
            login({ token: data.token });
          },
        }
      );
    }
  }, []);

  useEffect(() => {
    if (token) {
      navigate("/", { state: location.state, replace: true });
    }
  }, [token]);

  return (
    <div
      id="login-page"
      className="page-container page-gutter max-width-container"
    >
      <div className="front-center-wrapper">
        <div className="login-form-container fade-in">
          {isPending && <Spinner />}
          {isError && (
            <Alert>
              <Translated translationKey={errorMessage} />
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginAutoliittoPage;
