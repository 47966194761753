import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import { IoAlertOutline } from "react-icons/io5";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";
import TextInput from "../feature/TextInput";
import Translated from "../feature/Translated";
import EmailUtil from "../../utils/EmailUtil";
import dateFormat from "../../utils/date";
import { Roles, fillsRole } from "../../data/roles";
import Spinner from "./Spinner";
import { useAddTeacher, useAddUserWithEmail } from "../../api-new";
import { useTranslation } from "./TranslationProvider";

const UserCreate = (props) => {
  const navigate = useNavigate();
  const { lang: langContext, availableLangs, translate } = useTranslation();
  const [lang, setLang] = useState(langContext);
  const [inputEmail, setInputEmail] = useState("");
  const [nameImplicit, setNameImplicit] = useState("");
  const [nameExplicit, setNameExplicit] = useState("");
  const [useImplicitName, setUseImplicitName] = useState(true);
  const [error, setError] = useState(null);
  const addTeacherMutation = useAddTeacher();
  const addWithEmailMutation = useAddUserWithEmail();
  const mutation = fillsRole(props.role, Roles.TEACHER)
    ? addTeacherMutation
    : addWithEmailMutation;

  const sendRequest = (data) => {
    const schoolId = data.schoolId;
    const name = data.name;
    const email = data.email;
    const lang = data.lang;
    const force = data.force;

    if (!EmailUtil.isEmail(email) || !name) {
      setError("invalid_input");
      return;
    }

    setError(null);

    mutation.mutate(
      { schoolId, email, userName: name, lang, role: props.role, force },
      {
        onSuccess: () => {
          setInputEmail("");
          setNameImplicit("");
          setNameExplicit("");
          setUseImplicitName(true);
          setError(null);
        },
        onError: (error) => {
          setError(error.message);
        },
      }
    );
  };

  const reset = () => {
    setLang(lang);
    setInputEmail("");
    setNameImplicit("");
    setNameExplicit("");
    setUseImplicitName(true);
    setError(null);
  };

  const updateEmail = (email) => {
    email = email.trim();
    const resolvedName = EmailUtil.resolveNameFromEmail(email);
    setInputEmail(email);
    setError(null);
    setNameImplicit(resolvedName);
  };

  const updateName = (name) => {
    setNameExplicit(name);
    setUseImplicitName(false);
  };

  const renderEmailField = () => {
    const emailKey =
      props.role === Roles.STUDENT ? "student_email" : "teacher_email";
    const errorLabel = error && <Translated translationKey={emailKey} />;
    const emailText = translate(emailKey);
    return (
      <div>
        {errorLabel}
        <TextInput
          disabled={error === "email-already-connected"}
          listener={(email) => updateEmail(email)}
          value={inputEmail}
          type="email"
          placeholder={emailText}
          className="full-width"
        />
      </div>
    );
  };

  const renderNameField = () => {
    const errorLabel = error && <Translated translationKey="name" />;
    const nameText = translate("name");
    const currentName = getCurrentName();
    return (
      <div>
        {errorLabel}
        <TextInput
          disabled={error === "email-already-connected"}
          listener={(name) => updateName(name)}
          placeholder={currentName && currentName !== "" ? "" : nameText} // Safari bug, causes to placeholder to stick before focus event.
          value={currentName}
          className="full-width"
        />
      </div>
    );
  };

  const getCurrentName = () => {
    if (nameExplicit || !useImplicitName) {
      return nameExplicit;
    } else {
      return nameImplicit || "";
    }
  };

  const renderError = () => {
    if (!error) {
      return null;
    }
    let errorElem;
    switch (error) {
      case "email-already-connected":
        return renderAlreadyConnectedWarning();

      default:
        errorElem = <Translated translationKey={error} />;
    }

    return (
      <Alert className="fade-in top-margin-small" bsstyle="warning">
        <IoAlertOutline /> {errorElem}
      </Alert>
    );
  };

  const renderAlreadyConnectedWarning = () => {
    const ed = mutation.error.data;
    const existingUser = ed.existingUserData;

    let actionButton;
    if (existingUser.schoolId === props.schoolId) {
      actionButton = (
        <Button
          onClick={() => {
            navigate("/user/" + existingUser.userId);
          }}
        >
          <Translated translationKey="view" />
        </Button>
      );
    } else {
      actionButton = (
        <Button
          bsstyle="warning"
          onClick={() => {
            const ed = mutation.error.data;
            const addData = {
              schoolId: ed.schoolId,
              name: ed.userName,
              email: ed.email,
              lang: ed.lang,
              force: true,
            };
            sendRequest(addData);
          }}
        >
          <Translated translationKey="continue" />
        </Button>
      );
    }

    return (
      <Modal show={true}>
        <Modal.Body>
          <Translated translationKey={error} />
          <br />
          <Translated translationKey="id" />: {existingUser.userId}
          <br />
          <Translated translationKey="name" />: {existingUser.userName}
          <br />
          <Translated translationKey="school_name" />: {existingUser.schoolName}
          <br />
          <Translated translationKey="creation_date" />:{" "}
          {dateFormat.date(existingUser.createdDate)}
        </Modal.Body>

        <Modal.Footer className="centered">
          <Button bsstyle="info" onClick={reset}>
            <Translated translationKey="cancel" />
          </Button>
          {actionButton}
        </Modal.Footer>
      </Modal>
    );
  };

  const renderRequest = () => {
    return !mutation.data ? null : (
      <Alert bsstyle="success" className="top-margin fade-in">
        <Translated
          translationKey="user_email_request_sent"
          values={{ email: mutation.data.data.email }}
        />
        <br />
        <Translated
          translationKey="last_usage_date"
          values={{ date: dateFormat.date(mutation.data.data.expiresAt) }}
        />
      </Alert>
    );
  };

  const createLanguageItems = () => {
    const langCodes = Object.keys(availableLangs);
    if (langCodes.length <= 1) {
      return null;
    }

    return langCodes.map((langCode) => {
      return (
        <Dropdown.Item
          id={langCode}
          key={langCode}
          onClick={() => {
            setLang(langCode);
          }}
        >
          <span>{availableLangs[langCode]}</span>
        </Dropdown.Item>
      );
    });
  };

  const renderLanguageSelection = () => {
    const langId = lang;
    const langLocale = availableLangs[langId];
    return (
      <ButtonToolbar className="settings-category-limit">
        <DropdownButton
          id="addUserLanguageSelection"
          bsstyle="warning"
          title={langLocale}
        >
          {createLanguageItems()}
        </DropdownButton>
      </ButtonToolbar>
    );
  };

  const renderButtons = () => {
    if (mutation.isPending) {
      return <Spinner />;
    }

    const addBtnKey =
      props.role === Roles.STUDENT ? "add_student" : "add_teacher";
    const sendDisabled = !inputEmail || !getCurrentName();
    return (
      <div className="vertical-middle on-sides">
        {renderLanguageSelection()}
        <Button
          disabled={sendDisabled}
          onClick={() => {
            const addData = {
              schoolId: props.schoolId,
              name: getCurrentName().trim(),
              email: inputEmail,
              lang: lang,
            };
            sendRequest(addData);
          }}
        >
          <Translated translationKey={addBtnKey} />
        </Button>
      </div>
    );
  };

  return (
    <div>
      <div className="add-student-container">
        {renderEmailField()}
        {renderNameField()}
        {renderButtons()}
      </div>
      {renderError()}
      <div>{renderRequest()}</div>
    </div>
  );
};

UserCreate.propTypes = {
  schoolId: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
};



export default UserCreate;
