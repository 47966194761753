import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Translated from "./../Translated";
import { useTranslation } from "../TranslationProvider";

const FullTestDetailsModal = ({ showModal, closeModal }) => {
  const { translate } = useTranslation();

  return (
    <Modal onEscapeKeyDown={closeModal} show={showModal}>
      <Modal.Header>
        <strong>{translate("theory_exam_requirements")}</strong>
      </Modal.Header>
      <Modal.Body>
        <p>
          <Translated
            translationKey="official_test_limit_note"
            html={true}
            values={{ url: AJOKORTTI_INFO_URL }}
          />
        </p>
        <br />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={closeModal}>{translate("close")}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FullTestDetailsModal;
