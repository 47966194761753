import PropTypes from "prop-types";

import ProfileStudent from "../feature/ProfileStudent";
import ProfileAutoliitto from "../feature/ProfileAutoliitto";
import ActiveBookStatus from "../feature/ActiveBookStatus";
import News from "../feature/News";
import UserStatusLoaderWrapper from "../feature/UserStatusLoaderWrapper";
import UserResultsPreview from "../feature/UserResultsPreview";
import UserCategoryStatus from "../feature/UserCategoryStatus";
import CategorySelector from "./../feature/CategorySelector";
import StartTrainingButton from "../feature/StartTrainingButton";
import ResetVehicleCategoryButton from "../feature/ResetVehicleCategoryButton";
import { useUserData } from "../../context/UserDataContext";

const MeIntegrationPage = () => {
  const { userSettings, integrationPlatform } = useUserData();

  const renderCategoryStatus = () => {
    const selectedCategory = userSettings.vehicleCategory;
    if (selectedCategory === null) {
      return <CategorySelector className="fade-in delay" />;
    }

    return (
      <div>
        <UserStatusLoaderWrapper category={selectedCategory}>
          <UserCategoryStatus className="fade-in delay">
            <StartTrainingButton />
            <ResetVehicleCategoryButton bsstyle="link" />
          </UserCategoryStatus>
        </UserStatusLoaderWrapper>
        <ActiveBookStatus className="fade-in delay" />
        <UserStatusLoaderWrapper category={selectedCategory}>
          <UserResultsPreview className="fade-in long-delay" />
        </UserStatusLoaderWrapper>
      </div>
    );
  };

  let profile;
  if (integrationPlatform === "autoliitto") {
    profile = (
      <ProfileAutoliitto
        allowFacebook={false}
        className="user-profile-wrapper max-width-container page-gutter"
      />
    );
  } else if (integrationPlatform === "aaintegration") {
    profile = (
      <ProfileStudent
        showClosingDate={false}
        allowFacebook={false}
        className="user-profile-wrapper max-width-container page-gutter"
      />
    );
  } else {
    profile = (
      <ProfileStudent
        showClosingDate={true}
        allowFacebook={false}
        className="user-profile-wrapper max-width-container page-gutter"
      />
    );
  }

  return (
    <div
      id="me-student-page"
      className="page-container max-width-container no-page-gutter"
    >
      <div className="user-profile-bg fade-in">{profile}</div>
      <div className="max-width-container page-gutter">
        {renderCategoryStatus()}
        <News />
      </div>
    </div>
  );
};

export default MeIntegrationPage;
