import { useNavigate } from "react-router-dom";
import Translated from "./../Translated";

const ShopDeliveryTermsNote = (props) => {
  const navigate = useNavigate();

  return (
    <div className={props.className}>
      <Translated
        translationKey="delivery_terms_label"
        className="touchable"
        onClick={() => navigate("/cancelling-terms")}
      />
    </div>
  );
};

export default ShopDeliveryTermsNote;
