import { useEffect, useState } from "react";
import classNames from "classnames";

import PreloadMiddlePage from "./PreloadMiddlePage";
import FullTestMain from "../feature/fulltest/FullTestMain";
import FullTestStart from "../feature/fulltest/FullTestStart";
import FullTestContinue from "../feature/fulltest/FullTestContinue";
import Logger from "../../utils/logger";
import { useUserData } from "../../context/UserDataContext";
import { useFullTest } from "../../api-new";
import Tracker from "../../utils/tracker";
import { useTranslation } from "../feature/TranslationProvider";
import { createFullTest } from "../../utils/fullTestUtils";

const MAX_AGE_HOURS = 48;
const SAVE_REVISION = 1;

const FullTestPage = () => {
  const { userId, token, userSettings } = useUserData();
  const { lang } = useTranslation();
  const [initialized, setInitialized] = useState(false);
  const [test, setTest] = useState(null);
  const [started, setStarted] = useState(false);
  const [showContinue, setShowContinue] = useState(false);
  const [saveData, setSaveData] = useState(null);
  const fullTestQuery = useFullTest(userSettings.vehicleCategory, lang);

  useEffect(() => {
    if (fullTestQuery.isSuccess) {
      const fullTest = createFullTest(fullTestQuery.data);

      setTest(fullTest);
      setStarted(true);
    }
  }, [fullTestQuery.data]);

  const resetInitialState = () => {
    setTest(null);
    setStarted(false);
    setShowContinue(false);
    setSaveData(null);
  };

  useEffect(() => {
    if (initialized || !token || !userSettings.data) {
      return;
    }
    const savedState = loadState();
    if (savedState) {
      setSaveData(savedState);
      setShowContinue(true);
    } else {
      resetInitialState();
    }

    setInitialized(true);
  });

  const saveState = (testState) => {
    if (!token) {
      return;
    }

    try {
      const category = userSettings.vehicleCategory;
      const data = {
        timestamp: Date.now(),
        userId,
        category,
        testState,
        testData: test,
        rev: SAVE_REVISION,
      };
      const jsonString = JSON.stringify(data);
      localStorage.setItem("fulltest_state", jsonString);
    } catch (e) {
      console.log(e);
      // Ignore, possible private mode
      // Logger.info('Could not set full test state to local storage', e);
    }
  };

  const removeSavedState = () => {
    try {
      localStorage.removeItem("fulltest_state");
    } catch (e) {
      Logger.info(
        "Could not remove full test saved state from local storage",
        e
      );
      // Ignore, possible private mode
    }
  };

  const loadState = () => {
    if (!token) {
      return null;
    }

    try {
      const jsonString = localStorage.getItem("fulltest_state");
      if (jsonString) {
        const data = JSON.parse(jsonString);
        const category = userSettings.vehicleCategory;

        if (!data || data.userId !== userId || data.category !== category) {
          return null;
        }

        if (data.rev !== SAVE_REVISION) {
          removeSavedState();
          return null;
        }

        if (Date.now() - data.timestamp < MAX_AGE_HOURS * 3600 * 1000) {
          return data;
        } else {
          removeSavedState();
        }
      }
    } catch (e) {
      removeSavedState();
      return null;
    }

    return null;
  };

  const start = () => {
    Tracker.logEvent("api", "get-full-test");
    fullTestQuery.refetch();
  };

  const restart = () => {
    removeSavedState();
    resetInitialState();
  };

  const restore = () => {
    setShowContinue(false);
    setStarted(true);
    setTest(saveData.testData);
  };

  if (!initialized || fullTestQuery.isFetching || fullTestQuery.isError) {
    return <PreloadMiddlePage error={fullTestQuery.error?.message} />;
  }

  let content;
  let className;
  if (showContinue) {
    content = <FullTestContinue restart={restart} restore={restore} />;
  } else if (!started) {
    content = <FullTestStart start={start} locale={lang} />;
  } else {
    const state = saveData ? saveData.testState : null;
    content = (
      <FullTestMain
        test={test}
        save={saveState}
        removeSave={removeSavedState}
        initState={state}
      />
    );
    className = "no-page-gutter";
  }
  return (
    <div className={classNames("page-container full-test-page", className)}>
      {content}
    </div>
  );
};

export default FullTestPage;
