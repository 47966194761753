import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import BookMenuItem from "./BookMenuItem";
import MarkdownUtil from "../../utils/markdown";
import BookNavigation from "./BookNavigation";
import ResultFeedbackForm from "./results/ResultFeedbackForm";
import BookReadStatusMarkerSimple from "./BookReadStatusMarkerSimple";

/**
 * Book browser. Shows book main menu and chapters.
 * This browser does not show book read status or possible additional content.
 */
const BookBrowserSimple = (props) => {
  const navigate = useNavigate();
  const chapterIndex = props.chapters.findIndex(
    (chapter) => chapter.id === props.chapterId
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [chapterIndex]);

  const openChapter = (index) => {
    index = index >= 0 && index < props.chapters.length ? index : 0;
    const chapter = props.chapters[index];
    navigate(`/books/${props.bookId}/${props.language}/${chapter.id}`);
  };

  const renderChapter = () => {
    const chapter = chapterIndex >= 0 ? props.chapters[chapterIndex] : null;
    const navi = (
      <BookNavigation
        openChapter={openChapter}
        current={chapterIndex}
        total={props.chapters.length - 1}
      />
    );

    return (
      <div>
        <div className="book-content">
          {navi}
          {MarkdownUtil.render(chapter.markdown, chapter.id)}
          <ResultFeedbackForm
            key={chapter.id + "-feedback"}
            questionType="book-chapter"
            questionId={chapter.id}
            placeholderKey={"comment_book_chapter"}
          />
          {navi}
          <BookReadStatusMarkerSimple
            key={chapter.id + "-status"}
            chapter={chapter}
          />
        </div>
      </div>
    );
  };

  const renderMenu = () => {
    let menuItems = props.chapters.map((chapter, i) => {
      if (i === 0) {
        return null;
      }
      return (
        <BookMenuItem
          key={i}
          index={i}
          title={chapter.title}
          onClick={() => openChapter(i)}
          showStatus={false}
        />
      );
    });

    const menuChapter = props.chapters[0];

    return (
      <div className="fade-in">
        <h2>{menuChapter.title}</h2>
        <div className="book-menu-items">{menuItems}</div>
      </div>
    );
  };

  if (chapterIndex > 0) {
    return renderChapter();
  } else {
    return renderMenu();
  }
};

export default BookBrowserSimple;
