import Spinner from './Spinner';
import CategorySelector from './CategorySelector';
import UserCategoryDisplay from './UserCategoryDisplay';
import Translated from './Translated';
import ResetVehicleCategoryButton from './ResetVehicleCategoryButton';
import { useUserData } from '../../context/UserDataContext';

const UserCategorySetting =  () => {
  const { userId, userSettings } = useUserData();

    if (!userSettings.data) {
      return <Spinner/>;
    }

    const selectedCategory = userSettings.vehicleCategory;
    if (selectedCategory === null) {
      return (<CategorySelector className="fade-in"/>)
    }

    return (
      <div>
        <h4><Translated translationKey="license_class"/></h4>
        <div className="white-box vertical-middle on-sides">
          <UserCategoryDisplay
            category={selectedCategory}
          />
          <ResetVehicleCategoryButton userId={userId} />
        </div>
      </div>
    );
  
}

export default UserCategorySetting;
