import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { IoClose } from "react-icons/io5";
import classNames from "classnames";

import ImageSet from "../ImageSet";
import PriceDisplay from "./PriceDisplay";
import Translated from "../Translated";
import ProductInput from "./ProductInput";
import BookPromoComponent from "./BookPromoComponent";
import ImagesComponent from "./ImagesComponent";
import Tracker from "../../../utils/tracker";
import TrackerAB from "../../../utils/trackerAB";
import saleImg from "../../../assets/webshop/ale_rot_left.png";
import { useTranslation } from "../TranslationProvider";

const ProductDetails = (props) => {
  const { translate } = useTranslation();
  const [productInputs, setProductInputs] = useState(null);
  const [showInputErrors, setShowInputErrors] = useState(false);
  const [showInputs, setShowInputs] = useState(false);
  const product = props.product;


  useEffect(() => {
    Tracker.logEventLabel("webshop", "product-details", product.id);

    TrackerAB.track("webshop-show-product-details", {
      productId: product.id,
      productCategory: product.productData.category,
    });

    return () => {
      TrackerAB.track("webshop-show-product-details-closed");
    };
  }, []);

  const onSetProductInputs = (data) => {
    setProductInputs(data);
  };

  const onInputsOpened = () => {
    TrackerAB.track("product-inputs-opened");
    setShowInputs(true);
  };

  const renderInputs = () => {
    if (!showInputs) {
      return (
        <Button bsstyle="warning" onClick={onInputsOpened}>
          <Translated className="strong-text" translationKey="buy" />
        </Button>
      );
    }
    return (
      <div className="semi-dark-inner-box tight fade-in delay">
        <ProductInput
          setInput={onSetProductInputs}
          product={product}
          showErrors={showInputErrors}
        />
      </div>
    );
  };

  const renderPeriod = () => {
    const productData = product.productData;
    let content;
    if (productData.periodMonths) {
      content = (
        <Translated
          translationKey="purchase_length_months"
          values={{ periodMonths: productData.periodMonths }}
        />
      );
    } else if (productData.periodDays) {
      content = (
        <Translated
          translationKey="purchase_length_days"
          values={{ periodDays: productData.periodDays }}
        />
      );
    }
    return <div className="larger">{content}</div>;
  };

  const renderLeftSideComponents = () => {
    const components = product.productData
      ? product.productData.components
      : [];
    const componentElements = [];
    components.forEach((c) => {
      switch (c.type) {
        case "book":
          componentElements.push(<BookPromoComponent key={c.id} config={c} />);
          break;
      }
    });
    return componentElements;
  };

  const renderRightSideComponents = () => {
    const components = product.productData
      ? product.productData.components
      : [];
    const componentElements = [];
    components.forEach((c) => {
      switch (c.type) {
        case "image_preview":
          componentElements.push(<ImagesComponent key={c.id} config={c} />);
          break;
      }
    });
    return componentElements;
  };

  const onAddToCart = () => {
    if (!productInputs) {
      setShowInputErrors(true);
      return;
    }

    props.onAddToCart(product, productInputs);
  };

  const category = product.productData.category;
  const imageSrc =
    "../../assets/category/" + category.toLowerCase() + "_white.png";
  const descriptionData = Object.assign({}, product, product.productData);
  const description = translate(
    product.localizations["description"],
    descriptionData
  );
  const classes = classNames("product-details-item", props.className);

  return (
    <div className={classes} key={product.id}>
      <div className="header">
        <div>
          <ImageSet src={imageSrc} className="right-margin" />
          <div className="title">{product.localizations["title_short"]}</div>
        </div>
        <div>
          <IoClose className="touchable largest" onClick={props.onCancel} />
        </div>
      </div>
      <div className="content-wrapper">
        <div className="first-content">
          <div dangerouslySetInnerHTML={{ __html: description }} />
          {renderPeriod()}
          {renderLeftSideComponents()}
        </div>
        <div className="second-content">
          {renderRightSideComponents()}
          <div className="purchase-content top-margin">
            <PriceDisplay
              size="large"
              price={product.vatPrice}
              className="inline"
            />
          </div>
        </div>
      </div>
      <div>
        {renderInputs()}
        {showInputs ? (
          <Button onClick={onAddToCart} className="left-margin">
            <Translated translationKey="next" />
          </Button>
        ) : null}
      </div>
      {product.isSale ? (
        <img
          src={saleImg}
          className="product-preview-sale-left top-margin-small"
        />
      ) : null}
    </div>
  );
};

export default ProductDetails;
