import axios from 'axios';
import cache from 'memory-cache';
import Logger from './utils/logger';
import Tracker from './utils/tracker';

const API_VERSION = '/api/v1';
const BASE_URL = '' + API_VERSION;

// Login
const LOGIN_EAUTOKOULU_URL = BASE_URL + '/login/eautokoulu';
const LOGIN_EA2INTEGRATION_URL = BASE_URL + '/login/ea2';
const LOGIN_LIIKENNEOPETUS_URL = BASE_URL + '/login/liikenneopetus';
const LOGIN_FACEBOOK_URL = BASE_URL + '/login/facebook';

//Connect
const CONNECT_FACEBOOK_URL = BASE_URL + '/connect/facebook';

// Common
const USER_EMAIL_REQUEST_CREATE_USER_WITH_FACEBOOK = BASE_URL + '/user-email-request/create-user-facebook/:dataToken';

//Teacher

//Shop

const LOGIN_TIMEOUT_MS = IS_PRODUCTION ? 10000 : Number.MAX_SAFE_INTEGER; // Debugging requires longer timeouts.
const REGULAR_TIMEOUT_MS = IS_PRODUCTION ? 20000 : Number.MAX_SAFE_INTEGER;

// Cache time in seconds
const CACHE_TIME_INFINITY = 0;
const CACHE_TIME_NEVER = -1;
const CACHE_TIME_VERY_SHORT = 3;
const CACHE_TIME_SHORT = 60;
const CACHE_TIME_LONG = 60 * 15;

let _store;

class AjokaistaApiClient {
 


  /**
   * Common
   */
 

  /**
   * Teacher
   */
  
  
  /**
   * Private
   */

  get(url, timeout, cacheTimeSec, forceReload = false) {
    return this.apiCall(url, 'get', undefined, cacheTimeSec, timeout, forceReload);
  }

  post(url, data, timeout) {
    return this.apiCall(url, 'post', data, CACHE_TIME_NEVER, timeout, true)
  }

  apiCall(url, method, data, cacheTimeSec, timeout, forceReload) {
    if (!forceReload) {
      const cachedResponse = cache.get(method + url);
      if (cachedResponse) {
        return new Promise((resolve, reject) => {
          resolve(cachedResponse);
        });
      }
    }

    const callParams =
      {
        method: method,
        url: url,
        data: data,
        headers: this.getHeader(),
        timeout: timeout
      };
    return axios(callParams)
      .then(response => {
        const token = response.headers.authorization;
        if (token) {
          // Token was updated from this api call, take new one into use.
          Logger.setUserData({token});
          _store.dispatch(tokenUpdated(token));
        }

        // Server adds custom header if this user is required to accept new terms of service.
        const tosVersion = parseInt(response.headers['x-required-tos']);
        if (tosVersion) {
          _store.dispatch(requireTos(tosVersion));
        }

        const data = response.data;
        if (cacheTimeSec !== CACHE_TIME_NEVER) {
          cache.put(method + url, data, cacheTimeSec === CACHE_TIME_INFINITY ? undefined : cacheTimeSec * 1000);
        }
        // Data should always be object or array
        return data;
      }, error => { // Use then(success, failure) syntax to prevent global catch
        if (error.response) {
          switch (error.response.status) {
            case 401:
              if (error.response.data.error === 'old-app-version') {
                if (!window.location.origin) { // Origin missing in old IE
                  window.location.reload(true);
                } else {
                  // Reload doesn't seem to work on Firefox
                  window.location.href = window.location.origin + '?' + Math.round(Math.random() * 1000000);
                }
              }
              // Unauthorized, log out
              console.log('Request was unauthorized');
              this.cacheClear();
              _store.dispatch(resetAuth(error.response.data.error));
              Tracker.logEvent('api', 'Unauthorized');
              break;
            case 404:
              window.location.reload(true);
              break;
            case 503:
              // Server is in maintenance
              _store.dispatch(maintenanceStatus(true));
              break;
          }
        } else {
          // No response, network maybe unavailable
          _store.dispatch(connectionStatus(false));
          Tracker.logEvent('api', 'Could not connect to server');
        }
        let errorMessage = AjokaistaApiClient.resolveErrorMessage(error);
        cache.clear();
        // Always return objects, errors have error field.
        return Promise.resolve({error: errorMessage});
      });
  }

  getAuthToken() {
    const token = _store.getState().login.get('token');
    return token ? token.get('encoded') : null;
  }

  getProfileSchoolId() {
    const token = _store.getState().login.get('token');
    if (!token){
      return null;
    }
    return token.get('schoolId');
  }

  getUserId() {
    const token = _store.getState().login.get('token');
    return token ? token.get('userId') : 'anon';
  }

  getHeader() {
    const token = this.getAuthToken();
    const userId = this.getUserId();
    const profileSchoolId = this.getProfileSchoolId();
    return {
      'Authorization': `Bearer ${token}`,
      'user-id': userId,
      'profile-school-id': profileSchoolId,
      'app-version': APP_VERSION,
      'build-version': BUILD_VERSION
    };
  }

  static resolveErrorMessage(error) {
    let errorMessage = null;

    /** Parse error and try to take the error or message from the response data */

    if (error.message) {
      errorMessage = error.message;
    }

    if (error.response && error.response.status === 500) {
      //Not a correct error, should be replaced in server too.
      errorMessage = 'unknown-error';
    }

    if (error.response && error.response.status === 503) {
      errorMessage = 'maintenance';
    }

    if (error.response && error.response.data.message) {
      errorMessage = error.response.data.message;
    }

    if (error.response && error.response.data.error) {
      errorMessage = error.response.data.error;
    }

    /** Try to resolve a more defined (localized code) out of the message. */

    if (errorMessage === 'Network Error') {
      errorMessage = 'network-error';
      Tracker.logEvent('api', 'Network error');
    }

    if (errorMessage === 'Unexpected token < in JSON at position 0') {
      errorMessage = 'invalid-response-format';
      Tracker.logEvent('api', 'Invalid response format');
    }

    if (errorMessage.indexOf('timeout') === 0) {
      errorMessage = 'request-timeout';
      Tracker.logEvent('api', 'Network timeout');
    }

    /** Check if all parsing failed */

    if (errorMessage === null || errorMessage === undefined || errorMessage === '') {
      errorMessage = 'unknown-error';
      Tracker.logEvent('api', 'Unknown error');
    }

    if (typeof errorMessage !== 'string') {
      errorMessage = 'unknown-error';
      Tracker.logEvent('api', 'Unknown error')
    }

    return errorMessage;
  }

}

const api = new AjokaistaApiClient();

export default api;
