import { useNavigate } from "react-router-dom";
import { IoLockClosedSharp, IoMail, IoPerson } from "react-icons/io5";
import classNames from "classnames";
import Alert from "react-bootstrap/Alert";
import PropTypes from "prop-types";

import Translated from "./Translated";

const TeacherList = (props) => {
  const navigate = useNavigate();

  const renderUsersElements = () => {
    const elements = [];
    props.users.forEach((user) => {
      const isClosed = user.isClosed;
      let classes = classNames(
        "ct-table-row touchable",
        isClosed ? "ct-table-row semi-transparent strike-through" : null
      );
      elements.push(
        <div
          className={classes}
          key={user.id}
          onClick={() => {
            navigate("/user/" + user.id);
          }}
        >
          <div className="ct-table-body-cell">
            <span className="ct-mobile-only">
              <IoPerson />
            </span>
            &nbsp;{user.name}
            <span className="ct-mobile-only">
              {isClosed && <IoLockClosedSharp />}
            </span>
          </div>
          <div className="ct-table-body-cell">
            <IoMail className="ct-mobile-only" />
            &nbsp;{user.email}
          </div>
          <div className="ct-table-body-cell">
            <span className="ct-desktop-only">
              {isClosed && <IoLockClosedSharp />}
            </span>
          </div>
        </div>
      );
    });
    return elements;
  };

  if (!props.users || props.users.length === 0) {
    return (
      <Alert>
        <Translated translationKey={props.noUsersTranslationKey} />
      </Alert>
    );
  } else {
    return (
      <div className={props.className}>
        <div className="white-box tight">
          <div className="custom-table full-width">
            <div className="custom-table-header">
              <div className="ct-column-name">
                <Translated translationKey="name" />
              </div>
              <div className="ct-column-name">
                <Translated translationKey="email" />
              </div>
              <div className="ct-column-name">
                <IoLockClosedSharp />
              </div>
            </div>
            <div className="ct-table-body">{renderUsersElements()}</div>
          </div>
        </div>
      </div>
    );
  }
};

TeacherList.propTypes = {
  noUsersTranslationKey: PropTypes.string.isRequired,
  users: PropTypes.array.isRequired,
};

export default TeacherList;
