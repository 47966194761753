import PropTypes from "prop-types";
import Alert from "react-bootstrap/Alert";
import { IoMail, IoPerson } from "react-icons/io5";

import Translated from "./Translated";
import DateUtil from "../../utils/date";
import Spinner from "./Spinner";
import { useUserData } from "../../context/UserDataContext";
import { usePendingUserCreates } from "../../api-new";

const UserCreatesPending = (props) => {
  const { schoolId } = useUserData();
  const pendingCreatesQuery = usePendingUserCreates(schoolId);

  const renderError = () => {
    return (
      <Alert>
        <Translated translationKey={pendingCreatesQuery.error.message} />
      </Alert>
    );
  };

  const renderRequestElements = () => {
    return pendingArr.map((req) => {
      let classes = req.hasExpired
        ? "ct-table-row line-through"
        : "ct-table-row";
      return (
        <div className={classes} key={req.id}>
          <div className="ct-table-body-cell">
            <span className="ct-mobile-only">
              <IoPerson />
            </span>
            &nbsp;{req.userName}
          </div>
          <div className="ct-table-body-cell">
            <span className="ct-mobile-only">
              <IoMail />
            </span>
            &nbsp;{req.email}
          </div>
          <div className="ct-table-body-cell">
            <strong className="ct-mobile-only">
              <Translated translationKey="date" />:
            </strong>
            &nbsp;{DateUtil.date(req.requestDate)}
          </div>
          <div className="ct-table-body-cell">
            <strong className="ct-mobile-only">
              <Translated translationKey="valid_until" />:
            </strong>
            &nbsp;{DateUtil.date(req.expiresAt)}
          </div>
        </div>
      );
    });
  };

  if (pendingCreatesQuery.isPending) {
    return <Spinner />;
  }

  if (pendingCreatesQuery.isError) {
    return renderError();
  }
  
  const pendingArr = pendingCreatesQuery.data.requests;
  if (!pendingArr || pendingArr.length === 0) {
    return null;
  } else {
    return (
      <div className={props.className}>
        <h4>
          <Translated translationKey="unactivated_requests" />
        </h4>
        <div className="white-box tight">
          <div className="custom-table full-width">
            <div className="custom-table-header">
              <div className="ct-column-name">
                <Translated translationKey="name" />
              </div>
              <div className="ct-column-name">
                <Translated translationKey="email" />
              </div>
              <div className="ct-column-name">
                <Translated translationKey="date" />
              </div>
              <div className="ct-column-name">
                <Translated translationKey="valid_until" />
              </div>
            </div>
            <div className="ct-table-body">{renderRequestElements()}</div>
          </div>
        </div>
      </div>
    );
  }
};

UserCreatesPending.propTypes = {
  schoolId: PropTypes.string.isRequired,
};

export default UserCreatesPending;
