import { useState } from "react";
import PreloadMiddlePage from "./../pages/PreloadMiddlePage";
import ImagesStart from "../feature/practise/images/ImagesStart";
import PractiseResults from "../feature/practise/PractiseResults";
import PracticeImagesMain from "../feature/practise/images/PracticeImagesMain";
import Translated from "../feature/Translated";
import CategorySelector from "../feature/CategorySelector";
import ResetVehicleCategoryButton from "../feature/ResetVehicleCategoryButton";
import { useUserData } from "../../context/UserDataContext";
import { usePracticeTypes } from "../../api-new";

const PracticeImagesPage = () => {
  const { userSettings } = useUserData();
  const [started, setStarted] = useState(false);
  const [result, setResult] = useState(null);
  const practiceTypesQuery = usePracticeTypes(userSettings.vehicleCategory);

  const start = () => {
    setStarted(true);
    setResult(null);
  };

  const renderStart = () => {
    if (practiceTypesQuery.data.images) {
      const category = userSettings.vehicleCategory;
      return (
        <div>
          <ImagesStart category={category} start={start} />
          <ResetVehicleCategoryButton bsstyle="link" />
        </div>
      );
    } else {
      return (
        <div className="blue-inner-box">
          <Translated translationKey="category_has_no_images" />
        </div>
      );
    }
  };

  let content;
  if (!userSettings.data) {
    content = <PreloadMiddlePage />;
  } else if (!userSettings.vehicleCategory) {
    content = <CategorySelector />;
  } else if (!practiceTypesQuery.data) {
    content = <PreloadMiddlePage />;
  } else if (!started) {
    content = renderStart();
  } else if (!result) {
    content = (
      <PracticeImagesMain
        category={userSettings.vehicleCategory}
        setResult={setResult}
      />
    );
  } else {
    content = (
      <PractiseResults
        correct={result.correct}
        total={result.total}
        restart={start}
        buttonId="practise-images-restart"
      />
    );
  }

  return (
    <div
      id="practise-images-page"
      className="page-container max-width-container"
    >
      {content}
    </div>
  );
};

export default PracticeImagesPage;
