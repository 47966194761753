import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import DemoImagesStart from "../feature/practise/images/DemoImagesStart";
import DemoPractiseResults from "../feature/practise/DemoPractiseResults";
import PracticeImagesMain from "../feature/practise/images/PracticeImagesMain";
import TrackerAB from "../../utils/trackerAB";

const DemoImagesPage = () => {
  const [started, setStarted] = useState(false);
  const [result, setResult] = useState(null);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      if (started && !result) TrackerAB.track("demo-images-canceled");
    };
  }, []);

  const start = () => {
    TrackerAB.track("demo-images-started", { category: params.category });
    setStarted(true);
    setResult(null);
  };

  const setFinishResult = (result) => {
    TrackerAB.track("demo-images-finished", {
      "demo-images-score": result.correct,
    });
    setResult(result);
  };

  const backToShop = () => {
    // TODO Use currect vehicle category to take user back to subcategory page (opetuslupa, traktori etc)
    navigate("/kauppa");
  };

  let content;
  if (!started) {
    content = (
      <DemoImagesStart
        start={start}
        category={params.category}
        toShop={backToShop}
      />
    );
  } else if (!result) {
    content = (
      <PracticeImagesMain
        category={params.category}
        setResult={setFinishResult}
        isDemo={true}
      />
    );
  } else {
    content = (
      <DemoPractiseResults
        correct={result.correct}
        total={result.total}
        restart={start}
        buttonId="practise-images-restart"
        category={params.category}
        toShop={backToShop}
      />
    );
  }

  return (
    <div
      id="practise-images-page"
      className="page-container max-width-container"
    >
      {content}
    </div>
  );
};

export default DemoImagesPage;
