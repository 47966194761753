import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IoReader, IoMail } from "react-icons/io5";
import Button from "react-bootstrap/Button";

import LoginForm from "../feature/LoginForm";
import LoginFormEmail from "../feature/LoginFormEmail";
import Translated from "../feature/Translated";
import News from "../feature/News";
import DataPrivacyNote from "../feature/DataPrivacyNote";
import { useUserData } from "../../context/UserDataContext";

const LoginPage = () => {
  const { token } = useUserData();
  const navigate = useNavigate();
  const location = useLocation();
  const [loginType, setLoginType] = useState(null);

  useEffect(() => {
    if (token) {
      // Login happened
      navigate("/", {
        state: location.state,
        replace: true,
      });
    }
  }, [token]);

  const renderLoginEmailButton = () => {
    return (
      <Button
        aria-label="Login with email"
        role="button"
        onClick={() => setLoginType("email")}
        size="lg"
        className="full-width fade-in top-margin"
      >
        <IoMail />
        <Translated translationKey="login_with_email" />
      </Button>
    );
  };

  const renderLoginOneTimeKeyButton = () => {
    return (
      <Button
        aria-label="Login with one-time-keys"
        role="button"
        onClick={() => setLoginType("one-time-key")}
        size="lg"
        className="full-width fade-in top-margin"
      >
        <IoReader />
        <Translated translationKey="login_with_one_time_key" />
      </Button>
    );
  };

  const renderTeacherButton = () => {
    return (
      <div>
        <Button
          aria-label="Login with one-time-keys"
          role="button"
          size="lg"
          onClick={() => navigate("/login-teacher")}
          className="fade-in top-margin"
        >
          <Translated translationKey="login" />
        </Button>
      </div>
    );
  };

  let form;
  let buttons;
  let emailLoginIconHeader = <IoMail />;
  let oneTimeKeyLoginIconHeader = <IoReader />;

  if (loginType === "email") {
    const formHeader = (
      <div className="gray">
        {emailLoginIconHeader}&nbsp;
        <Translated translationKey="login_email" className="left-margin" />
      </div>
    );
    form = <LoginFormEmail key="email-form" header={formHeader} />;
    buttons = <>{renderLoginOneTimeKeyButton()}</>;
  } else if (loginType === "one-time-key") {
    const formHeader = (
      <div className="gray">
        {oneTimeKeyLoginIconHeader}&nbsp;
        <Translated
          translationKey="login_one_time_key"
          className="left-margin"
        />
      </div>
    );
    form = <LoginForm header={formHeader} />;
    buttons = <>{renderLoginEmailButton()}</>;
  } else {
    form = null;
    buttons = (
      <>
        {renderLoginOneTimeKeyButton()}
        {renderLoginEmailButton()}
      </>
    );
  }

  return (
    <div
      id="login-page"
      className="page-container page-gutter max-width-container"
    >
      <div className="front-center-wrapper medium-top-margin">
        <News simple className="fade-in long-delay" />

        <h1>
          <Translated translationKey="login" />
        </h1>

        <div>
          <div className="login-form-container fade-in">{form}</div>
        </div>

        <div>
          <div className="login-buttons-container top-margin">
            {buttons}
            <div className="semi-dark-inner-box tight large-top-margin">
              <Translated translationKey="login_school_teacher" />
              {renderTeacherButton()}
            </div>
            <DataPrivacyNote className="semi-dark-inner-box centered" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
