import Alert from "react-bootstrap/Alert";
import classNames from "classnames";
import { IoAlertOutline } from "react-icons/io5";

import Translated from "../Translated";
import api from "../../../api";
import Spinner from "./../../feature/Spinner";
import { useUpdatePaymentMethodId } from "../../../api-new";
import { useRef } from "react";

const PaymentMethods = (props) => {
  const paymentMethodMutation = useUpdatePaymentMethodId();
  const formRefs = useRef({});

  const doPaymentMethod = (paymentMethodId) => {
    if (paymentMethodMutation.isPending) {
      return;
    }

    paymentMethodMutation.mutate(
      {
        orderId: props.order.id,
        orderToken: props.order.token,
        paymentMethodId,
      },
      {
        onSuccess: (data) => {
          const paymentFormRef = formRefs.current[paymentMethodId];
          if (paymentFormRef) {
            paymentFormRef.submit();
          }
        },
      }
    );
  };

  const renderPaymentMethods = () => {
    const spinner = paymentMethodMutation.isPending ? (
      <Spinner className={"larger"} />
    ) : null;

    let methodsElem = (
      <div>
        <div className="white-box payment-methods">
          {props.paymentMethods.map((method) => {
            return (
              <div key={method.name} className="method-icon">
                <form
                  action={method.url}
                  method="post"
                  ref={(el) => (formRefs.current[method.id] = el)}
                >
                  {method.parameters.map((param) => {
                    return (
                      <input
                        type="hidden"
                        name={param.name}
                        value={param.value}
                        key={param.name}
                      />
                    );
                  })}
                  <img
                    alt={method.name}
                    src={method.icon}
                    onClick={() => doPaymentMethod(method.id)}
                    className="touchable"
                  />
                </form>
              </div>
            );
          })}
        </div>
        {spinner}
      </div>
    );

    return (
      <div>
        <h3>
          <Translated translationKey="payment_method" />
        </h3>
        {methodsElem}
      </div>
    );
  };

  const renderError = () => {
    return (
      <Alert>
        <IoAlertOutline />{" "}
        <Translated translationKey={paymentMethodMutation.error.message} />
      </Alert>
    );
  };

  const classes = classNames(props.className);
  return (
    <div className={classes}>
      {paymentMethodMutation.isError ? renderError() : renderPaymentMethods()}
    </div>
  );
};

export default PaymentMethods;
