import PropTypes from "prop-types";
import { useTranslation } from "./TranslationProvider";

function filterProps(props) {
  const spanProps = Object.assign({}, props);
  delete spanProps["translationKey"];
  delete spanProps["values"];
  delete spanProps["translate"];
  delete spanProps["options"];
  delete spanProps["html"];
  return spanProps;
}

const Translated = (props) => {
  const { translate } = useTranslation();
  const translation = translate(
    props.translationKey,
    props.values,
    props.options
  );
  if (props.html) {
    return (
      <span
        dangerouslySetInnerHTML={{ __html: translation }}
        {...filterProps(props)}
      />
    );
  } else {
    return <span {...filterProps(props)}>{translation}</span>;
  }
};

Translated.propTypes = {
  translationKey: PropTypes.string.isRequired,
  values: PropTypes.object,
  options: PropTypes.object
};

export default Translated;
