import { useState } from "react";
import Card from "react-bootstrap/Card";
import Alert from "react-bootstrap/Alert";
import FormGroup from "react-bootstrap/FormGroup";
import InputGroup from "react-bootstrap/InputGroup";
import ControlLabel from "react-bootstrap/FormLabel";
import FormControl from "react-bootstrap/FormControl";
import { IoPerson, IoMail } from "react-icons/io5";
import { BsFillTelephoneFill } from "react-icons/bs";
import Button from "react-bootstrap/Button";

import Translated from "../feature/Translated";
import Spinner from "./../feature/Spinner";
import LicenseAgreementNote from "../feature/LicenseAgreementNote";
import { useTrialContact } from "../../api-new";
import { useTranslation } from "../feature/TranslationProvider";

const ContactTrialPage = () => {
  const { translate } = useTranslation();
  const [form, setForm] = useState({
    schoolName: "",
    streetAddress: "",
    zipCode: "",
    postalLocation: "",
    contactPerson: "",
    phoneNumber: "",
    email: "",
    message: "",
  });
  const trialContactMutation = useTrialContact();

  const isFormValid = () => {
    const requiredFields = [
      "schoolName",
      "streetAddress",
      "zipCode",
      "postalLocation",
      "contactPerson",
      "phoneNumber",
      "email",
    ];
    let isValid = true;
    requiredFields.forEach((key) => {
      const value = form[key];
      if (value === "") {
        isValid = false;
      }
    });
    return isValid;
  };

  const submitContact = () => {
    if (!isFormValid()) {
      return;
    }

    trialContactMutation.mutate(form);
  };

  const handleInputChange = (e) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const makeFormGroup = (label, props) => {
    return (
      <FormGroup>
        <ControlLabel>{label}</ControlLabel>
        <FormControl {...props} />
      </FormGroup>
    );
  };

  const renderForm = () => {
    const header = (
      <div className="centered">
        <Translated translationKey="trial_request" />
      </div>
    );
    const errorMessage = trialContactMutation.isError ? (
      <Alert bsstyle="warning">
        <Translated translationKey="message_error_sending" />
      </Alert>
    ) : null;

    return (
      <div id="contact-page" className="contact-wrapper fade-in">
        <div className="contact-description blue-inner-box">
          <Translated translationKey="contact_description" />
        </div>

        <Card className="contact-panel" header={header}>
          <Card.Body>
            <form>
              <fieldset disabled={trialContactMutation.isPending}>
                {makeFormGroup(translate("school_name"), {
                  name: "schoolName",
                  placeholder: translate("school_name"),
                  value: form.schoolName,
                  onChange: handleInputChange,
                })}

                {makeFormGroup(translate("street_address"), {
                  name: "streetAddress",
                  placeholder: translate("street_address"),
                  value: form.streetAddress,
                  onChange: handleInputChange,
                })}

                <FormGroup>
                  <ControlLabel>
                    <Translated translationKey="postal_address" />
                  </ControlLabel>
                  <InputGroup>
                    <FormControl
                      type="text"
                      name="zipCode"
                      value={form.zipCode}
                      onChange={(e) => handleInputChange(e)}
                      className="contactform-zip"
                      placeholder={translate("zip_code")}
                    />
                    <FormControl
                      type="text"
                      name="postalLocation"
                      value={form.postalLocation}
                      onChange={(e) => handleInputChange(e)}
                      placeholder={translate("postal_location")}
                    />
                  </InputGroup>
                </FormGroup>

                <br />

                <FormGroup id="person">
                  <ControlLabel>
                    <Translated translationKey="contact_person" />
                  </ControlLabel>
                  <InputGroup>
                    <InputGroup.Text>
                      <IoPerson className="white" />
                    </InputGroup.Text>
                    <FormControl
                      type="text"
                      name="contactPerson"
                      value={form.contactPerson}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup id="phone">
                  <ControlLabel>
                    <Translated translationKey="phone_number" />
                  </ControlLabel>
                  <InputGroup>
                    <InputGroup.Text>
                      <BsFillTelephoneFill className="white" />
                    </InputGroup.Text>
                    <FormControl
                      type="text"
                      name="phoneNumber"
                      value={form.phoneNumber}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup id="email">
                  <ControlLabel>
                    <Translated translationKey="email" />
                  </ControlLabel>
                  <InputGroup>
                    <InputGroup.Text>
                      <IoMail className="white" />
                    </InputGroup.Text>
                    <FormControl
                      type="text"
                      name="email"
                      value={form.email}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup controlId="message">
                  <ControlLabel>
                    <Translated translationKey="message" />
                  </ControlLabel>
                  <FormControl
                    name="message"
                    componentclass="textarea"
                    value={form.message}
                    onChange={(e) => handleInputChange(e)}
                    className="contact-message-text-area"
                  />
                </FormGroup>

                <div className="centered">
                  {errorMessage}
                  <Button
                    id="submit-contact"
                    disabled={!isFormValid() || trialContactMutation.isPending}
                    onClick={submitContact}
                  >
                    <Translated translationKey="send" />
                  </Button>
                  {trialContactMutation.isPending ? (
                    <Spinner className="primary" />
                  ) : null}
                </div>
              </fieldset>
            </form>
          </Card.Body>
        </Card>
        <LicenseAgreementNote className="semi-dark-inner-box centered" />
      </div>
    );
  };

  const renderSubmittedMessage = () => {
    return (
      <div id="contact-submitted" className="contact-wrapper fade-in">
        <Card className="blue-box">
          <Card.Body>
            <h3 className="centered">
              <IoMail />
              &nbsp;&nbsp;
              <Translated translationKey="message_sent" />
            </h3>
          </Card.Body>
        </Card>
      </div>
    );
  };

  let content;
  if (trialContactMutation.isSuccess) {
    content = renderSubmittedMessage();
  } else {
    content = renderForm();
  }

  return (
    <div className="page-container max-width-container">
      <div className="centered">{content}</div>
    </div>
  );
};

export default ContactTrialPage;
