import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import PreloadMiddlePage from "../../../pages/PreloadMiddlePage";
import Line from "../../ProgressBarLine";
import Translated from "../../Translated";
import ImagesQuestion from "./ImagesQuestion";
import ResultFeedbackForm from "../../results/ResultFeedbackForm";
import MapUtil from "../../../../utils/map";
import BookModalLink from "../../BookModalLink";
import { IoChatbubblesSharp } from "react-icons/io5";
import VehicleInfo from "../../VehicleInfo";
import { hasVehicleInfo } from "../../../../data/vehicleInfoCategories";
import {
  useDemoImages,
  usePracticeImages,
  usePracticeLog,
} from "../../../../api-new";
import { useTranslation } from "../../TranslationProvider";

const PracticeImagesMain = (props) => {
  const { lang } = useTranslation();
  const [questionIndex, setQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [seenIds, setSeenIds] = useState([]);
  const [showFeedback, setShowFeedback] = useState(false);
  const practiceLogMutation = usePracticeLog();
  const practiceImagesMutation = usePracticeImages();
  const demoQuery = useDemoImages(props.category, lang);
  const images = props.isDemo ? demoQuery : practiceImagesMutation;

  useEffect(() => {
    if (props.isDemo) {
      demoQuery.refetch();
    } else {
      practiceImagesMutation.mutate({
        category: props.category,
        lang,
        seenIds,
      });
    }

    setQuestionIndex(0);
    setAnswers([]);
  }, []);

  useEffect(() => {
    preloadNextImage();
  }, [questionIndex]);

  const preloadNextImage = () => {
    if (!images.data || !images.data.questions) {
      return;
    }

    const nextIndex = questionIndex + 1;
    if (nextIndex < images.data.questions.length) {
      const nextQuestion = images.data.questions[nextIndex];
      const img = new Image();
      img.src = nextQuestion.imageUrl;
    }
  };

  const setAnswer = (answer) => {
    const newAnswers = answers.slice();
    if (newAnswers[questionIndex]) {
      return;
    }
    newAnswers[questionIndex] = answer;

    const newSeenIds = seenIds.slice();
    const question = images.data.questions[questionIndex];
    // Mark as seen if answered correctly, otherwise it may be asked even on the very next round again.
    if (question.isAssertionTruthy === answer) {
      if (newSeenIds.indexOf(question.id) === -1) {
        newSeenIds.push(question.id);
        if (newSeenIds.length > 30) {
          newSeenIds.shift();
        }
      }
    }

    setAnswers(newAnswers);
    setSeenIds(newSeenIds);
  };

  const nextQuestion = () => {
    const nextIndex = questionIndex + 1;
    setQuestionIndex(nextIndex);
    setShowFeedback(false);

    if (nextIndex === images.data.questions.length) {
      let faults = 0;
      images.data.questions.forEach((question, index) => {
        let answer = answers[index];
        if (answer !== question.isAssertionTruthy) {
          faults++;
        }
      });

      const total = images.data.questions.length;
      const correct = total - faults;

      props.setResult({ total, correct });

      if (!props.isDemo) {
        practiceLogMutation.mutate({
          category: props.category,
          practiceType: "images",
          numQuestions: images.data.questions.length,
          numFaults: faults,
        });
      }
    }
  };

  const renderQuestion = () => {
    const question = images.data.questions[questionIndex];
    const answer =
      questionIndex < answers.length ? answers[questionIndex] : null;
    const current = questionIndex + 1;
    const total = images.data.questions.length;
    const progressRatio = current / total;

    let explanations;
    let buttons;
    let bookContent;
    if (answer !== null) {
      explanations = renderExplanations(question.descriptions);
      bookContent = renderBookContent(question, answer);
      buttons = renderAnswerContent(question);
    }

    const vehicleInfo = hasVehicleInfo(props.category) && (
      <div className="blue-box semi-dark-inner-box tight fade-in long-delay">
        <VehicleInfo category={props.category} />
      </div>
    );

    return (
      <div className="practise-images-container practise-wrapper">
        <div className="practise-progress-wrapper fade-in long-delay">
          <Line className="yellow" percentage={progressRatio * 100} />
        </div>
        <ImagesQuestion
          question={question}
          index={questionIndex}
          setAnswer={setAnswer}
          key={question.id}
        />
        {explanations}
        {bookContent}
        {buttons}
        {vehicleInfo}
      </div>
    );
  };

  const renderAnswerContent = (question) => {
    let feedbackButton;
    if (!props.isDemo) {
      feedbackButton = (
        <Button
          bsstyle="link"
          className="fade-in long-delay"
          onClick={() => setShowFeedback((prev) => !prev)}
        >
          <IoChatbubblesSharp className="larger text-bottom" />
          <Translated translationKey="question_report" />
        </Button>
      );
    } else {
      feedbackButton = <span>&nbsp;</span>;
    }

    let feedbackForm;
    if (showFeedback) {
      feedbackForm = (
        <ResultFeedbackForm
          keepOpen
          questionType="image"
          questionId={question.id}
          className="fade-in"
        />
      );
    }
    return (
      <div>
        <div className="min-button-space literals-buttons-container">
          {feedbackButton}
          <Button
            id="practise-images-next-button"
            onClick={nextQuestion}
            className="fade-in delay"
          >
            <Translated translationKey="next" />
          </Button>
        </div>
        {feedbackForm}
      </div>
    );
  };

  const getBookReferencesMap = () => {
    const bookReferences = images.data.bookReferences;
    return MapUtil.mapFromObject(bookReferences);
  };

  const renderBookContent = (question, answer) => {
    const bookReferencesMap = getBookReferencesMap();

    // Do not add book reference link if there is none, or user does not have them linked (no rights)
    if (
      question.bookReferenceIds.length === 0 ||
      !bookReferencesMap ||
      bookReferencesMap.size === 0
    ) {
      return null;
    }
    const animate = question.isAssertionTruthy !== answer;
    const bookLinks = question.bookReferenceIds.map((refId) => {
      const content = bookReferencesMap.get(refId);
      return <BookModalLink content={content} animate={animate} key={refId} />;
    });
    return <div className="book-links fade-in">{bookLinks}</div>;
  };

  const renderExplanations = (explanations) => {
    const elems = explanations.map((exp) => {
      const src = exp.imageUrl;

      let img;
      if (src) {
        img = (
          <div>
            <img className="test-result-image-explanation-image" src={src} />
          </div>
        );
      }
      return (
        <div
          key={exp.id}
          className="test-result-image-explanation-wrapper fade-in"
        >
          {img}
          <div>{exp.text}</div>
        </div>
      );
    });
    return (
      <div className="practise-images-explanations-container blue-box">
        {elems}
      </div>
    );
  };

  let content;
  if (images.isError) {
    content = <PreloadMiddlePage error={images.error.message} />;
  } else if (images.isPending || !images.data) {
    content = <PreloadMiddlePage />;
  } else if (questionIndex < images.data.questions.length) {
    content = renderQuestion();
  } else {
    return null;
  }

  return content;
};

export default PracticeImagesMain;
