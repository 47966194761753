import Button from "react-bootstrap/Button";
import { IoCheckmarkSharp, IoAlertOutline } from "react-icons/io5";
import Alert from "react-bootstrap/Alert";
import classNames from "classnames";

import Translated from "./../feature/Translated";
import EmailConnect from "./EmailConnect";
import { useUserData } from "../../context/UserDataContext";
import { useEmailRecovery } from "../../api-new";
import Spinner from "./Spinner";
import { useTranslation } from "./TranslationProvider";

const EmailSettings = (props) => {
  const { email } = useUserData();
  const { lang } = useTranslation();
  const emailRecoveryMutation = useEmailRecovery();

  const resetPassword = () => {
    emailRecoveryMutation.mutate({ email, lang });
  };

  const renderError = () => {
    if (!emailRecoveryMutation.isError) {
      return null;
    }

    return (
      <Alert>
        <div>
          <IoAlertOutline />
          <Translated translationKey={emailRecoveryMutation.error.message} />
        </div>
      </Alert>
    );
  };

  const renderReceipt = () => {
    if (!emailRecoveryMutation.data) {
      return null;
    }

    return (
      <Alert bsstyle="success" className="top-margin">
        <Translated translationKey="password_reset_is_sent" />
      </Alert>
    );
  };

  let content;
  if (email) {
    content = (
      <div className="white-box">
        <div>
          <Translated translationKey="email_is_connected" />
          <IoCheckmarkSharp className="left-margin" />
        </div>
        <div className="top-margin">
          {email}
          <Button onClick={() => resetPassword()} className="left-margin">
            <Translated translationKey="email_reset_password" />
            {emailRecoveryMutation.isPending && <Spinner />}
          </Button>
        </div>
        {renderError()}
        {renderReceipt()}
      </div>
    );
  } else {
    content = <EmailConnect />;
  }

  return (
    <div className={classNames("email-settings-wrapper", props.className)}>
      <h4>
        <Translated translationKey={"email_settings_title"} />
      </h4>
      {content}
    </div>
  );
};

export default EmailSettings;
