import Button from "react-bootstrap/Button";
import { IoThumbsUpSharp } from "react-icons/io5";

import Translated from "../Translated";
import ImagesComponent from "../shop/ImagesComponent";
import { useTranslation } from "../TranslationProvider";
//import FeedbackPoll from '../FeedbackPoll';

const DemoPractiseResults = (props) => {
  const { translate } = useTranslation();

  const getImageSetId = () => {
    switch (props.category) {
      case "t":
        return "t_class";
      case "m":
        return "m_class";
      default:
        return "generic";
    }
  };

  const total = props.total;
  const correctCount = props.correct;
  const resultType = correctCount === total ? "positive" : "neutral";
  const feedbackKey = correctCount === total ? "demo_success" : "demo_fail";
  const decorator =
    resultType === "positive" ? (
      <IoThumbsUpSharp className="practise-result-decorator fade-in long-delay" />
    ) : null;

  const adText = translate("demo_ad");

  return (
    <div className="centered fade-in long-delay">
      <h3>
        <Translated
          translationKey="correct_from_total"
          values={{ correct: correctCount, total: total }}
        />
      </h3>
      {decorator}
      <h4 className="top-margin">
        <Translated translationKey={feedbackKey} />
      </h4>
      <div className="blue-inner-box tight large-top-margin">
        <span dangerouslySetInnerHTML={{ __html: adText }} />
      </div>
      <Button
        bsstyle="warning"
        id={props.buttonId}
        onClick={() => props.toShop()}
        className="top-margin"
      >
        <Translated translationKey="back_to_shop" />
      </Button>
      <div className="medium-top-margin">
        <ImagesComponent config={{ setId: getImageSetId() }} />
      </div>
      {/*<FeedbackPoll faults={total-correctCount}/>*/}
    </div>
  );
};

export default DemoPractiseResults;
