import { useState } from "react";
import {
  IoAlertOutline,
  IoPrintOutline,
  IoLockClosedSharp,
  IoLockOpenSharp,
  IoCalendarClearSharp,
  IoReader,
  IoLogoFacebook,
  IoMail,
} from "react-icons/io5";
import FormControl from "react-bootstrap/FormControl";
import Alert from "react-bootstrap/Alert";
import { Card, FormGroup } from "react-bootstrap";
import { RiEdit2Fill, RiCheckFill, RiCloseFill } from "react-icons/ri";
import Form from "react-bootstrap/Form";
import { BsFillShieldLockFill } from "react-icons/bs";
import Button from "react-bootstrap/Button";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";

import Spinner from "./Spinner";
import DateUtil from "../../utils/date";
import Tracker from "../../utils/tracker";
import Translated from "./Translated";
import UserAddEmail from "./UserAddEmail";
import { Roles, fillsRole } from "../../data/roles";
import SubscriptionPeriods from "./admin/SubscriptionPeriods";
import { useEmailOneTimeKeys, useEmailRecovery } from "../../api-new";
import { useTranslation } from "./TranslationProvider";

const EditUser = (props) => {
  const { translate } = useTranslation();
  const [showPrintableCodes, setShowPrintableCodes] = useState(false);
  const [name, setName] = useState(false);
  const [isEditingUserName, setIsEditingUserName] = useState(false);
  const [userName, setUserName] = useState(props.profile.userData.name);
  const [customDayPeriod, setCustomDayPeriod] = useState(
    props.profile.userData.name
  );
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [email, setEmail] = useState(props.profile.userData.email || "");
  const [isModalPasswordRecovery, setIsModalPasswordRecovery] = useState(false);
  const [showNamingModal, setShowNamingModal] = useState(false);
  const [showAddEmail, setShowAddEmail] = useState(false);

  const emailRecoveryMutation = useEmailRecovery();
  const oneTimeKeysMutation = useEmailOneTimeKeys();
  const profile = props.profile;

  const isProfileClosed = () => {
    return profile.userData.isClosed || profile.userData.isClosedByDate;
  };

  const isProfileActivated = () => {
    return profile.userData.activationDate !== null;
  };

  const validateEmail = (email) => {
    return /.@.+\../.test(email);
  };

  const saveUserData = (overridingData, callback) => {
    Tracker.logEvent("user", "edit");
    props.saveUserMethod(
      Object.assign({}, profile.userData, overridingData),
      callback
    );
  };

  const saveClosingInDays = (days) => {
    saveUserData({ setOpenForDays: days });
  };

  const saveChangedUserName = (name) => {
    saveUserData({ name: name }, () => setIsEditingUserName(false));
  };

  const disconnectEmail = () => {
    props.disconnectEmail();
  };

  const removeEmailPassword = () => {
    props.removeEmailPassword();
  };

  const sendResetPasswordLink = (e) => {
    e.preventDefault();
    const userData = profile.userData;

    emailRecoveryMutation.mutate({
      email: userData.email,
      lang: userData.schoolLanguage,
    });
  };

  const renderResetPasswordModal = () => {
    return (
      <Modal
        show={isModalPasswordRecovery}
        onHide={() => setIsModalPasswordRecovery(false)}
      >
        <Modal.Body>
          <Card id="recover-email-password-panel" className="login-form-well">
            <Card.Body>
              <h3>
                <Translated translationKey="recover_email_password_info" />
              </h3>
              <form id="email-login-form" onSubmit={sendResetPasswordLink}>
                <FormGroup>
                  <FormControl
                    id="email-to-recover"
                    type="text"
                    name="email"
                    value={profile.userData.email}
                    readOnly
                  />

                  <div className="field-spacer" />
                </FormGroup>
                <Button
                  id="email-request-create-password-submit"
                  type="submit"
                  disabled={
                    emailRecoveryMutation.isPending ||
                    emailRecoveryMutation.isSuccess
                  }
                  className="submit-login-button strong-text"
                >
                  {emailRecoveryMutation.isPending ? <Spinner /> : <Translated translationKey={"recover"} />}
                </Button>
              </form>
              {emailRecoveryMutation.isSuccess && (
                <Alert>
                  <Translated translationKey="recovery_is_sent" />
                </Alert>
              )}
              {emailRecoveryMutation.isError && (
                <Alert className="p-0 my-1">
                  <Translated
                    translationKey={emailRecoveryMutation.error.message}
                  />
                </Alert>
              )}
            </Card.Body>
          </Card>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={() => setIsModalPasswordRecovery(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const renderPrintableCodes = () => {
    if (fillsRole(profile.userData.role, Roles.TEACHER)) {
      return null;
    }

    const codeElems = [];
    profile.oneTimeKeys.forEach((code) => {
      if (code.activationDate && !code.isOpen) {
        codeElems.push(
          <div key={code.keyStr}>
            <s>{code.keyStr.toUpperCase()}</s>
          </div>
        );
      } else {
        codeElems.push(
          <div key={code.keyStr}>{code.keyStr.toUpperCase()}</div>
        );
      }
    });

    return (
      <Modal
        show={showPrintableCodes}
        onHide={() => setShowPrintableCodes(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Translated translationKey="print" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="printable no-margin">
            <div>www.ajokaista.com</div>
            <h3>{profile.userData.name}</h3>
            <div>
              <i>(#{profile.userData.id})</i>
            </div>
            <h4>{profile.userData.schoolName}</h4>
            <div className="uppercase">
              <strong>
                <Translated translationKey="school_code" />:
              </strong>{" "}
              {profile.userData.schoolId}
            </div>
            <div className="uppercase">
              <strong>
                <Translated translationKey="login_code" />:
              </strong>
            </div>
            <br />
            {codeElems}
          </div>
          <br />
          <Alert>
            <IoAlertOutline />
            &nbsp;
            <Translated translationKey="suggest_email" />
          </Alert>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={() => window.print()}>
            <IoPrintOutline /> <Translated translationKey="print" />
          </Button>
          <div className="top-margin-small">{renderEmailForm()}</div>
        </Modal.Footer>
      </Modal>
    );
  };

  const renderEmailForm = () => {
    const isValidEmail = validateEmail(email);
    let emailFormContent;
    if (oneTimeKeysMutation.isIdle) {
      emailFormContent = (
        <Form inline="true">
          <FormControl
            type="text"
            value={email}
            placeholder={translate("email")}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button onClick={() => sendByEmail()} disabled={!isValidEmail}>
            <Translated translationKey="send" />
          </Button>
        </Form>
      );
    } else if (oneTimeKeysMutation.isPending) {
      emailFormContent = <Spinner />;
    } else if (oneTimeKeysMutation.isError) {
      emailFormContent = (
        <Translated translationKey={oneTimeKeysMutation.error.message} />
      );
    } else {
      emailFormContent = <Translated translationKey="message_sent" />;
    }
    return <div className="edit-user-email-form">{emailFormContent}</div>;
  };

  const sendByEmail = () => {
    oneTimeKeysMutation.mutate({
      userId: profile.userData.id,
      emailAddress: email,
    });
  };

  const saveUserName = () => {
    setShowNamingModal(false);
    saveUserData({ name }, toggleShowPrintableCodes);
  };

  const renderNamingModal = () => {
    return (
      <Modal show={showNamingModal}>
        <Modal.Body>
          <h4>
            <Translated translationKey="must_assign_name" />
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <Button bsstyle="primary" onClick={() => setShowNamingModal(false)}>
            <Translated translationKey="close" />
          </Button>
          <Form
            inline
            onSubmit={(e) => e.preventDefault()}
            className="edit-user-input-form"
          >
            <FormControl
              type="text"
              value={name}
              placeholder={translate("login_student_name")}
              onChange={(e) => setName(e.target.value)}
            />
            <Button onClick={saveUserName} disabled={!name}>
              <Translated translationKey="save" />
            </Button>
          </Form>
        </Modal.Footer>
      </Modal>
    );
  };

  const renderImage = (facebookUser) => {
    if (facebookUser) {
      return (
        <div>
          <img
            className="user-profile-image fade-in"
            src={facebookUser.pictureUrl}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  const renderNameElem = () => {
    const nameElem = (
      <div className="edit-user-name">
        {profile.userData.name}{" "}
        {props.isAdminView && (
          <RiEdit2Fill
            className="touchable"
            onClick={() => setIsEditingUserName(true)}
          />
        )}
        {props.isSaving ? <Spinner className="primary" /> : null}
      </div>
    );

    const editingNameElem = (
      <div className="edit-user-name">
        <div className="edit-username">
          <Form.Control
            autoFocus
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setIsEditingUserName(false);
                saveChangedUserName(userName);
              }
              if (e.key === "Escape") {
                setIsEditingUserName(false);
                setUserName(profile.userData.name);
              }
            }}
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            type="userName"
          />
          <RiCheckFill
            fontSize={32}
            className="touchable"
            onClick={() => saveChangedUserName(userName)}
          />
          <RiCloseFill
            fontSize={32}
            className="touchable"
            onClick={() => {
              setIsEditingUserName(false);
              setUserName(profile.userData.name);
            }}
          />
        </div>
        {props.isSaving ? <Spinner className="primary" /> : null}
      </div>
    );
    const userIdElem = (
      <span>
        <i>(ID #{profile.userData.id})</i>
      </span>
    );
    return (
      <div>
        {isEditingUserName ? editingNameElem : nameElem}
        {userIdElem}
      </div>
    );
  };

  const renderSchoolInfo = () => {
    const schoolNameElem = <div>{profile.userData.schoolName}</div>;
    const schoolIdElem = (
      <span>
        <i>(ID #{profile.userData.schoolId})</i>
      </span>
    );

    return (
      <div className="top-margin-small">
        {schoolNameElem}
        {schoolIdElem}
      </div>
    );
  };

  const renderLockElement = () => {
    if (profile.userData.isLocked !== true) {
      return null;
    }

    return (
      <Alert className="top-margin bottom-margin large">
        <BsFillShieldLockFill className="largest" />{" "}
        <Translated translationKey="user-account-locked" />
      </Alert>
    );
  };

  const renderSubscriptionPeriods = (userId) => {
    return <SubscriptionPeriods userId={userId} />;
  };

  const renderActivationDateElem = (date) => {
    if (date) {
      return (
        <div>
          <Translated translationKey="activation_date" />:{" "}
          {DateUtil.dateTime(date)}
        </div>
      );
    } else {
      return (
        <div>
          <Translated translationKey="activation_date" />
          :&nbsp;
          <Translated translationKey="not_yet_activated" />
        </div>
      );
    }
  };

  const renderOpenDaysElement = () => {
    const closingDays = [];
    if (props.isAdminView) {
      closingDays.push(
        <div key="customDays" className="p-2">
          <Form.Group>
            <Form.Label>
              <Translated translationKey="duration_days" />
            </Form.Label>
            <Form.Control
              value={customDayPeriod}
              onChange={(e) => setCustomDayPeriod(e.target.value)}
              type="number"
            />
          </Form.Group>
          <Button
            className="mt-2"
            onClick={() => saveClosingInDays(customDayPeriod)}
          >
            <Translated translationKey="set" />
          </Button>
        </div>
      );
    } else {
      [10, 35, 85].forEach((dayPeriod) =>
        closingDays.push(
          <Dropdown.Item
            id={dayPeriod + "d"}
            key={dayPeriod + "d"}
            onClick={() => saveClosingInDays(dayPeriod)}
          >
            {dayPeriod} <Translated translationKey="days" />
          </Dropdown.Item>
        )
      );
    }

    return (
      <DropdownButton
        id="closing-date"
        title={<Translated translationKey="set_closing_date" />}
      >
        {closingDays}
      </DropdownButton>
    );
  };

  const renderRemoveClosingDate = () => {
    return (
      <Button onClick={() => saveClosingInDays(null)}>
        <Translated translationKey="remove_closing_date" />
      </Button>
    );
  };

  const renderClosingElement = (
    isManuallyClosed,
    isClosedByDate,
    closingDate
  ) => {
    if (isManuallyClosed) {
      return (
        <div className="light-blue-inner-box tight highlight-border vertical-middle">
          <div className="user-edit-login-credential-icon hide-xs">
            <IoLockClosedSharp className="edit-user-lock-icon" />
          </div>
          <div>
            <strong>
              <Translated translationKey="user_account_closed" />
            </strong>
            <br />
            <Button
              id="edit-user-save-button"
              onClick={() => {
                saveUserData({ isClosed: false });
              }}
            >
              <Translated translationKey="open" />
            </Button>
          </div>
        </div>
      );
    } else if (isClosedByDate) {
      return (
        <div className="light-blue-inner-box tight highlight-border vertical-middle">
          <div className="user-edit-login-credential-icon hide-xs">
            <IoLockClosedSharp className="edit-user-lock-icon" />
          </div>
          <div>
            <strong>
              <Translated
                translationKey="user_account_closed_on"
                values={{ date: DateUtil.dateTime(closingDate) }}
              />
            </strong>
            <br />
            {renderOpenDaysElement()}
            {renderRemoveClosingDate()}
          </div>
        </div>
      );
    } else if (closingDate) {
      return (
        <div>
          <div className="light-blue-inner-box tight vertical-middle">
            <div className="user-edit-login-credential-icon hide-xs">
              <IoCalendarClearSharp className="edit-user-calendar-icon" />
            </div>
            <div>
              <Translated
                translationKey="user_account_closes_on"
                values={{ date: DateUtil.dateTime(closingDate) }}
              />
              <br />
              {renderOpenDaysElement()}
              {renderRemoveClosingDate()} <br />
            </div>
          </div>
          <Button
            id="edit-user-close-button"
            onClick={() => {
              saveUserData({ isClosed: true });
            }}
          >
            <IoLockClosedSharp /> <Translated translationKey="close" />
          </Button>
        </div>
      );
    } else {
      return (
        <div className="top-margin">
          <div>
            <Translated translationKey="automatic_closing_info" />
          </div>
          {renderOpenDaysElement()} <br />
          <Button
            id="edit-user-close2"
            onClick={() => {
              saveUserData({ isClosed: true });
            }}
          >
            <IoLockClosedSharp /> <Translated translationKey="close" />
          </Button>
        </div>
      );
    }
  };

  const renderUnactivatedOneTimeKeyCredentials = () => {
    const infoElem = (
      <div>
        <h4>
          <Translated translationKey="unactivated_codes" />
        </h4>
        <Translated translationKey="no_one_time_key_handout_info" />
      </div>
    );
    return renderLoginCredentials("one-time", infoElem);
  };

  const renderNoOneTimeKeyCredentials = () => {
    return renderLoginCredentials(
      "one-time",
      <Translated translationKey="no_one_time_key_credentials" />
    );
  };

  const renderOneTimeKeys = (usedKeyCount, totalKeyCount) => {
    const keysElem = (
      <div>
        <Translated translationKey="one_time_keys_used_amount" />:{" "}
        {usedKeyCount}/{totalKeyCount}
        <br />
        {usedKeyCount > 0 ? renderOneTimeKeysResetButton() : null}
        <Button
          disabled={isProfileClosed(profile)}
          onClick={toggleShowPrintableCodes}
        >
          <Translated translationKey="view" />
        </Button>
      </div>
    );
    return renderLoginCredentials("one-time", keysElem);
  };

  const renderOneTimeKeysResetButton = () => {
    return (
      <Button
        disabled={isProfileClosed(profile)}
        onClick={() => {
          saveUserData({ resetKeys: true });
        }}
      >
        <span className="hide-xs">
          <Translated translationKey="reset_keys" />
        </span>
        <span className="hide-sm-and-gt">
          <IoLockOpenSharp />
          <Translated translationKey="open" />
        </span>
      </Button>
    );
  };

  const toggleShowPrintableCodes = () => {
    if (!profile.userData.name) {
      Tracker.logModalView("print-codes-naming");
      setShowNamingModal(true);
      setName("");
    } else {
      Tracker.logModalView("print-codes");
      setShowPrintableCodes(true);
      setName("");
    }
  };

  const renderNoEmailCredentials = () => {
    const statusElem = (
      <div>
        <Translated translationKey="no_email_credentials" />
        <br />
        <Button
          disabled={isProfileClosed() || !isProfileActivated()}
          onClick={() => setShowAddEmail(true)}
        >
          <Translated translationKey="add" />
        </Button>
        {renderAddEmailModal()}
      </div>
    );
    return renderLoginCredentials("email", statusElem);
  };

  const renderEmailCredentials = () => {
    if (isEditingEmail) {
      return renderLoginCredentials(
        "email",
        <div className="edit-user-name">
          <div className="edit-username">
            <Form.Control
              autoFocus
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  props.changeUserEmail(email, () => setIsEditingEmail(false));
                }
                if (e.key === "Escape") {
                  setIsEditingEmail(false);
                  props.resetAdminError("changeEmailError");
                }
              }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
            />

            <RiCheckFill
              fontSize={32}
              className="touchable"
              onClick={() =>
                props.changeUserEmail(email, () => {
                  setIsEditingEmail(false);
                  setEmail(email);
                })
              }
            />
            <RiCloseFill
              fontSize={32}
              className="touchable"
              onClick={() => {
                setIsEditingEmail(false);
                setEmail(profile.userData.email);
              }}
            />
          </div>
          {props.adminErrors.changeEmailError && (
            <Alert className="p-0 my-1">
              <Translated translationKey={props.adminErrors.changeEmailError} />
            </Alert>
          )}
          {props.isSaving ? <Spinner className="primary" /> : null}
        </div>
      );
    }
    const emailText = translate("can_login_email", {
      email: profile.userData.email,
    });
    const hasEmail = !!profile.userData.email;
    const hasEmailPassword = profile.userData.hasEmailPassword;
    return renderLoginCredentials(
      "email",
      <>
        <span>
          {emailText}&nbsp;
          {props.isAdminView && (
            <RiEdit2Fill
              className="touchable"
              onClick={() => setIsEditingEmail(true)}
            />
          )}
        </span>
        <div className="mt-1 p-0">
          <Button
            className="mt-1"
            onClick={() => setIsModalPasswordRecovery(true)}
          >
            <Translated translationKey="send_reset_link" />
          </Button>
          {props.isAdminView && (
            <div>
              {hasEmail && (
                <>
                  <Button onClick={disconnectEmail}>
                    <Translated translationKey="disconnect" />
                  </Button>
                  {props.adminErrors.disconnectEmailError && (
                    <Alert className="p-0 my-1">
                      <Translated
                        translationKey={props.adminErrors.disconnectEmailError}
                      />
                    </Alert>
                  )}
                </>
              )}
              <br />
              {hasEmailPassword ? (
                <>
                  <Button onClick={removeEmailPassword}>
                    <Translated translationKey="remove_password" />
                  </Button>
                  {props.adminErrors.removeEmailPasswordError && (
                    <Alert className="p-0 my-1">
                      <Translated
                        translationKey={
                          props.adminErrors.removeEmailPasswordError
                        }
                      />
                    </Alert>
                  )}
                </>
              ) : (
                <Alert className="p-1 m-0">
                  <Translated translationKey={"no_password"} />
                </Alert>
              )}
            </div>
          )}
        </div>
      </>
    );
  };

  const renderLoginCredentials = (iconElemId, statusElem) => {
    let iconElem;
    switch (iconElemId) {
      case "email":
        iconElem = <IoMail className="largest" />;
        break;
      case "facebook":
        iconElem = <IoLogoFacebook className="largest" />;
        break;
      case "one-time":
        iconElem = <IoReader className="largest" />;
        break;
    }
    return (
      <div className="vertical-middle blue-inner-box tight">
        <div className="user-edit-login-credential-icon hide-xs">
          {iconElem}
        </div>
        <div>{statusElem}</div>
      </div>
    );
  };

  const renderAddEmailModal = () => {
    return (
      <Modal show={showAddEmail} onHide={() => setShowAddEmail(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Translated translationKey={"connect_email_credentials"} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UserAddEmail
            userId={profile.userData.id}
            userName={profile.userData.name}
          />
        </Modal.Body>
      </Modal>
    );
  };

  let userData = profile.userData;

  const oneTimeKeys = profile.oneTimeKeys ? profile.oneTimeKeys : [];
  const oneTimeKeyCount = oneTimeKeys.length;
  const usedOneTimeKeyCount = oneTimeKeys.filter((key) => !key.isOpen).length;
  const activationDate = userData.activationDate;

  const hasEmail = !!profile.userData.email;
  const isUnActivatedOneTimeKeyAccount = !activationDate && !hasEmail;
  const isTeacher = fillsRole(profile.userData.role, Roles.TEACHER);

  let credentialsElem;

  if (isTeacher) {
    credentialsElem = (
      <div>
        {hasEmail ? renderEmailCredentials() : renderNoEmailCredentials()}
      </div>
    );
  } else if (isUnActivatedOneTimeKeyAccount) {
    // Not yet taken into use and user account is created through one-time-key process.
    // Show note about this account. Should be given to a student only on paper.
    credentialsElem = renderUnactivatedOneTimeKeyCredentials();
  } else {
    // Render all credential information.
    credentialsElem = (
      <div>
        {hasEmail ? renderEmailCredentials() : renderNoEmailCredentials()}
        {oneTimeKeyCount > 0
          ? renderOneTimeKeys(usedOneTimeKeyCount, oneTimeKeyCount)
          : renderNoOneTimeKeyCredentials()}
      </div>
    );
  }

  return (
    <div id="edit-user-container" className="edit-user-profile-wrapper">
      {renderImage(profile.facebookUser)}
      <div className="edit-user-profile-data">
        {renderNameElem()}
        {renderActivationDateElem(activationDate)}
        {renderSchoolInfo()}
        {renderLockElement()}
        {renderClosingElement(
          userData.isClosed,
          userData.isClosedByDate,
          userData.closingDate
        )}
        {credentialsElem}
      </div>
      {renderPrintableCodes()}
      {renderResetPasswordModal()}
      {renderNamingModal()}
    </div>
  );
};

export default EditUser;
