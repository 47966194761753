import { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import FormGroup from "react-bootstrap/FormGroup";
import FormControl from "react-bootstrap/FormControl";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";
import { IoAlertOutline } from "react-icons/io5";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import Translated from "../feature/Translated";
import Spinner from "./../feature/Spinner";
import { useUserEmailRequestSetPassword } from "../../api-new";
import { useTranslation } from "./TranslationProvider";

const EmailSetPassword = (props) => {
  const { lang, translate } = useTranslation();
  const navigate = useNavigate();
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [error, setError] = useState(null);
  const passwordMutation = useUserEmailRequestSetPassword();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (passwordMutation.isPending) {
      return;
    }

    if (password1 !== password2) {
      setError(translate("password_mismatch"));
      return;
    }

    setError(null);
    passwordMutation.mutate({
      dataToken: props.requestToken,
      newPassword: password1,
      locale: lang,
    });
  };

  const renderError = () => {
    if (!error && !passwordMutation.isError) {
      return null;
    }

    const errorMsg = error ? (
      <span>{error}</span>
    ) : (
      <Translated
        translationKey={passwordMutation.error.message}
        values={passwordMutation.error?.data || {}}
      />
    );

    return (
      <Alert>
        <IoAlertOutline /> {errorMsg}
      </Alert>
    );
  };

  const renderDone = () => {
    return (
      <div>
        <Alert>
          <Translated translationKey="password_is_set" />{" "}
          <Translated translationKey="login_with_new_password" />
        </Alert>
        <Button onClick={() => navigate("/")}>
          <Translated translationKey="ok" />
        </Button>
      </div>
    );
  };

  if (passwordMutation.isSuccess) {
    return renderDone();
  }

  const passwordLabel1 = <Translated translationKey="new_password" />;
  const passwordLabel2 = <Translated translationKey="password_again" />;
  const inputEmpty = !password1 || !password2;

  return (
    <div
      className={classNames("centered", "large-top-margin", props.className)}
    >
      <Card
        id="create-email-password-panel"
        className="login-form-well"
        header={props.header}
      >
        <Card.Body>
          <h3>
            <Translated
              translationKey="create_email_password_info"
              values={{ email: props.email }}
            />
          </h3>
          <form id="email-login-form" onSubmit={handleSubmit} method="post">
            <FormGroup onSubmit={handleSubmit}>
              {passwordLabel1}
              <FormControl
                id="create-password-1"
                disabled={passwordMutation.isPending}
                type="password"
                name="password1"
                placeholder={translate("new_password")}
                value={password1}
                autoComplete="new-password"
                onChange={(e) => setPassword1(e.target.value)}
              />
              <div className="field-spacer" />
              {passwordLabel2}
              <FormControl
                id="create-password-2"
                disabled={passwordMutation.isPending}
                type="password"
                name="password2"
                placeholder={translate("password_again")}
                value={password2}
                autoComplete="new-password"
                onChange={(e) => setPassword2(e.target.value)}
              />

              <div className="field-spacer" />
              {renderError()}
            </FormGroup>
            <Button
              id="email-request-create-password-submit"
              type="submit"
              disabled={passwordMutation.isPending || inputEmpty}
              className="submit-login-button strong-text"
              onClick={handleSubmit}
            >
              {passwordMutation.isPending ? <Spinner /> : translate("save")}
            </Button>
          </form>
        </Card.Body>
      </Card>
    </div>
  );
};

EmailSetPassword.propTypes = {
  email: PropTypes.string.isRequired,
  requestToken: PropTypes.string.isRequired,
};

export default EmailSetPassword;
