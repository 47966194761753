import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Button from "react-bootstrap/Button";
import FormGroup from "react-bootstrap/FormGroup";
import FormControl from "react-bootstrap/FormControl";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";
import Translated from "../feature/Translated";
import Spinner from "./../feature/Spinner";
import classNames from "classnames";
import { useEmailLogin } from "../../api-new";
import { useUserData } from "../../context/UserDataContext";
import { useTranslation } from "./TranslationProvider";

const LoginFormEmail = (props) => {
  const { login } = useUserData();
  const { translate } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [profileSchoolId, setProfileSchoolId] = useState(null);
  const loginMutation = useEmailLogin();
  const navigate = useNavigate();

  useEffect(() => {
    if (profileSchoolId) {
      handleLoginTap();
    }
  }, [profileSchoolId]);

  const handleLoginTap = (e) => {
    e ? e.preventDefault() : null;
    if (!email || !password) {
      return;
    }
    // TODO Validate input values
    loginMutation.mutate(
      { email, password, profileSchoolId },
      {
        onSuccess: (userData) => {
          if (!userData.profiles) {
            login(userData);
          }
        },
      }
    );
  };

  const renderProfileSelection = (profiles) => {
    const shake = loginMutation.isError && !loginMutation.isPending;
    const formClasses = classNames(
      "login-form-well",
      { shake },
      props.className
    );

    let errorMessage;
    if (loginMutation.isError) {
      errorMessage = (
        <Alert className={loginMutation.isPending ? "transparent" : null}>
          <Translated className="strong-text" translationKey="error-login" />
          <br />
          <Translated translationKey={loginMutation.error.message} />
        </Alert>
      );
    }

    const profileElems = profiles.map((p) => {
      const id = p.schoolId;
      const name = p.schoolName;
      return (
        <div key={id} className="centered">
          <Button
            onClick={() => {
              setProfileSchoolId(id);
            }}
          >
            {name}
          </Button>
          <br />
        </div>
      );
    });

    return (
      <Card id="login-form-email" className={formClasses}>
        <Card.Header>{props.header}</Card.Header>
        <Card.Body>
          <div className="centered">
            <div className="centered medium-bottom-margin">
              <Translated translationKey="select_profile_school" />
            </div>
            {profileElems}
            {errorMessage}
          </div>
        </Card.Body>
      </Card>
    );
  };

  const renderRecoveryLink = () => {
    if (props.disableRecovery) {
      return null;
    }
    return (
      <div className="top-margin-small">
        <a onClick={() => navigate("/email-recovery")} className="touchable">
          <Translated translationKey="did_forget_password" />
        </a>
        <div className="field-spacer" />
      </div>
    );
  };

  const shake = loginMutation.isError && !loginMutation.isPending;
  const formClasses = classNames("login-form-well", { shake }, props.className);

  let emailLabel;
  let passwordLabel;
  if (loginMutation.isError) {
    emailLabel = <Translated className="input-label" translationKey="email" />;
    passwordLabel = (
      <Translated className="input-label" translationKey="password" />
    );
  }
  const inputEmpty = !email || !password;

  let errorMessage;

  if (loginMutation.isError) {
    if (loginMutation.isPending) {
      errorMessage = null;
    } else {
      errorMessage = (
        <Alert className="top-margin-small">
          <Translated className="strong-text" translationKey="error-login" />
          <br />
          <Translated translationKey={loginMutation.error.message} />
          <br />
          {loginMutation.error.message === "user-account-expired-tp" && (
            <Link to="/kauppa">
              <Button>
                <Translated translationKey="menu_shop" />
              </Button>
            </Link>
          )}
        </Alert>
      );
    }
  }

  if (loginMutation.data?.profiles) {
    return renderProfileSelection(loginMutation.data.profiles);
  }

  return (
    <Card id="login-form-email" className={formClasses}>
      <Card.Header>{props.header}</Card.Header>
      <Card.Body>
        <form id="email-login-form" onSubmit={handleLoginTap} method="post">
          <FormGroup onSubmit={handleLoginTap}>
            {emailLabel}
            <FormControl
              id="email-login-form-email"
              disabled={loginMutation.isPending}
              type="email"
              name="email"
              autoComplete="username"
              placeholder={translate("email")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="field-spacer" />
            {passwordLabel}
            <FormControl
              id="email-login-form-password"
              disabled={loginMutation.isPending}
              type="password"
              name="password"
              autoComplete="current-password"
              placeholder={translate("password")}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            {errorMessage}
            {renderRecoveryLink()}
          </FormGroup>
          <Button
            id="email-login-form-submit"
            type="submit"
            disabled={loginMutation.isPending || inputEmpty}
            className="submit-login-button strong-text"
            onClick={handleLoginTap}
          >
            {loginMutation.isPending ? <Spinner /> : translate("login")}
          </Button>
        </form>
      </Card.Body>
    </Card>
  );
};

export default LoginFormEmail;
