import Translated from "../feature/Translated";
import Help from "../feature/Help";
import UserCreatesPending from "../feature/UserCreatesPending";
import { Roles } from "../../data/roles";
import UserCreate from "../feature/UserCreate";
import { useUserData } from "../../context/UserDataContext";

const UserAddPage = () => {
  const { schoolId } = useUserData();

  return (
    <div>
      <h3>
        <Translated translationKey={"add_student"} />
        <Help content="help_add_student" placement="bottom" />
      </h3>
      <UserCreate role={Roles.STUDENT} schoolId={schoolId} />
      <UserCreatesPending schoolId={schoolId} className="large-top-margin" />
    </div>
  );
};

export default UserAddPage;
