const PreloadPage = () => {
  return (
    <div className="full-view-height-wrapper page-container preload-wrapper">
      <div className="preload-spinner">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
    </div>
  );
};

export default PreloadPage;
