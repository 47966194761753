import { useEffect, useState } from "react";
import { IoCheckmarkSharp } from "react-icons/io5";
import Translated from "./Translated";
import Circle from "./ProgressBarCircle";
import { useSendBookContentStatus } from "../../api-new";

const MARK_AS_READ_DELAY_S = 30;

const BookReadStatusMarker = (props) => {
  const [secondsPassed, setSecondsPassed] = useState(0);
  const updateBookStatusMutation = useSendBookContentStatus();

  useEffect(() => {
    let interval = null;
    if (!props.isRead) {
      interval = setInterval(
        () =>
          setSecondsPassed((prev) => {
            const newSeconds = prev + 1;
            if (newSeconds >= MARK_AS_READ_DELAY_S) {
              if (interval) {
                clearInterval(interval);
              }

              if (props.sendStatus) {
                const chapter = props.chapter;

                updateBookStatusMutation.mutate({
                  materialContentId: chapter.id,
                  materialId: chapter.materialId,
                });
              }
            }

            return newSeconds;
          }),
        1000
      );
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [props.isRead, props.sendStatus, props.chapter]);

  let content;
  let key;
  if (props.isRead) {
    key = "book_chapter_marked_read";
    content = <IoCheckmarkSharp className="glyph fade-in delay" />;
  } else {
    if (!props.showProgress) {
      return null;
    }
    key = "book_chapter_marked_read_in";
    // +1 so that the animation reaches the correct value just as the second finishes.
    const percentage = (secondsPassed + 1) / MARK_AS_READ_DELAY_S;
    content = (
      <Circle
        percentage={percentage * 100}
        initialAnimate={false}
        transitionDuration={1}
        className="progressbar-small progressbar-yellow progressbar-light-bg progressbar-no-text"
      />
    );
  }

  return (
    <div className="book-status-marker">
      <Translated className="status-label" translationKey={key} />
      {content}
    </div>
  );
};

export default BookReadStatusMarker;
