import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import FormLabel from "react-bootstrap/FormLabel";

import { useTranslation } from "./TranslationProvider";

/**
 * Simple Text input that emits onChange to prop.listener.
 */
const TextInput = (props) => {
  const { translate } = useTranslation();

  const onChange = (e) => {
    const input = e.target.value;
    props.listener(input);
  };

  const label = props.label && <FormLabel>{props.label}</FormLabel>;

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
      }}
      className={props.className}
    >
      <Form.Group>
        {label}
        <InputGroup className={"mb-3 " + props.className}>
          <Form.Control
            type={props.type ? props.type : "text"}
            name="input"
            disabled={props.disabled}
            className={props.className}
            placeholder={translate(props.placeholder)}
            value={props.value}
            bssize={props.size}
            autoComplete={props.autoComplete}
            onChange={onChange}
          />
        </InputGroup>
      </Form.Group>
    </Form>
  );
};

TextInput.propTypes = {
  listener: PropTypes.func,
};

export default TextInput;
