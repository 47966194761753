import { useState } from "react";
import Button from "react-bootstrap/Button";
import { IoChatbubblesSharp } from "react-icons/io5";

import PreloadMiddlePage from "./../pages/PreloadMiddlePage";
import Line from "../feature/ProgressBarLine";
import Translated from "../feature/Translated";
import LiteralsStart from "../feature/practise/literals/LiteralsStart";
import LiteralsQuestion from "../feature/practise/literals/LiteralsQuestion";
import PractiseResults from "../feature/practise/PractiseResults";
import ResultFeedbackForm from "../feature/results/ResultFeedbackForm";
import MapUtil from "../../utils/map";
import BookModalLink from "../feature/BookModalLink";
import { useUserData } from "../../context/UserDataContext";
import { usePracticeLiterals, usePracticeLog } from "../../api-new";
import { useTranslation } from "../feature/TranslationProvider";

const PracticeLiteralsPage = () => {
  const { userSettings } = useUserData();
  const { lang } = useTranslation();
  const [started, setStarted] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [faults, setFaults] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [seenIds, setSeenIds] = useState([]);
  const [showFeedback, setShowFeedback] = useState(false);
  const literalsMutation = usePracticeLiterals();
  const practiceLogMutation = usePracticeLog();

  const setAnswer = (optionId) => {
    const newSeenIds = seenIds.slice();
    const question = literalsMutation.data.questions[questionIndex];
    // Mark as seen if answered correctly, otherwise it may be asked even on the very next round again.
    if (question.correctAnswer === optionId) {
      if (newSeenIds.indexOf(question.id) === -1) {
        newSeenIds.push(question.id);
        if (newSeenIds.length > 30) {
          newSeenIds.shift();
        }
      }
    }

    setAnswers((prev) => [...prev, optionId]);
    setSeenIds(newSeenIds);
  };

  const nextQuestion = () => {
    const nextIndex = questionIndex + 1;
    setQuestionIndex(nextIndex);
    setShowFeedback(false);

    if (nextIndex === literalsMutation.data.questions.length) {
      let faults = 0;
      literalsMutation.data.questions.forEach((question, index) => {
        let answer = answers[index];
        if (answer !== question.correctAnswer) {
          faults++;
        }
      });

      setFaults(faults);
      practiceLogMutation.mutate({
        category: userSettings.vehicleCategory,
        practiceType: "literals",
        numQuestions: literalsMutation.data.questions.length,
        numFaults: faults,
      });
    }
  };

  const start = () => {
    // BookModalLink.setAllowPopover(true);
    literalsMutation.mutate(
      { category: userSettings.vehicleCategory, lang, seenIds },
      {
        onSuccess: () => {
          setStarted(true);
          setQuestionIndex(0);
          setAnswers([]);
        },
      }
    );
  };

  const getBookReferencesMap = () => {
    const bookReferences = literalsMutation.data.bookReferences;
    return MapUtil.mapFromObject(bookReferences);
  };

  const renderBookContent = (question, answer) => {
    const bookReferencesMap = getBookReferencesMap();

    // Do not add book reference link if there is none, or user does not have them linked (no rights)
    if (
      question.bookReferenceIds.length === 0 ||
      !bookReferencesMap ||
      bookReferencesMap.size === 0
    ) {
      return null;
    }
    const animate = question.correctAnswer !== answer;
    const bookLinks = question.bookReferenceIds.map((refId) => {
      const content = bookReferencesMap.get(refId);
      return <BookModalLink content={content} animate={animate} key={refId} />;
    });

    return <div className="book-links fade-in">{bookLinks}</div>;
  };

  const renderAnswerContent = (question, answer) => {
    let feedbackForm;
    if (showFeedback) {
      feedbackForm = (
        <ResultFeedbackForm
          keepOpen
          questionType="literal"
          questionId={question.id}
          className="fade-in"
        />
      );
    }

    return (
      <div>
        {renderBookContent(question, answer)}
        <div className="min-button-space literals-buttons-container">
          <Button
            bsstyle="link"
            className="fade-in long-delay"
            onClick={() => setShowFeedback((prev) => !prev)}
          >
            <IoChatbubblesSharp className="larger text-bottom" />
            <Translated translationKey="question_report" />
          </Button>
          <Button
            id="practise-literals-next-button"
            onClick={nextQuestion}
            className="fade-in delay"
          >
            <Translated translationKey="next" />
          </Button>
        </div>
        {feedbackForm}
      </div>
    );
  };

  const renderQuestion = () => {
    const i = questionIndex;
    const questions = literalsMutation.data.questions;
    const question = questions[i];
    const answer = i < answers.length ? answers[i] : null;
    const current = i + 1;
    const total = questions.length;
    const progressRatio = current / total;

    return (
      <div
        id="practise-literals-question-container"
        className="practise-wrapper"
      >
        <div className="practise-progress-wrapper fade-in long-delay">
          <Line className="yellow" percentage={progressRatio * 100} />
        </div>
        <LiteralsQuestion
          question={question}
          index={questionIndex}
          setAnswer={setAnswer}
          answer={answer}
          key={question.id}
        />

        {answer ? (
          renderAnswerContent(question, answer)
        ) : (
          <div className="min-button-space"></div>
        )}
      </div>
    );
  };

  const renderResults = () => {
    const total = literalsMutation.data.questions.length;
    const correctCount = total - faults;
    return (
      <PractiseResults
        correct={correctCount}
        total={total}
        restart={start}
        buttonId="literals-results-restart"
      />
    );
  };

  let content;

  if (!userSettings.data) {
    content = <PreloadMiddlePage />;
  } else if (!started) {
    content = <LiteralsStart start={start} locale={lang} />;
  } else if (literalsMutation.isError) {
    content = <PreloadMiddlePage error={literalsMutation.error.message} />;
  } else if (literalsMutation.isPending) {
    content = <PreloadMiddlePage />;
  } else if (questionIndex < literalsMutation.data.questions.length) {
    content = renderQuestion();
  } else {
    content = renderResults();
  }

  return (
    <div
      id="practise-literals-page"
      className="page-container page-gutter max-width-container"
    >
      {content}
    </div>
  );
};

export default PracticeLiteralsPage;
