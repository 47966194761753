import classNames from "classnames";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import { useTranslation } from "./TranslationProvider";
import { availableLangsData } from "../../data/availableLangsData";

const LanguageSelector = (props) => {
  const { lang, switchLanguage } = useTranslation();

  let currentLangLocalization = null;
  const langCodes = Object.keys(availableLangsData);
  if (langCodes.length <= 1) {
    return null;
  }
  const langItems = [];
  langCodes.forEach((langCode) => {
    const langLocalization = availableLangsData[langCode];
    if (langCode === lang) {
      currentLangLocalization = langLocalization;
    }
    langItems.push(
      <Dropdown.Item
        key={langCode}
        onClick={() => {
          switchLanguage(langCode);
        }}
      >
        <span
          className={classNames("language", {
            selected: langCode === lang,
          })}
        >
          {langLocalization}
        </span>
      </Dropdown.Item>
    );
  });

  return (
    <DropdownButton
      title={currentLangLocalization}
      id="languageSelectorButton"
      drop={props.drop}
    >
      {langItems}
    </DropdownButton>
  );
};

export default LanguageSelector;
