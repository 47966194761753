import { useNavigate } from "react-router-dom";
import PreloadMiddlePage from "./../pages/PreloadMiddlePage";
import BookStatus from "./BookStatus";
import Translated from "./Translated";
import Button from "react-bootstrap/Button";
import { useUserData } from "../../context/UserDataContext";
import { useBookStatuses } from "../../api-new";
import { useTranslation } from "./TranslationProvider";

const ActiveBookStatus = (props) => {
  const { lang } = useTranslation();
  const { userId, userSettings } = useUserData();
  const navigate = useNavigate();
  const booksStatusQuery = useBookStatuses(userId, lang);

  const renderReadButton = () => {
    if (props.hideButton) {
      return null;
    }
    return (
      <Button onClick={() => navigate("/book")}>
        <Translated translationKey="read_book" />
      </Button>
    );
  };

  if (!userSettings.data || !booksStatusQuery.data) {
    return <PreloadMiddlePage />;
  }

  const selectedCategory = userSettings.vehicleCategory;
  if (selectedCategory === null) {
    return null;
  }

  let bookStatus = booksStatusQuery.data.find(
    (status) => status.category === selectedCategory
  );
  if (!bookStatus) {
    return null;
  }

  return (
    <BookStatus bookStatus={bookStatus} className={props.className}>
      {renderReadButton()}
    </BookStatus>
  );
};

export default ActiveBookStatus;
