const ShopDeliveryTermsPage = () => {
  return (
    <div className="page-container max-width-container">
      <h3>Yhteystiedot</h3>
      <p>
        Ajokaista Oy
        <br />
        Katajapolku 14
        <br />
        00780 Helsinki
        <br />
        Y-tunnus: 2924778-7
        <br />
        info@ajokaista.com
        <br />
        040 722 3833
        <br />
      </p>

      <h3>Toimitusehdot</h3>
      <h4>Tuotteet</h4>
      <p>
        Ajokaistan myymät tuotteet ovat verkkopalveluita ja niitä voi käyttää
        kaikilla yleisimmillä uudehkoilla selaimilla. Palvelut ovat testattu
        toimiviksi uudehkoilla yleisillä käyttöjärjestelmillä (Windows, macOS,
        Android, iOS), sekä niissä toimivilla yleisimmillä selaimilla.
        <br />
        Ajokortin teoriaharjoittelut vastaavat rakenteeltaan Trafin virallista
        oikeaa koetta. Oikean kokeen materiaalit ovat salaisia ja harjoittelun
        materiaali ei siksi ole täysin oikean kokeen kanssa yhteneväinen.
        <br />
        Oppikirjat ovat tarkoitettu selaimella käytettäväksi, eikä niitä ole
        sallittua tulostaa.
        <br />
        Palveluun saatava käyttöoikeus on henkilökohtainen, eikä tunnuksia ole
        luvallista jakaa kolmannelle osapuolelle.
        <br />
        Käyttöaika palveluihin on mainittu tuotteiden ostosivuilla.
      </p>
      <p>
        Verkkokaupasta ostetut tuotteet toimitetaan välittömästi ostoksen
        jälkeen. Sähköpostitse toimitettavissa käyttäjätunnuksissa saattaa olla
        normaalia sähköpostin viivettä, esimerkiksi roskapostisuodattimien
        johdosta.
      </p>
      <p>
        Kuluttajalla ei ole kaupan peruuttamisoikeutta, jos digitaalisen
        sisällön toimittaminen on sähköisesti aloitettu kuluttajan
        suostumuksella ennen peruuttamisajan päättymistä ja kuluttajalle on
        ilmoitettu peruttamisoikeuden puuttumisesta. Ajokaista antaa kuitenkin
        useimmille tuotteille erityisen tyytyväisyystakuun, joka mainitaan
        ostettavan tuotteen yhteydessä.
      </p>
      <p>
        Asiakaspalvelu on pääasiassa suomeksi ja tavoitettavissa
        yhteydenottolomakkeella.
      </p>
      <p>
        Asiakkaan on pikimmiten reklamoitava mahdolliset havaitsemansa virheet
        palvelussa asiakaspalveluun. Ajokaista ei vastaa mahdollisen
        ylivoimaisen esteen (Force Majeure) aiheuttamasta haitasta palvelussa
        tai Ajokaistasta riippumattomasta syystä aiheutuvasta lyhytkestoisesta
        haistasta (esimerkiksi palvelinsalin palveluntarjoajan lyhyt katkos
        palvelimella).
      </p>
      <p>
        Käytettävät maksuvälineet ovat listattu verkkokaupassa ostosten
        yhteydessä.
      </p>
      <p>
        Ostoksen perumisesta ei veloiteta asiakkaalta ylimääräisiä kuluja.
        Peruminen tapahtuu ottamalla yhteyttä yhteydenottolomakkeen kautta ja
        ilmoittamalla ostoksen tiedot sekä peruutuksen syy.
      </p>
    </div>
  );
};

export default ShopDeliveryTermsPage;
