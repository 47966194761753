import { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import { IoAlertOutline } from "react-icons/io5";

import BookBrowserSimple from "../feature/BookBrowserSimple";
import Translated from "../feature/Translated";
import PreloadMiddlePage from "./PreloadMiddlePage";
import { useUserData } from "../../context/UserDataContext";
import { useBookIndex } from "../../api-new";
import { useParams } from "react-router-dom";

/**
 * Book page that takes book id and language as props.
 * Uses browser that does not show read status.
 * Used for teacher.
 */
const BookSimplePage = () => {
  const { userSettings } = useUserData();
  const params = useParams();
  const [initialized, setInitialized] = useState(false);
  const [error, setError] = useState(null);
  const [apiBookSettings, setApiBookSettings] = useState({
    id: null,
    locale: null,
  });
  const bookIndexQuery = useBookIndex(
    apiBookSettings.id,
    apiBookSettings.locale
  );

  useEffect(() => {
    const tryInit = () => {
      if (!userSettings.data) {
        return;
      }
      setInitialized(true);
      const bookId = params.bookId;
      const locale = params.language;

      const books = userSettings.data.availableBooks;
      const booksForId = books.filter((book) => book.id === bookId);
      if (booksForId.size === 0) {
        return setError("book-not-found");
      }
      const book = booksForId.find((book) => book.lang === locale);
      if (!book) {
        return setError("no-book-for-language");
      }
      if (!book.hasLicense) {
        return setError("no-license-for-book");
      }

      setApiBookSettings({ id: bookId, locale });
    };

    if (!initialized) {
      tryInit();
    }
  });

  let content;
  if (error || bookIndexQuery.isError) {
    const errorMessage = error || bookIndexQuery.error.message;
    content = (
      <Alert>
        <IoAlertOutline /> <Translated translationKey={errorMessage} />
      </Alert>
    );
  } else if (!initialized || !bookIndexQuery.isSuccess) {
    content = <PreloadMiddlePage />;
  } else {
    const bookData = [...bookIndexQuery.data.content];
    bookData.sort((a, b) => a.positionIndex - b.positionIndex);

    content = (
      <BookBrowserSimple
        bookId={params.bookId}
        language={params.language}
        chapters={bookData}
        chapterId={params.chapterId}
      />
    );
  }
  return (
    <div id="book-page" className="page-container">
      <div className="max-width-container centered">{content}</div>
    </div>
  );
};

export default BookSimplePage;
