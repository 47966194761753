import { useState } from "react";
import Button from "react-bootstrap/Button";
import { BsFillTelephoneFill } from "react-icons/bs";
import { IoPerson } from "react-icons/io5";
import { IoMail } from "react-icons/io5";
import Alert from "react-bootstrap/Alert";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";

import Translated from "../feature/Translated";
import Spinner from "./../feature/Spinner";
import { useFeedback } from "../../api-new";
import { useTranslation } from "./TranslationProvider";
import LocalStorageUtil from "../../utils/localStorageUtil";

const ContactForm = (props) => {
  const { translate } = useTranslation();
  const [form, setForm] = useState({
    contactPerson: "",
    phoneNumber: "",
    email: "",
    message: "",
  });
  const feedbackMutation = useFeedback();

  const submitContact = () => {
    if (isFormValid()) {
      // Include local settings in feedback. This helps to identify the users in case he is not logged in.
      const localSettings = LocalStorageUtil.getAllLocalSettingsObj();

      feedbackMutation.mutate(
        {
          userName: form.contactPerson,
          phone: form.phoneNumber,
          email: form.email,
          feedback: form.message,
          extra: { settings: localSettings },
        },
        {
          onSuccess: () => {
            if (props.onSubmitted) {
              props.onSubmitted();
            }
          },
        }
      );
    }
  };

  const isFormValid = () => {
    const requiredFields = ["message"];
    let isValid = true;
    requiredFields.forEach((key) => {
      const value = form[key];
      if (value === "") {
        isValid = false;
      }
    });
    return isValid;
  };

  const handleInputChange = (e) => {
    const newForm = { ...form, [e.target.name]: e.target.value };
    setForm(newForm);
  };

  const renderForm = () => {
    const errorMessage = feedbackMutation.isError ? (
      <Alert bsstyle="warning">
        <Translated translationKey={feedbackMutation.error.message} />
      </Alert>
    ) : null;

    return (
      <Form>
        <fieldset disabled={feedbackMutation.isPending}>
          {props.name && (
            <Form.Group id="person" className="mb-3">
              <InputGroup>
                <InputGroup.Text>
                  <IoPerson className="white" />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="contactPerson"
                  placeholder={translate("name")}
                  value={form.contactPerson}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </Form.Group>
          )}

          {props.phone && (
            <Form.Group id="phone" className="mb-3">
              <InputGroup>
                <InputGroup.Text>
                  <BsFillTelephoneFill className="white" />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="phoneNumber"
                  placeholder={translate("phone_number")}
                  value={form.phoneNumber}
                  onChange={(e) => handleInputChange(e)}
                />
              </InputGroup>
            </Form.Group>
          )}

          {props.email && (
            <Form.Group id="email" controlId="formBasicEmail" className="mb-3">
              <InputGroup>
                <InputGroup.Text>
                  <IoMail className="white" />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="email"
                  placeholder={translate("email")}
                  value={form.email}
                  onChange={(e) => handleInputChange(e)}
                />
              </InputGroup>
            </Form.Group>
          )}

          <Form.Group controlId="message" className="mb-3">
            <Form.Control
              name="message"
              as="textarea"
              rows={5}
              value={form.message}
              placeholder={translate("message")}
              onChange={(e) => handleInputChange(e)}
              className="contact-message-text-area"
            />
          </Form.Group>

          <div className="centered">
            {errorMessage}
            <Button
              aria-label="Submit"
              role="presentation"
              id="submit-contact"
              disabled={!isFormValid() || feedbackMutation.isPending}
              onClick={() => submitContact()}
            >
              <Translated translationKey="send" />
            </Button>
            {feedbackMutation.isPending ? (
              <Spinner className="primary" />
            ) : null}
          </div>
        </fieldset>
      </Form>
    );
  };

  return <div>{renderForm()}</div>;
};

export default ContactForm;
