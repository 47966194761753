import { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";

import Translated from "./Translated";
import Spinner from "./Spinner";
import { useSchoolData, useUpdateSchoolData } from "../../api-new";
import { useUserData } from "../../context/UserDataContext";
import { useTranslation } from "./TranslationProvider";

const AutoCloseSettings = (props) => {
  const { schoolId } = useUserData();
  const { translate } = useTranslation();
  const [school, setSchool] = useState(null);
  const schoolDataQuery = useSchoolData(schoolId);
  const schoolDataMutation = useUpdateSchoolData();

  useEffect(() => {
    if (schoolDataQuery.isSuccess) {
      setSchool(schoolDataQuery.data.school);
    }
  }, [schoolDataQuery.data]);

  const updateSchoolState = (field, value) => {
    const newSchoolObj = { ...school, [field]: value };
    schoolDataMutation.mutate({ schoolId, schoolData: newSchoolObj });
  };

  const buildAmortizationSelection = () => {
    const daysLabel = " " + translate("days");
    const selections = [2, 7, 15, 30, 45];
    const currentValue = school.userAmortizationPeriodDays;

    return (
      <ButtonToolbar className="settings-category-limit">
        <DropdownButton
          id="amortizationSelection"
          bsstyle="warning"
          title={currentValue + daysLabel}
        >
          {selections.map((selectableValue) => {
            return (
              <Dropdown.Item
                id={selectableValue}
                key={selectableValue}
                onClick={() => {
                  updateSchoolState(
                    "userAmortizationPeriodDays",
                    selectableValue
                  );
                }}
              >
                <span>
                  {selectableValue === currentValue ? (
                    <span className="settings-selected-dropdown-value ">
                      {selectableValue + daysLabel}
                    </span>
                  ) : (
                    selectableValue + daysLabel
                  )}
                </span>
              </Dropdown.Item>
            );
          })}
        </DropdownButton>
      </ButtonToolbar>
    );
  };

  const renderForm = () => {
    return (
      <div className="white-box">
        <form>
          <fieldset disabled={schoolDataMutation.isPending}>
            <p>
              <Translated translationKey="user_amortization_info" />
            </p>
            {buildAmortizationSelection()}
          </fieldset>
          {schoolDataMutation.isPending && <Spinner />}
          {schoolDataMutation.isError && (
            <Alert>
              <Translated translationKey={schoolDataMutation.error.message} />
            </Alert>
          )}
        </form>
      </div>
    );
  };

  return (
    <div id="auto-close-settings" className={props.className}>
      <h3>
        <Translated translationKey="user_amortization_title" />
      </h3>
      {schoolDataQuery.isError && (
        <Alert>
          <Translated translationKey={schoolDataQuery.error.message} />
        </Alert>
      )}
      {schoolDataQuery.isPending && <Spinner />}
      {school && renderForm()}
    </div>
  );
};

export default AutoCloseSettings;
