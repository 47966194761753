import { useEffect, useRef } from "react";
import Spinner from "./Spinner";
import Translated from "./Translated";
import AnimatedNumber from "animated-number-react";
import { useActivityCount } from "../../api-new";

const ActivityCount = (props) => {
  const activityCountQuery = useActivityCount();
  const timeoutSecsRef = useRef(10);
  const timeoutRef = useRef(null);

  useEffect(() => {
    const loadFreshData = () => {
      clearInterval(timeoutRef.current);
      activityCountQuery.refetch();
      timeoutSecsRef.current *= 1.5;
      if (timeoutSecsRef.current > 100000) {
        timeoutSecsRef.current = 100000;
      }

      timeoutRef.current = setTimeout(
        loadFreshData,
        timeoutSecsRef.current * 1000
      );
    };

    timeoutRef.current = setTimeout(
      loadFreshData,
      timeoutSecsRef.current * 1000
    );

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const renderLoader = () => {
    return (
      <div>
        <Spinner className="primary semi-transparent" />
      </div>
    );
  };

  if (activityCountQuery.isPending) {
    // Render loader only on first time, no on updates
    return renderLoader();
  }

  if (activityCountQuery.isError) {
    return null;
  }

  const examsToday = activityCountQuery.data.exams.today;
  const wrapped = (
    <AnimatedNumber
      value={examsToday}
      duration={2000}
      formatValue={Math.floor}
    />
  );

  if (props.type === "large-number") {
    return (
      <div className={props.className}>
        <Translated
          translationKey="exams_today"
          className="large semi-transparent"
        />
        <div className="largest strong">{wrapped}</div>
      </div>
    );
  }

  if (props.type === "large") {
    return (
      <div className={props.className}>
        <Translated translationKey="exams_today" className="large" />
        <div className="largest strong">{wrapped}</div>
      </div>
    );
  }

  return (
    <div className={props.className}>
      <Translated translationKey="exams_today" />
      <div className="larger">{wrapped}</div>
    </div>
  );
};

export default ActivityCount;
