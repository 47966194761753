import { Children, cloneElement } from "react";
import Spinner from "./Spinner";
import { useUserData } from "../../context/UserDataContext";
import { useCategoryStatuses } from "../../api-new";

const UserStatusLoaderWrapper = ({ category, children }) => {
  const { userSettings, userId, role } = useUserData();
  const categoryStatusesQuery = useCategoryStatuses(userId);

  const renderChildrenForCategory = () => {
    const categoryId = category.toLowerCase();
    const data = categoryStatusesQuery.data;
    let status = data.find((status) => status.category === categoryId);
    return Children.map(children, (child) => {
      const props = {
        role,
        userId,
        category: categoryId,
        acceptedAmount: status.acceptedTestAmount,
        rejectedAmount: status.rejectedTestAmount,
        totalAcceptedRequired: status.acceptedTestsRequired,
      };
      return cloneElement(child, props);
    });
  };

  const renderChildrenForTotalResults = () => {
    let acceptedAmount = 0;
    let rejectedAmount = 0;
    const data = categoryStatusesQuery.data;
    data.forEach((row, i) => {
      acceptedAmount += row.acceptedTestAmount;
      rejectedAmount += row.rejectedTestAmount;
    });
    return Children.map(children, (child) => {
      const props = {
        role,
        userId,
        acceptedAmount,
        rejectedAmount,
      };

      return cloneElement(child, props);
    });
  };

  if (!userId) {
    return null;
  }

  if (!userSettings.data || categoryStatusesQuery.isPending) {
    return <Spinner />;
  }

  return (
    <div id="user-status-container">
      {category ? renderChildrenForCategory() : renderChildrenForTotalResults()}
    </div>
  );
};

export default UserStatusLoaderWrapper;
