import { useState } from "react";
import PropTypes from "prop-types";
import Alert from "react-bootstrap/Alert";
import { IoAlertOutline } from "react-icons/io5";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";

import TextInput from "../feature/TextInput";
import Translated from "../feature/Translated";
import dateFormat from "../../utils/date";
import { useUserConnectEmail } from "../../api-new";
import { useTranslation } from "./TranslationProvider";

const UserAddEmail = (props) => {
  const { lang: langContext, translate, availableLangs } = useTranslation();
  const [inputEmail, setInputEmail] = useState("");
  const [lang, setLang] = useState(langContext);
  const [error, setError] = useState(null);
  const emailMutation = useUserConnectEmail();

  const sendRequest = (forceConnect) => {
    if (!inputEmail) {
      setError("invalid_input");
      return;
    }

    setError(null);

    emailMutation.mutate(
      { userId: props.userId, email: inputEmail, lang, forceConnect },
      {
        onError: (error) => {
          setError(error.message);
        },
      }
    );
  };

  const updateEmail = (email) => {
    setInputEmail(email);
    setError(null);
    emailMutation.reset();
  };

  const renderEmailField = () => {
    const emailText = translate("student_email");
    return (
      <TextInput
        listener={(email) => updateEmail(email)}
        placeholder={emailText}
        value={inputEmail}
        type="email"
        className="long-input-field"
      />
    );
  };

  const renderError = () => {
    if (!error) {
      return null;
    }
    let errorElem;
    switch (error) {
      case "email-already-connected":
        const errorData = emailMutation.error;
        errorElem = (
          <span>
            <Translated translationKey={error} />
            <br />
            <Translated translationKey="id" />: {errorData.userId}
            <br />
            <Translated translationKey="name" />: {errorData.userName}
            <br />
            <Translated translationKey="school_name" />: {errorData.schoolName}
            <br />
            <Translated translationKey="creation_date" />:{" "}
            {dateFormat.date(errorData.createdDate)}
            <div className="large-top-margin centered">
              <div className="bottom-margin">
                <strong>
                  <Translated translationKey="email_force_connect_teacher" />
                </strong>
              </div>
              <div className="top-margin">
                <Button bsstyle="primary" onClick={() => this.updateEmail("")}>
                  <Translated translationKey="no" />
                </Button>
                <Button
                  onClick={() => sendRequest(true)}
                  className="left-margin"
                >
                  <Translated translationKey="yes" />
                </Button>
              </div>
            </div>
          </span>
        );
        break;

      default:
        errorElem = <Translated translationKey={error} />;
    }

    return (
      <Alert>
        <IoAlertOutline /> {errorElem}
      </Alert>
    );
  };

  const renderSentRequest = () => {
    return !emailMutation.isSuccess ? null : (
      <Alert bsstyle="success" className="top-margin">
        <Translated
          translationKey="user_email_request_sent"
          values={{ email: emailMutation.data.data.email }}
        />
        <br />
        <Translated
          translationKey="last_usage_date"
          values={{ date: dateFormat.date(emailMutation.data.data.expiresAt) }}
        />
      </Alert>
    );
  };

  const createLanguageItems = () => {
    const langCodes = Object.keys(availableLangs);
    if (langCodes.length <= 1) {
      return null;
    }

    return langCodes.map((langCode) => {
      return (
        <Dropdown.Item
          id={langCode}
          key={langCode}
          onClick={() => {
            setLang(langCode);
          }}
        >
          <span>{availableLangs[langCode]}</span>
        </Dropdown.Item>
      );
    });
  };

  const renderLanguageSelection = () => {
    const langLocale = availableLangs[lang];
    return (
      <ButtonToolbar className="settings-category-limit">
        <DropdownButton
          id="addUserLanguageSelection"
          bsstyle="warning"
          title={langLocale}
        >
          {createLanguageItems()}
        </DropdownButton>
      </ButtonToolbar>
    );
  };

  return (
    <div>
      <div>
        <h4>{props.userName}</h4>
        <p>
          <Translated translationKey="help_connect_email" />
        </p>
        {renderEmailField()}
        {renderLanguageSelection()}
        {renderError()}
        <br />
        <Button
          onClick={() => sendRequest(false)}
          disabled={emailMutation.isPending || emailMutation.isSuccess}
        >
          <Translated translationKey="send" />
        </Button>
      </div>
      <div>{renderSentRequest()}</div>
    </div>
  );
};

UserAddEmail.proptypes = {
  userId: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
};

export default UserAddEmail;
