import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import FormGroup from "react-bootstrap/FormGroup";
import FormControl from "react-bootstrap/FormControl";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";

import Translated from "../feature/Translated";
import Spinner from "./../feature/Spinner";
import { IoAlertOutline } from "react-icons/io5";
import EmailUtil from "../../utils/EmailUtil";
import { useEmailRecovery } from "../../api-new";
import { useTranslation } from "../feature/TranslationProvider";
import { useNavigate } from "react-router-dom";

const EmailPasswordCreate = (props) => {
  const { lang, translate } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const recoveryMutation = useEmailRecovery();

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null);

    if (!EmailUtil.isEmail(email)) {
      setError("invalid_email_form");
      return;
    }

    recoveryMutation.mutate(
      { email, lang },
      {
        onError: (error) => {
          setError(error.message);
        },
      }
    );
  };

  const renderError = () => {
    return error ? (
      <Alert>
        <IoAlertOutline /> <Translated translationKey={error} />
      </Alert>
    ) : null;
  };

  const renderDone = () => {
    return (
      <div>
        <Alert>
          <Translated
            translationKey="recovery_is_sent"
            values={{ email: recoveryMutation.data.responseEmail }}
          />
        </Alert>
        <Button onClick={() => navigate("/")}>
          <Translated translationKey="ok" />
        </Button>
      </div>
    );
  };

  const inputEmpty = !email;

  let content;

  if (recoveryMutation.isSuccess) {
    content = renderDone();
  } else {
    content = (
      <div className="login-form-container">
        <Card
          id="recover-email-password-panel"
          className="login-form-well"
          header={props.header}
        >
          <Card.Body>
            <h3>
              <Translated
                translationKey="recover_email_password_info"
                values={{ email: props.email }}
              />
            </h3>
            <form id="email-login-form" onSubmit={(e) => handleSubmit(e)}>
              <FormGroup onSubmit={handleSubmit}>
                <FormControl
                  id="email-to-recover"
                  disabled={recoveryMutation.isPending}
                  type="text"
                  name="email"
                  placeholder={translate("email")}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

                <div className="field-spacer" />
                {renderError()}
              </FormGroup>
              <Button
                id="email-request-create-password-submit"
                type="submit"
                disabled={recoveryMutation.isPending || inputEmpty}
                className="submit-login-button strong-text"
                onClick={handleSubmit}
              >
                {recoveryMutation.isPending ? (
                  <Spinner />
                ) : (
                  translate("recover")
                )}
              </Button>
            </form>
          </Card.Body>
        </Card>
      </div>
    );
  }

  return (
    <div
      id="email-recovery-page"
      className="page-container max-width-container fade-in"
    >
      <div className="front-center-wrapper">{content}</div>
    </div>
  );
};

export default EmailPasswordCreate;
