import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";

import Spinner from "../feature/Spinner";
import Translated from "../feature/Translated";
import { useUserData } from "../../context/UserDataContext";
import { useLoginAATeoriaIntegration } from "../../api-new";

const LoginAATeoriaIntegrationPage = () => {
  const { token, login } = useUserData();
  const navigate = useNavigate();
  const location = useLocation();
  const loginMutation = useLoginAATeoriaIntegration();

  useEffect(() => {
    loginMutation.mutate(
      {
        challengedUrl: window.location.href,
      },
      {
        onSuccess: (data) => {
          login({ token: data.token });
        },
      }
    );
  }, []);

  useEffect(() => {
    if (token) {
      navigate("/", {
        state: location.state,
        replace: true,
      });
    }
  }, [token]);

  return (
    <div
      id="login-page"
      className="page-container page-gutter max-width-container"
    >
      <div className="front-center-wrapper">
        <div className="login-form-container fade-in">
          {loginMutation.isPending && <Spinner />}
          {loginMutation.isError && (
            <Alert>
              <Translated translationKey={loginMutation.error.message} />
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginAATeoriaIntegrationPage;
