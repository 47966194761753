import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import queryString from "query-string";

import Spinner from "../feature/Spinner";
import Translated from "../feature/Translated";
import { useUserData } from "../../context/UserDataContext";
import { useLoginAAIntegration } from "../../api-new";

const LoginAAPage = () => {
  const { token, login } = useUserData();
  const navigate = useNavigate();
  const location = useLocation();
  const loginMutation = useLoginAAIntegration();

  useEffect(() => {
    const tryLogin = () => {
      if (!loginMutation.isPending) {
        let params = queryString.parse(location.search);
        let name = decodeURIComponent(params.name);
        let email = decodeURIComponent(params.email);
        let licenseType = decodeURIComponent(params.type); // Legacy, drop when AA site is updated to send only licensesBase64
        let licensesBase64 = params.lic
          ? decodeURIComponent(params.lic)
          : undefined; // Licenses as base64 encoded non-white space JSON {class: lastValidISODate}
        let hash = params.hash;
        loginMutation.mutate(
          {
            name,
            email,
            licenseType,
            licensesBase64,
            hash,
          },
          {
            onSuccess: (data) => {
              login({ token: data.token });
            },
          }
        );
      }
    };

    tryLogin();
  }, []);

  useEffect(() => {
    if (token) {
      navigate("/", { state: location.state, replace: true });
    }
  }, [token]);

  return (
    <div
      id="login-page"
      className="page-container page-gutter max-width-container"
    >
      <div className="front-center-wrapper">
        <div className="login-form-container fade-in">
          {loginMutation.isPending && <Spinner />}
          {loginMutation.isError && (
            <Alert>
              <Translated translationKey={loginMutation.error.message} />
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginAAPage;
