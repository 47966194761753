import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import Spinner from "../feature/Spinner";
import Translated from "../feature/Translated";
import DateFormat from "../../utils/date";
import Tracker from "../../utils/tracker";
import LocalStorageUtil from "../../utils/localStorageUtil";
import TrackerAB from "../../utils/trackerAB";
import { useOrder } from "../../api-new";

const OrderStatusPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const orderQuery = useOrder(params.orderId, params.token);
  const receipt = orderQuery.data;

  useEffect(() => {
    TrackerAB.track("webshop-receipt");
    // Clear the pending order from local storage
    LocalStorageUtil.setForUser("anon", "pending-order", null);
  }, []);

  useEffect(() => {
    if (orderQuery.isSuccess) {
      // No receipt data nor status -> order cancelled before going to bank
      if (!orderQuery.data.data && !orderQuery.data.status) {
        goToShop();
        return;
      }

      if (orderQuery.data.data) {
        const orderId = orderQuery.data.data.orderId;
        const totalPrice = orderQuery.data.data.totalPrice;
        Tracker.logWebshopPurchase(orderId, totalPrice);
      }
    }
  }, [orderQuery.data]);

  const goToShop = () => {
    navigate("/kauppa", { replace: true });
  };

  const renderReceipt = () => {
    const receiptData = receipt.data;
    const actions = receipt.actions;
    return (
      <div>
        <h2>
          <Translated translationKey="purchase_completed_title" />
        </h2>
        {renderActionResults(actions)}
        <Translated translationKey="order_id" />: {receiptData.orderId}
        <br />
        <Translated translationKey="delivery_date" />:{" "}
        {DateFormat.dateTime(receiptData.deliveryDate)}
        {renderProducts(receiptData.products)}
        <div className="top-margin">
          <Translated
            translationKey="order_total_price"
            values={{ totalPrice: receiptData.totalPrice.toFixed(2) }}
          />
          <br />
          <Translated translationKey="payment_method" />:{" "}
          {receiptData.paymentMethod}
        </div>
        <div className="top-margin">
          <Translated
            translationKey="receipt_sent"
            values={{ email: receiptData.customerEmail }}
          />
        </div>
        <Button onClick={goToShop} className="large-top-margin">
          <Translated translationKey="back_to_shop" />
        </Button>
      </div>
    );
  };

  const renderActionResults = (actions) => {
    return actions.map((action, i) => {
      switch (action.type) {
        case "create_email_credentials":
          return (
            <div className="blue-inner-box tight" key={i}>
              <Translated
                translationKey="registration_link_sent"
                values={{ email: action.email }}
                html={true}
              />
            </div>
          );
      }
    });
  };

  const renderProducts = (products) => {
    const productElems = products.map((product, i) => (
      <div className="receipt-product" key={i}>
        {product.title}
        <Translated
          translationKey="order_product_price"
          values={{
            vatPrice: product.vatPrice.toFixed(2),
            vatPercentage: product.vatPercentage,
          }}
        />
      </div>
    ));
    return (
      <div className="top-margin">
        <Translated translationKey="order_products" />
        {productElems}
      </div>
    );
  };

  const renderCancelled = () => {
    return (
      <div>
        <h2>
          <Translated translationKey="purchase_cancelled_title" />
        </h2>
        <div className="blue-inner-box tight">
          <Translated translationKey="purchase_cancelled_info" />
        </div>
        <div>
          <Button onClick={goToShop}>
            <Translated translationKey="back_to_shop" />
          </Button>
        </div>
      </div>
    );
  };

  const renderPending = () => {
    return (
      <div>
        <h2>
          <Translated translationKey="purchase_pending_title" />
        </h2>
        <div className="blue-inner-box tight">
          <Translated translationKey="purchase_pending_info" />
        </div>
        <div>
          <Button onClick={goToShop}>
            <Translated translationKey="back_to_shop" />
          </Button>
        </div>
      </div>
    );
  };

  let stateContent;
  if (orderQuery.isPending) {
    stateContent = <Spinner />;
  } else if (orderQuery.isError) {
    stateContent = (
      <Alert bsstyle="danger">
        <Translated translationKey={orderQuery.error.message} />
      </Alert>
    );
  } else {
    if (receipt.data) {
      stateContent = renderReceipt();
    } else if (receipt.status === "fail") {
      stateContent = renderCancelled();
    } else {
      stateContent = renderPending();
    }
  }
  return (
    <div id="shop-page" className="page-container max-width-container">
      {stateContent}
    </div>
  );
};

export default OrderStatusPage;
