import Translated from "./../Translated";

const ShopPaymentTermsNote = (props) => {
  return (
    <div className={props.className}>
      <a
        href="https://www.paytrail.com/kuluttaja/maksupalveluehdot"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Translated translationKey="payment_terms_label" />
      </a>
    </div>
  );
};

export default ShopPaymentTermsNote;
