import EmailSettings from "../feature/EmailSettings";
import LanguageSelector from "../feature/LanguageSelector";
import Translated from "../feature/Translated";
import UserCategorySetting from "../feature/UserCategorySetting";
import { useUserData } from "../../context/UserDataContext";

const UserSettingsPage = () => {
  const { loginMethod } = useUserData();

  const canAddCredentials = () => {
    if (!loginMethod) {
      return null;
    }
    return loginMethod !== "integration";
  };

  const renderEmailSettings = () => {
    if (!canAddCredentials()) {
      return null;
    }
    return <EmailSettings />;
  };

  return (
    <div id="user-settings-page" className="page-container max-width-container">
      <h2>
        <Translated translationKey="settings" />
      </h2>
      <div className="top-margin">
        <LanguageSelector drop="up" />
      </div>
      <div className="top-margin">
        <UserCategorySetting className="large-top-margin" />
      </div>
      {renderEmailSettings()}
    </div>
  );
};

export default UserSettingsPage;
