import "./styles/style.scss";

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import TagManager from "react-gtm-module";
import { QueryClientProvider } from "@tanstack/react-query";

import App from "./components/App";
import VersionPage from "./components/pages/VersionPage";
import TranslationProvider from "./components/feature/TranslationProvider";
import Logger from "./utils/logger";
import { queryClient } from "./api-new";
import UserContextProvider from "./context/UserDataContext";
import GlobalContextProvider from "./context/GlobalContext";

// Google Consent Mode setup.
window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}
gtag("consent", "default", {
  ad_storage: "denied",
  analytics_storage: "denied",
  wait_for_update: 500,
});
gtag("set", "ads_data_redaction", true);

TagManager.initialize({
  gtmId: GTM_CONTAINER_ID,
});

Logger.initialize();

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <TranslationProvider>
          <GlobalContextProvider>
            <UserContextProvider>
              <CookiesProvider>
                <Router>
                  <Routes>
                    <Route path="/build/version" element={<VersionPage />} />
                    <Route path="/*" element={<App />} />
                  </Routes>
                </Router>
              </CookiesProvider>
            </UserContextProvider>
          </GlobalContextProvider>
        </TranslationProvider>
      </QueryClientProvider>
  </React.StrictMode>
);
