import { useState } from "react";
import PreloadMiddlePage from "./../pages/PreloadMiddlePage";
import Translated from "./Translated";
import ImageSet from "./ImageSet";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import { useUserData } from "../../context/UserDataContext";
import { useTranslation } from "./TranslationProvider";

const CategorySelector = (props) => {
  const { userSettings, setVehicleCategory } = useUserData();
  const { lang } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const hasLanguageSupport = (category) => {
    const settings = userSettings.data.categorySettings[category];
    return settings.langs.indexOf(lang) !== -1;
  };

  const selectCategory = (category) => {
    if (hasLanguageSupport(category)) {
      setVehicleCategory(category);
    } else {
      setShowModal(true);
    }
  };

  const renderCategoryElem = (category, isSelected) => {
    const categoryKey = "category_" + category.toLowerCase();
    const selectedClass = isSelected ? "selected" : "";
    const imageSrc = "../../assets/category/" + category + ".png";
    const buttonKey = hasLanguageSupport(category)
      ? "select"
      : "category_selector_button_info";

    return (
      <div
        key={category}
        className={"vehicle-category-select-item " + selectedClass}
      >
        <div>
          <ImageSet src={imageSrc} onClick={() => selectCategory(category)} />
        </div>
        <div>
          <Translated
            className="vehicle-category-select-item-title"
            translationKey={categoryKey}
          />
        </div>
        <Button
          id={"category-select-button-" + category}
          type="submit"
          onClick={() => selectCategory(category)}
        >
          <Translated translationKey={buttonKey} />
        </Button>
      </div>
    );
  };

  const renderModal = () => {
    return (
      <Modal show={showModal}>
        <Modal.Body>
          <Translated translationKey={"category_unsupported_lang"} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="vehicle_category_unsupported_lang"
            onClick={() => setShowModal(false)}
          >
            <Translated translationKey="close" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const renderCategorySelector = (currentlySelectedCategory) => {
    const cats = userSettings.data.vehicleCategories;
    if (!cats || cats.size === 0) {
      return (
        <Alert>
          <Translated translationKey="no_valid_categories_available" />
        </Alert>
      );
    }

    const catElems = cats.map((cat) => {
      return renderCategoryElem(cat, cat === currentlySelectedCategory);
    });

    const modal = renderModal();
    return (
      <div
        id={"category-selector-" + currentlySelectedCategory}
        className={props.className}
      >
        <h3>
          <Translated translationKey="select_vehicle_category" />
        </h3>
        <div className="vehicle-category-select-wrapper">{catElems}</div>
        {modal}
      </div>
    );
  };

  if (!userSettings.data) {
    return <PreloadMiddlePage />;
  }

  const category = userSettings.vehicleCategory;
  return renderCategorySelector(category);
};

export default CategorySelector;
