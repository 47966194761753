
class Tracker {

  static setUser(userId) {
    if (IS_PRODUCTION && window.dataLayer) {
      // Note, userId can se set to undefined too, do not shorten the value syntax for set().
      //ReactGA.set({userId: userId});
      // TODO Custom dimension userId seems to be inactive in GA
    }
  }

  static logPageView(viewId) {
    if (IS_PRODUCTION && window.dataLayer) {
      window.dataLayer.push({
        event: 'pageview',
        page: {
          viewId
        }
      })
    }
  }

  static logModalView(viewId) {
    if (IS_PRODUCTION && window.dataLayer) {
      window.dataLayer.push({
        event: 'pageview',
        page: {
          viewId: 'modal/' + viewId
        }
      })
    }
  }

  /**
   *  Log simple GA event without value or label
   * @param category
   * @param action
   */
  static logEvent(category, action) {
    if (IS_PRODUCTION && window.dataLayer) {
      window.dataLayer.push({
        event: 'event',
        eventProps: {
          category,
          action
        }
      })
    }
  }

  /**
   * Log GA event with a numeric value
   * @param category
   * @param action
   * @param value
   */
  static logEventValue(category, action, value) {
    if (IS_PRODUCTION && window.dataLayer) {
      window.dataLayer.push({
        event: 'event',
        eventProps: {
          category,
          action,
          value
        }
      })
    }
  }

  /**
   * Log GA event with a string label
   * @param category
   * @param action
   * @param label
   */
  static logEventLabel(category, action, label) {
    if (IS_PRODUCTION && window.dataLayer) {
      window.dataLayer.push({
        event: 'event',
        eventProps: {
          category,
          action,
          label: label.toString()
        }
      })
    }
  }

  static logWebshopPurchase(orderId, totalPrice) {
    // Google tracking
    if (IS_PRODUCTION && window.dataLayer) {
      window.dataLayer.push({
        event: 'purchase',
        purchaseData: {
          orderId,
          totalPrice
        }
      })
    }
  }

}

export default Tracker;