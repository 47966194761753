import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Translated from "./Translated";
import Circle from "../feature/ProgressBarCircle";
import UserCategoryDisplay from "./UserCategoryDisplay";
import classnames from "classnames";

const UserCategoryStatus = (props) => {
  const [acceptedAmount, setAcceptedAmount] = useState(0);

  useEffect(() => {
    // Set the accepted amount in delay, so that progress graphics update a little bit later
    const progressTimeout = setTimeout(() => {
      setAcceptedAmount(props.acceptedAmount);
    }, 500);
    return () => {
      clearTimeout(progressTimeout);
    };
  }, [props]);

  const countAcceptedRatio = (
    acceptedAmount,
    totalRequired,
    minimumProgress
  ) => {
    const acceptedClamped = Math.min(
      Math.max(0, acceptedAmount),
      totalRequired
    );
    return Math.max(acceptedClamped / totalRequired, minimumProgress);
  };

  const getProgressText = (acceptedAmount, totalRequired) => {
    const acceptedClamped = Math.min(
      Math.max(0, acceptedAmount),
      totalRequired
    );
    return acceptedClamped + "/" + totalRequired;
  };

  const renderAcceptanceText = () => {
    const isAccepted = props.acceptedAmount >= props.totalAcceptedRequired;
    if (isAccepted) {
      return (
        <Translated className="strong" translationKey="training_now_accepted" />
      );
    } else {
      return (
        <Translated
          className="lightly-transparent"
          translationKey="training_not_yet_accepted"
          values={{ required: props.totalAcceptedRequired }}
        />
      );
    }
  };

  // Show teaser progress when there are no accepted tests.
  let minProgress = 0;
  if (props.acceptedAmount === 0) {
    minProgress = 0.01;
  }

  const acceptedRatio = countAcceptedRatio(
    acceptedAmount,
    props.totalAcceptedRequired,
    minProgress
  );
  const progressText = getProgressText(
    props.acceptedAmount,
    props.totalAcceptedRequired
  );

  const classes = classnames(
    "user-category-status white-box vertical-margins",
    props.className
  );

  return (
    <div id={"user-category-status" + props.category} className={classes}>
      <div className="user-category-progress-container">
        <Circle
          className="progressbar-large-progress"
          initialAnimate={true}
          progressText={progressText}
          percentage={acceptedRatio * 100}
        />
        <div className="user-category-progress-note">
          <Translated translationKey={"accepted_full_amount"} />
        </div>
      </div>
      <div className="user-category-data">
        <UserCategoryDisplay category={props.category} />
        {props.children}
        <div className="top-margin">{renderAcceptanceText()}</div>
      </div>
    </div>
  );
};

UserCategoryStatus.propTypes = {
  userId: PropTypes.string,
  category: PropTypes.string,
  acceptedAmount: PropTypes.number,
  rejectedAmount: PropTypes.number,
  totalAcceptedRequired: PropTypes.number,
};

export default UserCategoryStatus;
