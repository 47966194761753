import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import MeIntegrationPage from "./MeIntegrationPage";
import MeTeacherPage from "./MeTeacherPage";
import MeStudentPage from "./MeStudentPage";
import { Roles, fillsRole } from "../../data/roles";
import { useUserData } from "../../context/UserDataContext";

export const MePage = () => {
  const { role, integrationPlatform } = useUserData();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const redirect = location.state?.redirect;
    if (redirect) {
      navigate(redirect);
    }
  }, []);

  if (integrationPlatform) {
    return <MeIntegrationPage />;
  } else if (fillsRole(role, Roles.TEACHER)) {
    return <MeTeacherPage allowFacebook={false} />;
  } else {
    return <MeStudentPage allowFacebook={true} />;
  }
};

export default MePage;
