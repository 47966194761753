import { useState } from "react";
import PreloadMiddlePage from "./PreloadMiddlePage";
import Translated from "./../feature/Translated";
import TestResultStatus from "../feature/results/TestResultStatus";
import TestResultLiteral from "../feature/results/TestResultLiteral";
import TestResultSign from "../feature/results/TestResultSign";
import TestResultImage from "../feature/results/TestResultImage";
import DateFormat from "../../utils/date";
import ErrorModal from "../feature/ErrorModal";
import { Roles } from "../../data/roles";
import MapUtil from "../../utils/map";
import ActiveBookStatus from "../feature/ActiveBookStatus";
import UserStatusLoaderWrapper from "../feature/UserStatusLoaderWrapper";
import UserCategoryStatus from "../feature/UserCategoryStatus";
import StartTrainingButton from "../feature/StartTrainingButton";
import VehicleInfo from "../feature/VehicleInfo";
import { hasVehicleInfo } from "../../data/vehicleInfoCategories";
import Button from "react-bootstrap/Button";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { useUserData } from "../../context/UserDataContext";
import { useFullTestResults } from "../../api-new";
import { useParams } from "react-router-dom";
import { useTranslation } from "../feature/TranslationProvider";

const ResultPage = () => {
  const {
    role: userDataRole,
    userId: userDataId,
    userSettings,
  } = useUserData();
  const { lang } = useTranslation();
  const { id } = useParams();
  const [showCorrectAnswers, setShowCorrectAnswers] = useState(false);
  const resultsQuery = useFullTestResults(id, lang);
  const role = userDataRole || Roles.STUDENT;
  const userId = userDataId || -1;

  const filterLiteralQuestions = (
    questions,
    includeCorrect,
    includeIncorrect
  ) => {
    if (!questions) {
      return [];
    }

    if (includeCorrect) {
      return questions.filter(
        (question) => question.answer === question.correctAnswer
      );
    }

    if (includeIncorrect) {
      return questions.filter(
        (question) => question.answer !== question.correctAnswer
      );
    }
  };

  const renderLiterals = (result, filteredLiterals, bookReferencesMap) => {
    if (!result.literalQuestions && !result.signQuestions) {
      return null;
    }

    const hasVisibleItems =
      filteredLiterals.literals.length + filteredLiterals.signs.length > 0;

    if (!hasVisibleItems) {
      return null;
    }

    return (
      <div id="result-literals-wrapper">
        <h3>
          <Translated translationKey="literals" />
        </h3>
        {filteredLiterals.literals.map((question) => (
          <TestResultLiteral
            question={question}
            key={question.id}
            bookReferences={bookReferencesMap}
          />
        ))}
        {filteredLiterals.signs.map((question) => (
          <TestResultSign
            question={question}
            key={question.id}
            bookReferences={bookReferencesMap}
          />
        ))}
      </div>
    );
  };

  const renderRisks = (result, filteredRisks, bookReferencesMap) => {
    if (!result.riskQuestions) {
      return null;
    }

    if (filteredRisks.length === 0) {
      return null;
    }

    return (
      <div id="result-risks-wrapper">
        <h3>
          <Translated translationKey="risks" />
        </h3>
        {filteredRisks.map((question) => (
          <TestResultLiteral
            question={question}
            key={question.id}
            bookReferences={bookReferencesMap}
          />
        ))}
      </div>
    );
  };

  const filterImageQuestions = (questions, showCorrect, showIncorrect) => {
    if (!questions || questions.length === 0) {
      return [];
    }

    if (showCorrect) {
      return questions.filter(
        (question) => question.answer === question.isAssertionTruthy
      );
    }

    if (showIncorrect) {
      return questions.filter(
        (question) => question.answer !== question.isAssertionTruthy
      );
    }
  };

  const renderImages = (result, filteredImages, bookReferencesMap) => {
    if (!result.imageQuestions) {
      return null;
    }

    if (filteredImages.length === 0) {
      return null;
    }

    const vehicleInfo = hasVehicleInfo(result.category) && (
      <div className="blue-box tight">
        <VehicleInfo category={result.category} />
      </div>
    );

    const elements = filteredImages.map((question, i) => (
      <TestResultImage
        key={i}
        question={question}
        bookReferences={bookReferencesMap}
      />
    ));

    return (
      <div id="result-images-wrapper">
        <h3>
          <Translated translationKey="images" />
        </h3>
        {vehicleInfo}
        <div id="test-result-images-container">{elements}</div>
      </div>
    );
  };

  const renderUserData = (result) => {
    if (userId === result.userId) {
      return null;
    }

    return (
      <div>
        <div className="blue-box">
          <div>
            <strong>
              <Translated translationKey="user_id" />:
            </strong>{" "}
            {result.userId}
          </div>
          <div>
            <strong>
              <Translated translationKey="date" />:
            </strong>{" "}
            {DateFormat.dateTime(result.endDateTime)}
          </div>
        </div>
        <br />
      </div>
    );
  };

  const renderUserStatus = () => {
    const selectedCategory = userSettings.vehicleCategory;
    if (!selectedCategory) {
      return null;
    }
    return (
      <UserStatusLoaderWrapper category={selectedCategory}>
        <UserCategoryStatus>
          <StartTrainingButton />
        </UserCategoryStatus>
      </UserStatusLoaderWrapper>
    );
  };

  const renderAnswers = (result, bookReferencesMap, showCorrect) => {
    const filterStatements = showCorrect ? [true, false] : [false, true];
    const filteredLiterals = {
      literals: filterLiteralQuestions(
        result.literalQuestions,
        filterStatements[0],
        filterStatements[1]
      ),
      signs: filterLiteralQuestions(
        result.signQuestions,
        filterStatements[0],
        filterStatements[1]
      ),
    };
    const filteredRisks = filterLiteralQuestions(
      result.riskQuestions,
      filterStatements[0],
      filterStatements[1]
    );
    const filteredImages = filterImageQuestions(
      result.imageQuestions,
      filterStatements[0],
      filterStatements[1]
    );

    const renderCommon = (
      <>
        {renderLiterals(result, filteredLiterals, bookReferencesMap)}
        {renderRisks(result, filteredRisks, bookReferencesMap)}
        {renderImages(result, filteredImages, bookReferencesMap)}
      </>
    );

    if (showCorrect) {
      return (
        <div
          className={`no-overflow top-margin ${
            showCorrectAnswers
              ? "animate-extend-height"
              : "animate-retract-height"
          }`}
        >
          {renderCommon}
        </div>
      );
    }

    return renderCommon;
  };

  const renderCorrectAnswers = (result, bookReferencesMap) => {
    return renderAnswers(result, bookReferencesMap, true);
  };

  const renderIncorrectAnswers = (result, bookReferencesMap) => {
    return renderAnswers(result, bookReferencesMap, false);
  };

  if (resultsQuery.isPending) {
    return <PreloadMiddlePage />;
  }

  if (resultsQuery.isError) {
    return (
      <div>
        <ErrorModal show={true} errorKey={resultsQuery.error.message} />
        &nbsp;
      </div>
    );
  }

  const result = resultsQuery.data.result;
  const bookReferences = resultsQuery.data.bookReferences;
  const bookReferencesMap = MapUtil.mapFromObject(bookReferences);

  return (
    <div id="result-page" className="page-container">
      <div className="max-width-container centered">
        {renderUserData(result)}
        <TestResultStatus result={result} />
        <div className="test-results-answers-list-wrapper">
          {renderIncorrectAnswers(result, bookReferencesMap)}
          <br />
          <Button onClick={() => setShowCorrectAnswers((prev) => !prev)}>
            {showCorrectAnswers ? (
              <>
                <Translated translationKey="hide_correct_answers" />
                <AiOutlineUp className="animate-rotate-180" />
              </>
            ) : (
              <>
                <Translated translationKey="show_correct_answers" />
                <AiOutlineDown className="animate-rotate-180" />
              </>
            )}
          </Button>
          {renderCorrectAnswers(result, bookReferencesMap)}
          {role === Roles.STUDENT ? renderUserStatus() : null}
          {role === Roles.STUDENT ? <ActiveBookStatus /> : null}
          <br />
        </div>
      </div>
    </div>
  );
};

export default ResultPage;
