import React from "react";
import Translated from "./Translated";
import Button from "react-bootstrap/Button";
import classnames from "classnames";
import { FaGraduationCap } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useUserData } from "../../context/UserDataContext";

/**
 * Forward to user's test results. Use user id of one is given and current logged in user otherwise.
 */
const UserResultsPreview = ({
  acceptedAmount,
  rejectedAmount,
  className,
  userId,
  role,
}) => {
  const navigate = useNavigate();

  const onClick = (e) => {
    if (role !== "student") {
      navigate("/results/" + userId);
    } else {
      navigate("/results");
    }
  };

  const renderNoTests = () => {
    const classes = classnames(
      "blue-box tight vertical-middle on-sides",
      className
    );
    return (
      <div className={classes}>
        <div className="vertical-middle">
          <FaGraduationCap className="largest right-margin" />
          <Translated translationKey="no_exercises_done" className="larger" />
        </div>
      </div>
    );
  };

  const renderTests = () => {
    const classes = classnames(
      "blue-inner-box tight vertical-middle on-sides phone-column",
      className
    );
    const totalDone = acceptedAmount + rejectedAmount;
    return (
      <div className={classes}>
        <div className="vertical-middle">
          <FaGraduationCap className="largest right-margin" />
          <Translated
            translationKey="total_tests_done"
            values={{ num: totalDone }}
            className="larger"
          />
        </div>
        <Button onClick={onClick} className="pull-right">
          <Translated translationKey="all_test_results" />
        </Button>
      </div>
    );
  };

  const totalDone = acceptedAmount + rejectedAmount;
  if (totalDone === 0) {
    return renderNoTests();
  } else {
    return renderTests();
  }
};

export default UserResultsPreview;
