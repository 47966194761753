import { useState } from "react";
import Button from "react-bootstrap/Button";

import PreloadMiddlePage from "./../pages/PreloadMiddlePage";
import Line from "../feature/ProgressBarLine";
import Translated from "../feature/Translated";
import SignsStart from "../feature/practise/signs/SignsStart";
import SignsQuestionFromImage from "../feature/practise/signs/SignsQuestionFromImage";
import SignsQuestionFromText from "../feature/practise/signs/SignsQuestionFromText";
import PractiseResults from "../feature/practise/PractiseResults";
import { useUserData } from "../../context/UserDataContext";
import { usePracticeLog, usePracticeSigns } from "../../api-new";
import { useTranslation } from "../feature/TranslationProvider";

const PracticeSignsPage = (props) => {
  const { token, userSettings } = useUserData();
  const { lang } = useTranslation();
  const [started, setStarted] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [seenIds, setSeenIds] = useState([]);
  const [faults, setFaults] = useState(0);
  const practiceLogMutation = usePracticeLog();
  const signsMutation = usePracticeSigns();

  const setAnswer = (optionId) => {
    const newAnswers = answers.slice();
    newAnswers.push(optionId);

    const newSeenIds = seenIds.slice();
    const question = signsMutation.data.questions[questionIndex];
    // Mark as seen if answered correctly, otherwise it may be asked even on the very next round again.
    if (question.correctAnswer === optionId) {
      if (newSeenIds.indexOf(question.id) === -1) {
        newSeenIds.push(question.id);
        if (newSeenIds.length > 30) {
          newSeenIds.shift();
        }
      }
    }

    setAnswers(newAnswers);
    setSeenIds(newSeenIds);
  };

  const nextQuestion = () => {
    const nextIndex = questionIndex + 1;
    setQuestionIndex(nextIndex);

    if (nextIndex === signsMutation.data.questions.length) {
      let faults = 0;
      signsMutation.data.questions.forEach((question, index) => {
        let answer = answers[index];
        if (answer !== question.correctAnswer) {
          faults++;
        }
      });

      setFaults(faults);

      if (token && userSettings.vehicleCategory) {
        practiceLogMutation.mutate({
          category: userSettings.vehicleCategory,
          practiceType: "signs",
          numQuestions: signsMutation.data.questions.length,
          numFaults: faults,
        });
      }
    }
  };

  const start = () => {
    signsMutation.mutate(
      { lang, seenIds },
      {
        onSuccess: () => {
          setStarted(true);
          setQuestionIndex(0);
          setAnswers([]);
        },
      }
    );
  };

  const renderQuestion = () => {
    const question = signsMutation.data.questions[questionIndex];
    const answer =
      questionIndex < answers.length ? answers[questionIndex] : null;
    const current = questionIndex + 1;
    const total = signsMutation.data.questions.length;
    const progressRatio = current / total;

    const format = questionIndex % 2 === 0 ? "text-question" : "image-question";
    let questionElement = null;

    if (format === "text-question") {
      questionElement = (
        <SignsQuestionFromText
          question={question}
          index={questionIndex}
          setAnswer={setAnswer}
          answer={answer}
          key={question.id}
        />
      );
    } else {
      questionElement = (
        <SignsQuestionFromImage
          question={question}
          index={questionIndex}
          setAnswer={setAnswer}
          answer={answer}
          key={question.id}
        />
      );
    }

    return (
      <div className="page-container">
        <div className="max-width-container">
          <div className="practise-wrapper">
            <div className="practise-progress-wrapper fade-in long-delay">
              <Line className="yellow" percentage={progressRatio * 100} />
            </div>
            {questionElement}
            {answer !== null ? (
              <div className="practise-next-wrapper min-button-space pull-right">
                <Button
                  id="practise-signs-next-button"
                  onClick={nextQuestion}
                  className="fade-in delay"
                >
                  <Translated translationKey="next" />
                </Button>
              </div>
            ) : (
              <div className="practise-next-wrapper min-button-space"></div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderPublicStart = () => {
    return (
      <>
        <h1>
          <Translated translationKey="public_signs_title" />
        </h1>
        <div className="blue-inner-box tight">
          <Translated translationKey="public_signs_description" />
        </div>
        <Button
          id="practise-signs-start"
          onClick={start}
          className="self-centered horizontal-padding medium-top-margin"
        >
          <Translated translationKey="start" />
        </Button>
      </>
    );
  };

  const renderResults = () => {
    const total = signsMutation.data.questions.length;
    const correctCount = total - faults;
    return (
      <PractiseResults
        correct={correctCount}
        total={total}
        restart={start}
        buttonId="practise-signs-restart"
      />
    );
  };

  let content;
  if (token && !userSettings.data) {
    content = <PreloadMiddlePage />;
  } else if (!started) {
    if (token) {
      content = <SignsStart start={start} />;
    } else {
      content = renderPublicStart();
    }
  } else if (signsMutation.isError) {
    content = <PreloadMiddlePage error={signsMutation.error.message} />;
  } else if (signsMutation.isPending || !signsMutation.data.questions) {
    content = <PreloadMiddlePage />;
  } else if (questionIndex < signsMutation.data.questions.length) {
    content = renderQuestion();
  } else {
    content = renderResults();
  }

  return (
    <div
      id="practise-signs-page"
      className="page-container max-width-container"
    >
      {content}
    </div>
  );
};

export default PracticeSignsPage;
