import Alert from "react-bootstrap/Alert";

import BookStatus from "./BookStatus";
import Translated from "./Translated";
import { useBookStatuses } from "../../api-new";
import Spinner from "./Spinner";

const UserBookStatuses = (props) => {
  const bookStatusesQuery = useBookStatuses(props.userId);

  if (bookStatusesQuery.isPending) {
    return <Spinner />;
  }

  if (bookStatusesQuery.isError) {
    return (
      <Alert>
        <Translated translationKey={bookStatusesQuery.error.message} />
      </Alert>
    );
  }

  const bookStatuses = bookStatusesQuery.data;

  return (
    <div>
      {bookStatuses.map((bookStatus, i) => {
        return <BookStatus key={i} bookStatus={bookStatus} />;
      })}
    </div>
  );
};

export default UserBookStatuses;
