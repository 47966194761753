import { useState } from "react";
import Alert from "react-bootstrap/Alert";

import TextInputForm from "../feature/TextInputForm";
import Spinner from "../feature/Spinner";
import Translated from "../feature/Translated";
import Tracker from "../../utils/tracker";
import UserList from "../feature/UserList";
import { useSearchUsers } from "../../api-new";
import { useTranslation } from "../feature/TranslationProvider";

const UsersSearchPage = () => {
  const { translate } = useTranslation();
  const [term, setTerm] = useState("");
  const userSearchQuery = useSearchUsers(term);

  const searchUsers = (term) => {
    if (userSearchQuery.isFetching) {
      return;
    }
    // Filter out pass hash
    const cleanedTerm = term ? term.replace(/#/g, "") : "";
    setTerm(cleanedTerm);
    Tracker.logEventLabel("search", "search-users", cleanedTerm);
  };

  const renderUserList = () => {
    if (userSearchQuery.isFetching) {
      return <Spinner className="primary" />;
    }

    if (userSearchQuery.isError || !userSearchQuery.data) {
      return null;
    }

    return (
      <div>
        <UserList
          users={userSearchQuery.data.users}
          noUsersTranslationKey="no_search_results"
        />
      </div>
    );
  };

  const placeholderText = translate("search_user");
  const submitText = translate("search_do");
  return (
    <div>
      <h3>
        <Translated translationKey="search" />
      </h3>
      <TextInputForm
        onSubmit={searchUsers}
        minLength={4}
        id="user-search-container"
        iconAddon="search"
        placeholderText={placeholderText}
        submitText={submitText}
        className="fade-in"
      />
      <br />
      {userSearchQuery.isFetching ? (
        <Spinner className="primary" />
      ) : (
        renderUserList()
      )}
      {userSearchQuery.isError ? (
        <Alert>
          <Translated translationKey={userSearchQuery.error.message} />
        </Alert>
      ) : null}
    </div>
  );
};

export default UsersSearchPage;
