import { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";

import Spinner from "./Spinner";
import MarkdownUtil from "../../utils/markdown";
import Translated from "./Translated";
import { useBookContent, useSendBookContentStatus } from "../../api-new";
import { useUserData } from "../../context/UserDataContext";
import { useTranslation } from "./TranslationProvider";

const BookContent = (props) => {
  const { token } = useUserData();
  const { lang } = useTranslation();
  const [source, setSource] = useState(null);
  const bookContentQuery = useBookContent(props.contentId, props.lang || lang);
  const bookStatusMutation = useSendBookContentStatus();

  useEffect(() => {
    // Book content can be set as a source. Otherwise it will be fetched based on the id.
    const source = props.source;
    if (source) {
      sendReadLog(source.id, source.materialId);
      setSource(source.markdown);
    } else {
      bookContentQuery.refetch();
    }
  }, []);

  useEffect(() => {
    if (bookContentQuery.isSuccess) {
      const content = bookContentQuery.data;
      sendReadLog(content.id, content.materialId);
      setSource(content.markdown);
    }
  }, [bookContentQuery.data]);

  const sendReadLog = (contentId, materialId) => {
    if (token) {
      bookStatusMutation.mutate({ materialContentId: contentId, materialId });
    }
  };

  let content;
  if (bookContentQuery.isError) {
    content = (
      <Alert>
        <Translated translationKey={bookContentQuery.error.message} />
      </Alert>
    );
  } else if (!source) {
    content = <Spinner className="primary" />;
  } else {
    content = MarkdownUtil.render(source, "section");
  }
  return <div className="book-content">{content}</div>;
};

export default BookContent;
