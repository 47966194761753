import Button from "react-bootstrap/Button";
import Spinner from "../feature/Spinner";
import Alert from "react-bootstrap/Alert";

import Translated from "../feature/Translated";
import { useAcceptAgreement, useAgreementContent } from "../../api-new";
import { useUserData } from "../../context/UserDataContext";
import { useTranslation } from "../feature/TranslationProvider";

const TermsOfServicePage = () => {
  const { requiredTos, setRequiredTos } = useUserData();
  const { lang } = useTranslation();
  const agreementQuery = useAgreementContent(
    "terms-of-service",
    requiredTos,
    lang
  );
  const agreementMutation = useAcceptAgreement();

  const accept = () => {
    agreementMutation.mutate(
      {
        agreementContentId: agreementQuery.data.id,
      },
      {
        onSuccess: () => {
          setRequiredTos(null);
        },
      }
    );
  };

  let content;
  if (agreementQuery.isPending) {
    content = <Spinner />;
  } else if (agreementQuery.isError) {
    content = (
      <Alert>
        <Translated translationKey={agreementQuery.error.message} />
      </Alert>
    );
  } else {
    content = (
      <>
        <span
          className="terms-of-service"
          dangerouslySetInnerHTML={{
            __html: agreementQuery.data.content,
          }}
        />
        <div>
          <Button onClick={accept}>
            <Translated translationKey="accept" />
          </Button>
        </div>
      </>
    );
  }

  return (
    <div id="terms-of-service-page" className="page-container">
      <div className="max-width-container">{content}</div>
    </div>
  );
};

export default TermsOfServicePage;
