import React, { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import { IoAlertOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";

import BookBrowser from "../feature/BookBrowser";
import Translated from "../feature/Translated";
import PreloadMiddlePage from "./PreloadMiddlePage";
import { Roles } from "../../data/roles";
import CategorySelector from "../feature/CategorySelector";
import SettingsUtil from "../../utils/settingsUtil";
import { useUserData } from "../../context/UserDataContext";
import { useBookIndex, useBookStatuses } from "../../api-new";
import { useTranslation } from "../feature/TranslationProvider";

/**
 * Book page that reads users selected category and language and selects book accordingly.
 * Uses browser that shows read status.
 * Used for students.
 */
const BookPage = (props) => {
  const { lang } = useTranslation();
  const { userId, role, userSettings } = useUserData();
  const { chapterId } = useParams();
  const [bookId, setBookId] = useState(null);
  const [error, setError] = useState(null);
  const bookStatusesQuery = useBookStatuses(userId, lang);
  const bookIndexQuery = useBookIndex(bookId, lang);
  const category = userSettings.vehicleCategory;

  useEffect(() => {
    {
      if (!category || !userSettings.data) {
        return;
      }

      const books = userSettings.data.availableBooks;
      const booksForCategory = books.filter((book) =>
        SettingsUtil.categoryContainsBookCategory(category, book.category)
      );

      if (booksForCategory.length === 0) {
        return setError("no-book-for-category");
      }

      const book = books.find((book) => {
        if (book.lang !== lang) {
          return false;
        }
        // Ugly olb => b hack!
        let bCat = book.category;
        return bCat === category || (bCat === "b" && category === "olb");
      });

      if (!book) {
        return setError("no-book-for-language");
      }
      if (!book.hasLicense) {
        return setError("insufficient-licenses");
      }

      setBookId(book.id);
    }
  }, [userSettings.data, category]);

  let content;
  if (!category) {
    content = <CategorySelector className="fade-in" />;
  } else if (error) {
    content = (
      <Alert>
        <IoAlertOutline /> <Translated translationKey={error} />
      </Alert>
    );
  } else if (
    !bookIndexQuery.data ||
    (!bookStatusesQuery.data && role === Roles.STUDENT)
  ) {
    content = <PreloadMiddlePage />;
  } else {
    let bookStatus;
    let bookData;

    bookStatus = bookStatusesQuery.data.find(
      (status) => status.bookId === bookId
    );

    bookData = [...bookIndexQuery.data.content].sort(
      (a, b) => a.positionIndex - b.positionIndex
    );

    content = (
      <BookBrowser
        chapters={bookData}
        status={bookStatus}
        chapterId={chapterId}
      />
    );
  }

  return (
    <div id="book-page" className="page-container">
      <div className="max-width-container centered">{content}</div>
    </div>
  );
};

export default BookPage;
