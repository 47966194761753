import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import SubMenu from "../feature/SubMenu";
import { useGlobalData } from "../../context/GlobalContext";

/**
 * Wrap sub pages from router configs under one SubMenu.
 * Sub pages are just regular Pages with gutter automatically removed.
 */
const SubPagesContainer = (props) => {
  const { pageState, setMenuIndex } = useGlobalData();
  const params = useParams();
  const navigate = useNavigate();
  const selectedMenuIndex = pageState[props.containerId]?.selectedMenuIndex;
  const containerId = props.containerId;

  useEffect(() => {
    const paramsSubId = params.subPage;
    // Check if the current path overrides stored selected page index
    // or navigate to default if there is none set yet and path does not mandate anything
    if (paramsSubId) {
      // Set selected index to what path params define
      setSelectedIndex(getMenuIndex(paramsSubId));
    } else {
      if (selectedMenuIndex === undefined) {
        // No index defined yet, navigate to 0 index path
        setSelectedIndex(0);
        navigate(getSubPageFullPath(0), { replace: true });
      } else {
        navigate(getSubPageFullPath(selectedMenuIndex), {
          replace: true,
        });
      }
    }
  }, [containerId]);

  const setSelectedIndex = (index) => {
    setMenuIndex(containerId, index);
  };

  const getMenuIndex = (subPageId) => {
    let foundIndex = -1;
    props.subPageData.forEach((pageData, index) => {
      if (pageData.pageId === subPageId) {
        foundIndex = index;
      }
    });
    return foundIndex;
  };

  const getSubPageData = (menuIndex) => {
    return props.subPageData[menuIndex];
  };

  const getSubPageComponent = (menuIndex) => {
    const data = getSubPageData(menuIndex);
    return data ? data.component : null;
  };

  const getSubPageFullPath = (menuIndex) => {
    const data = getSubPageData(menuIndex);
    return "/" + props.baseUrl + data.pageId;
  };

  const subMenuListener = (selectedIndex) => {
    setSelectedIndex(selectedIndex);
    navigate(getSubPageFullPath(selectedIndex));
  };

  const SubPageComp = getSubPageComponent(selectedMenuIndex);
  const subPageElem = SubPageComp ? (
    <SubPageComp className="no-page-gutter" />
  ) : null;

  return (
    <div className="page-container max-width-container">
      <SubMenu
        selectedIndex={selectedMenuIndex}
        selectionListener={subMenuListener}
        items={props.subPageData}
        key={containerId}
      />
      {subPageElem}
    </div>
  );
};

export default SubPagesContainer;
