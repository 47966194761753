import PropTypes from "prop-types";
import classNames from "classnames";
import { Alert } from "react-bootstrap";

import Translated from "./Translated";
import Spinner from "./Spinner";
import DateUtil from "../../utils/date";
import { useUserProfile } from "../../api-new";
import { useUserData } from "../../context/UserDataContext";

const ProfileTeacher = (props) => {
  const { userId } = useUserData();
  const profileQuery = useUserProfile(userId);

  if (profileQuery.isPending) {
    return <Spinner className="padded primary" />;
  }

  if(profileQuery.isError) {
    return (
      <Alert>
        <Translated translationKey={profileQuery.error.message} />
      </Alert>
    );
  }

  const profile = profileQuery.data;

  const classes = classNames(
    props.className,
    "user-profile-container",
    "fade-in"
  );

  return (
    <div id="profile-teacher-container" className={classes}>
      <div className="user-profile-data">
        <h1 className="uppercase">
          <Translated
            translationKey={profile.userData.name}
            options={{ onlyIfKey: true }}
          />
        </h1>
        <h4>
          {profile.userData.schoolName}{" "}
          <i className="semi-transparent smaller">
            {" "}
            @{profile.userData.schoolId.toUpperCase()}
          </i>
        </h4>
        <br />
        {profile.userData.closingDate ? (
          <div className="user-profile-usage-valid">
            <Translated
              translationKey="license_valid"
              values={{
                valid_until: DateUtil.date(profile.userData.closingDate),
              }}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

ProfileTeacher.propTypes = {
  allowFacebook: PropTypes.bool,
};

export default ProfileTeacher;
