import { useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import classNames from "classnames";

import OptionsMenu from "../feature/OptionsMenu";
import Spinner from "../feature/Spinner";
import Translated from "../feature/Translated";
import Tracker from "../../utils/tracker";
import UserList from "../feature/UserList";
import { useUserData } from "../../context/UserDataContext";
import { useGlobalData } from "../../context/GlobalContext";
import { useListStudents } from "../../api-new";

const PAGE_ID = "USERS_LIST_PAGE";

const ITEM_DATA = [
  { id: "latest-accepted-students", localizationId: "last_approved_students" },
  {
    id: "latest-activated-students",
    localizationId: "latest_activated_students",
  },
  { id: "all-active-students", localizationId: "all_active_students" },
  { id: "latest-added-students", localizationId: "latest_added_students" },
];

const UsersListPage = (props) => {
  const { pageState, setSelectedRuleIndex } = useGlobalData();
  const { token, schoolId } = useUserData();
  const selectedRuleIndex = pageState[PAGE_ID]?.selectedRuleIndex;
  const listQuery = useListStudents(schoolId, ITEM_DATA[selectedRuleIndex]?.id);

  useEffect(() => {
    if (!selectedRuleIndex) {
      onRuleSelected(0, ITEM_DATA[0].id);
    }
  }, []);

  const onRuleSelected = (index, id) => {
    setSelectedRuleIndex(PAGE_ID, index);

    switch (id) {
      case "latest-added-students":
        Tracker.logEvent("search", "list-latest-added-students");
        break;
      case "latest-activated-students":
        Tracker.logEvent("search", "list-latest-activated-students");
        break;
      case "latest-accepted-students":
        Tracker.logEvent("search", "list-latest-added-students");
        break;
      case "all-active-students":
        Tracker.logEvent("search", "list-all-active-students");
        break;
    }
  };

  const renderUserList = () => {
    if (listQuery.isPending) {
      return <Spinner className="primary" />;
    }

    if (listQuery.isError) {
      return null;
    }

    return (
      <div>
        <UserList
          users={listQuery.data.users}
          noUsersTranslationKey="no_search_results"
          className="fade-in"
        />
      </div>
    );
  };

  if (!token) {
    return null;
  }
  if (selectedRuleIndex === undefined) {
    return null;
  }

  const classes = classNames(
    "page-container max-width-container fade-in",
    props.className
  );
  return (
    <div id="user-list-page" className={classes}>
      <OptionsMenu
        itemData={ITEM_DATA}
        selectedIndex={selectedRuleIndex}
        onSelect={onRuleSelected}
        className="blue-inner-box tight white"
      />
      {listQuery.isError ? (
        <Alert>
          <Translated translationKey={listQuery.error.message} />
        </Alert>
      ) : null}
      {renderUserList()}
    </div>
  );
};

export default UsersListPage;
