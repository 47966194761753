import {  useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import classNames from "classnames";
import Popover from "react-bootstrap/Popover";
import Overlay from "react-bootstrap/Overlay";
import Modal from "react-bootstrap/Modal";
import Translated from "../Translated";
import FullTestTimer from "./FullTestTimer";
import FullTestButton from "./FullTestButton";
import Spinner from "../Spinner";
import Tracker from "../../../utils/tracker";
import ZoomableImage from "../ZoomableImage";
import FullTestSubmit from "./FullTestSubmit";
import FullTestQuestionSelector from "./FullTestQuestionSelector";
import FullTestQuestionSelectorForm from "./FullTestQuestionSelectorForm";
import VehicleInfoTrigger from "../VehicleInfoTrigger";
import { hasVehicleInfo } from "../../../data/vehicleInfoCategories";
import { useSubmitFullTest } from "../../../api-new";
import { formatFulltestAnswers } from "../../../utils/fullTestUtils";

const DURATION_SECS = 1800;
const TIMER_INFO_DELAY_SECS = 900;

const FullTestMain = (props) => {
  const [completed, setCompleted] = useState(
    props.initState?.completed || false
  );
  const [questionIndex, setQuestionIndex] = useState(
    props.initState?.questionIndex || 0
  );
  const [answers, setAnswers] = useState(props.initState?.answers || {});
  const [startTime, setStartTime] = useState(
    props.initState?.startTime || Date.now()
  );
  const [imageReady, setImageReady] = useState(false);
  const [showMarkButtonOverlay, setShowMarkButtonOverlay] = useState(false);
  const [showTimerModal, setShowTimerModal] = useState(false);
  const [showTimerOverlay, setShowTimerOverlay] = useState(false);
  const [assertion, setAssertion] = useState(false);
  const timerRef = useRef(null);
  const timerInfoTimeoutRef = useRef(null);
  const navigate = useNavigate();
  const topDiv = useRef(null);
  const middleDiv = useRef(null);
  const bottomDiv = useRef(null);
  const imageRef = useRef(null);
  const markButtonRef = useRef(null);
  const viewportRef = useRef({ width: 0, height: 0 });
  const submitTestMutation = useSubmitFullTest();

  useEffect(() => {
    preloadImages();
    checkImageLoadStatus();
    window.addEventListener("resize", resizeImage);
    timerInfoTimeoutRef.current = setTimeout(() => {
      setShowMarkButtonOverlay(true);
      Tracker.logEventValue(
        "full-test-timer",
        "automatic-view",
        TIMER_INFO_DELAY_SECS
      );
    }, TIMER_INFO_DELAY_SECS * 1000);

    return () => {
      clearTimeout(timerInfoTimeoutRef.current);
      window.removeEventListener("resize", resizeImage);
    };
  }, []);

  useEffect(() => {
    if (props.save) {
      props.save({
        completed,
        questionIndex,
        answers,
        startTime,
        imageReady,
        showMarkButtonOverlay,
      });
    }
  });

  useEffect(() => {
    preloadImages();
    checkImageLoadStatus();
  }, [questionIndex]);

  const submitAnswers = () => {
    const formattedAnswers = formatFulltestAnswers(
      props.test.questions,
      answers
    );

    submitTestMutation.mutate(
      {
        testId: props.test.id,
        answers: formattedAnswers,
      },
      {
        onSuccess: (data) => {
          props.removeSave();
          navigate("/result/" + data.resultId);
        },
      },
      
    );
  };

  const renderQuestionNum = () => {
    return (
      <div className="question-num">
        {questionIndex + 1 + "/" + props.test.questions.length}
      </div>
    );
  };

  const renderVehicleInfoTrigger = (question) => {
    const category = props.test.category;

    if (question.type !== "image" || assertion || !hasVehicleInfo(category)) {
      return null;
    }

    return (
      <div className="vehicle-info">
        <VehicleInfoTrigger category={category} />
      </div>
    );
  };

  const renderTimer = () => {
    const clickHandler = () => {
      Tracker.logEvent("full-test-timer", "manual-view");
      setShowTimerOverlay(true);
    };
    const completeHandler = () => {
      Tracker.logEventValue(
        "full-test-timer",
        "automatic-view",
        FullTestMain.DURATION_SECS
      );
      setShowTimerModal(true);
    };

    return (
      <FullTestTimer
        key="full-test-timer"
        start={startTime}
        durationSec={DURATION_SECS}
        onClick={clickHandler}
        innerRef={timerRef}
        onComplete={completeHandler}
      />
    );
  };

  const renderTimerOverlay = () => {
    const onHide = () => {
      clearTimeout(timerInfoTimeoutRef.current);
      setShowTimerOverlay(false);
    };

    return (
      <Overlay
        show={showTimerOverlay}
        target={timerRef.current}
        placement="bottom"
        onHide={onHide}
        rootClose
      >
        <Popover id="timer-popover">
          <Popover.Body>
            <Translated translationKey="timer_info" />
          </Popover.Body>
        </Popover>
      </Overlay>
    );
  };

  const renderTimerModal = () => {
    return (
      <Modal show={showTimerModal}>
        <Modal.Body>
          <Translated translationKey="timer_info_full" />
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="full-test-close-modal"
            onClick={() => setShowTimerModal(false)}
          >
            <Translated translationKey="ok" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const renderQuestion = (question) => {
    if (assertion) {
      return null;
    }

    let content;
    switch (question.type) {
      case "literal":
      case "risk":
        content = question.question;
        break;
      case "sign":
        content = <Translated translationKey="select_definition_for_sign" />;
        break;
      case "image":
        content = question.assertion;
        break;
    }
    return <div className="question-text">{content}</div>;
  };

  const renderQuestionContent = (question) => {
    if (assertion) {
      return renderAssertion();
    }

    switch (question.type) {
      case "literal":
      case "risk":
        return renderLiteral(question);
      case "sign":
        return renderSign(question);
      case "image":
        return renderImage(question);
      default:
        return null;
    }
  };

  const renderAssertion = () => {
    return (
      <div className="next-assertion-container">
        <div className="next-assertion">{assertion}</div>
        <div>
          <Button
            id="full-test-assert-continue"
            onClick={() => setAssertion(null)}
            className="uppercase"
          >
            <Translated translationKey="continue" />
          </Button>
        </div>
      </div>
    );
  };

  const renderLiteral = (question) => {
    const answer = answers[question.id];

    let image;
    if (question.imageUrl) {
      const imgProps = {
        width: 0,
        height: 0,
        ref: imageRef,
        onLoad: resizeImage,
        draggable: "false",
      };
      image = (
        <div>
          <ZoomableImage src={question.imageUrl} imgProps={imgProps} />
        </div>
      );
    }

    return (
      <div
        key={questionIndex}
        className={classNames({ padding: !question.imageUrl }, "fade-in")}
      >
        {image}
        {Object.keys(question.options).map((optionId, index) => (
          <div
            key={index}
            onClick={() => setAnswer(question.id, optionId)}
            className={classNames("literal-option", {
              "selected bump": answer === optionId,
            })}
          >
            {optionId + ") " + question.options[optionId]}
          </div>
        ))}
      </div>
    );
  };

  const renderSign = (question) => {
    const answer = answers[question.id];
    const src = question.question.imageUrl;

    return (
      <div key={questionIndex} className="sign-container padding fade-in">
        <img key={questionIndex} src={src} className="sign-image" />
        <div className="sign-options-container">
          {Object.keys(question.options).map((optionId, index) => (
            <div
              key={index}
              onClick={() => setAnswer(question.id, optionId)}
              className={classNames("literal-option", {
                "selected bump": answer === optionId,
              })}
            >
              {optionId + ") " + question.options[optionId].text}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderImage = (question) => {
    const imgProps = {
      width: 0,
      height: 0,
      ref: imageRef,
      onLoad: resizeImage,
      draggable: "false",
    };

    return (
      <div className="image-container fade-in">
        {imageReady ? (
          <ZoomableImage
            src={question.imageUrl}
            imgProps={imgProps}
            modalClassName="fulltest-image-modal"
          />
        ) : (
          <Spinner />
        )}
      </div>
    );
  };

  const resizeImage = () => {
    let h = document.documentElement.clientHeight;
    let w = document.documentElement.clientWidth;
    if (w != viewportRef.current.width) {
      viewportRef.current.width = w;
      viewportRef.current.height = h;
    } else if (h > viewportRef.current.height) {
      viewportRef.current.height = h;
    } else {
      h = viewportRef.current.height;
    }

    if (!imageRef.current || !topDiv.current || !bottomDiv.current) {
      return;
    }

    const freeH =
      h - topDiv.current.clientHeight - bottomDiv.current.clientHeight;
    const freeW = Math.min(middleDiv.current.clientWidth, w);
    const img = imageRef.current;
    const imgH = img.naturalHeight;
    const imgW = img.naturalWidth;

    if (imgW > freeW || imgH > freeH) {
      const imageRatio = imgW / imgH;
      const containerRatio = freeW / freeH;
      if (imageRatio > containerRatio) {
        img.width = freeW;
        img.height = freeW / imageRatio;
      } else {
        img.height = freeH;
        img.width = freeH * imageRatio;
      }
    } else {
      img.width = img.naturalWidth;
      img.height = img.naturalHeight;
    }
  };

  const renderYesButton = (question) => {
    if (question.type != "image") {
      return null;
    }
    const answer = answers[question.id];
    return (
      <FullTestButton
        id="full-test-yes-button"
        onClick={() => setAnswer(question.id, "a")}
        label="yes"
        selected={answer === "a"}
      />
    );
  };

  const renderNoButton = (question) => {
    if (question.type != "image") {
      return null;
    }
    const answer = answers[question.id];
    return (
      <FullTestButton
        id="full-test-no-button"
        onClick={() => setAnswer(question.id, "c")}
        label="no"
        selected={answer === "c"}
      />
    );
  };

  const renderMarkButton = (question) => {
    const answer = answers[question.id];
    const clickHandler = () => {
      setShowMarkButtonOverlay(true);
      setAnswer(question.id, "d");
    };
    return (
      <div>
        <div ref={markButtonRef}>
          <FullTestButton
            id="full-test-mark-question"
            onClick={clickHandler}
            selected={answer === "d"}
            label="full_test_mark_question"
          ></FullTestButton>
        </div>

        <Overlay
          show={showMarkButtonOverlay}
          target={markButtonRef.current}
          placement="top"
          onHide={() => setShowMarkButtonOverlay(false)}
          rootClose
        >
          <Popover id="mark-button-tooltip">
            <Popover.Body>
              <Translated translationKey="mark_tooltip" />
            </Popover.Body>
          </Popover>
        </Overlay>
      </div>
    );
  };

  const renderNextButton = () => {
    const question = props.test.questions[questionIndex];
    const isAnswered = answers[question.id] !== undefined;
    const allAnswered =
      Object.values(answers).length === props.test.questions.length;
    let label;
    let action;
    if (allAnswered || questionIndex === props.test.questions.length - 1) {
      label = "ready";
      action = complete;
    } else {
      label = "next";
      action = nextQuestion;
    }
    return (
      <FullTestButton
        onClick={action}
        disabled={!isAnswered}
        label={label}
        id={`full-test-${label}-button`}
      />
    );
  };

  const renderQuestionSelector = () => {
    return (
      <FullTestQuestionSelector
        answers={answers}
        questions={props.test.questions}
        index={questionIndex}
        select={selectQuestion}
      />
    );
  };

  const renderQuestionSelectorForm = () => {
    return (
      <FullTestQuestionSelectorForm
        answers={answers}
        questions={props.test.questions}
        index={questionIndex}
        select={selectQuestion}
      />
    );
  };

  const renderSubmit = () => {
    return (
      <FullTestSubmit
        answers={answers}
        error={
          submitTestMutation.isError ? submitTestMutation.error.message : ""
        }
        isSending={submitTestMutation.isPending}
        review={goBackToQuestions}
        submit={submitAnswers}
      />
    );
  };

  const setAnswer = (questionId, answer) => {
    if (answers[questionId] === answer) {
      return;
    }

    const newAnswers = Object.assign({}, answers);
    newAnswers[questionId] = answer;
    setAnswers(newAnswers);
  };

  const nextQuestion = () => {
    const index = questionIndex;
    if (index < props.test.questions.length - 1) {
      const currentQuestion = props.test.questions[index];
      const nextQuestion = props.test.questions[index + 1];
      let assertion;
      if (nextQuestion.assertion !== currentQuestion.assertion) {
        assertion = nextQuestion.assertion;
      }
      setAssertion(assertion);
      selectQuestion(index + 1);
    } else {
      complete();
    }
  };

  const complete = () => {
    setCompleted(true);
  };

  const goBackToQuestions = () => {
    let index = props.test.questions.findIndex(
      (question) => answers[question.id] === "d"
    );
    index = Math.max(index, 0);
    setCompleted(false);
    setQuestionIndex(index);
    submitTestMutation.reset()
  };

  const selectQuestion = (index) => {
    setQuestionIndex(index);
  };

  const checkImageLoadStatus = () => {
    const question = props.test.questions[questionIndex];
    if (question && question.type === "image") {
      const img = new Image();
      img.src = question.imageUrl;
      if (imageReady !== img.complete) {
        setImageReady(img.complete);
      }
      if (!img.complete) {
        img.onload = () => {
          setImageReady(true);
        };
      }
    }
  };

  const preloadImages = (count = 5) => {
    for (let i = 1; i <= count; i++) {
      const qIndex = questionIndex + i;
      if (qIndex < props.test.questions.length) {
        const question = props.test.questions[qIndex];
        if (question.type === "image") {
          const img = new Image();
          img.src = question.imageUrl;
        } else if (question.type === "sign") {
          const img = new Image();
          const src = question.question.imageUrl;
          img.src = src;
        }
      }
    }
  };

  let topContent;
  let middleContent;
  let bottomContent;
  const imageRotateIcon = require("../../../assets/rotate-phone.svg");

  if (completed) {
    topContent = (
      <div id="full-test-top-completed" className="top align-right">
        {renderTimer()}
      </div>
    );

    middleContent = renderSubmit();
  } else {
    const question = props.test.questions[questionIndex];

    topContent = (
      <div id="full-test-top" className="top" ref={topDiv}>
        {renderQuestionNum()}
        {renderVehicleInfoTrigger(question)}
        {renderQuestion(question)}
        {renderTimer()}
      </div>
    );

    middleContent = (
      <div className="middle" ref={middleDiv}>
        {renderQuestionContent(question)}
      </div>
    );

    if (!assertion) {
      bottomContent = (
        <div id="full-test-bottom" className="bottom" ref={bottomDiv}>
          {renderMarkButton(question)}
          {renderQuestionSelector()}
          {renderQuestionSelectorForm()}
          {renderYesButton(question)}
          {renderNoButton(question)}
          {renderNextButton()}
        </div>
      );
    }
  }

  return (
    <div id="full-test-container">
      <div id="full-test">
        {topContent}
        {middleContent}
        {bottomContent}
        {renderTimerOverlay()}
        {renderTimerModal()}
      </div>
      <img src={imageRotateIcon} className="rotate-icon swing" />
    </div>
  );
};

FullTestMain.propTypes = {
  test: PropTypes.object.isRequired,
  save: PropTypes.func.isRequired,
  removeSave: PropTypes.func.isRequired,
  initState: PropTypes.object,
};

export default FullTestMain;
