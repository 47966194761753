import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useNavigate } from "react-router-dom";

import { useUserData } from "../../context/UserDataContext";
import { useTranslation } from "./TranslationProvider";

const MeMenu = ({ menuData, closeNavigation }) => {
  const { token, logout } = useUserData();
  const { translate } = useTranslation();
  const navigate = useNavigate();

  const handleListItemClick = (eventId) => {
    switch (eventId) {
      case "contact":
      case "me":
      case "settings":
        navigate("/" + eventId);
        break;
      case "logout":
        closeNavigation();
        logout(navigate);
        break;
    }
  };

  const buildMenuItem = (id, labelKey) => {
    const loc = translate(labelKey);
    return (
      <Dropdown.Item id={id} key={id} onClick={() => handleListItemClick(id)}>
        <span>{loc}</span>
      </Dropdown.Item>
    );
  };

  if (!token || !menuData) {
    return null;
  }
  let menuTitle = translate("menu");

  const infoItems = menuData.map((item) =>
    buildMenuItem(item.id, item.titleKey)
  );
  const classes = "me-menu ";
  return (
    <DropdownButton
      className={classes}
      aria-label="Menu"
      role="presentation"
      id="me-actions"
      title={menuTitle}
    >
      {infoItems}
    </DropdownButton>
  );
};

export default MeMenu;
