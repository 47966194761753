import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import queryString from "query-string";
import WebFont from "webfontloader";
import { useCookies } from "react-cookie";
import { Settings } from "luxon";

import Header from "./feature/Header";
import Footer from "./feature/Footer";
import FullTestFooter from "./feature/fulltest/FullTestFooter";
import Translated from "./feature/Translated";
import Tracker from "../utils/tracker";
import AppRoutes from "./AppRoutes";
import LoginMethodsModal from "./feature/LoginMethodsModal";
import TrackerAB from "../utils/trackerAB";
import TermsOfServicePage from "./pages/TermsOfServicePage";
import { useUserData } from "../context/UserDataContext";
import { useGlobalData } from "../context/GlobalContext";
import { useLogDirectTokenLogin } from "../api-new";

Settings.defaultZone = "Europe/Helsinki";

const App = () => {
  const { token, userId, isLoadingUserData, requiredTos, login } =
    useUserData();
  const {
    hasMaintenance,
    setHasMaintenance,
    hasConnection,
    setHasConnection,
  } = useGlobalData();
  const location = useLocation();
  const [cookies] = useCookies(["mid"]);
  const tokenLoginMutation = useLogDirectTokenLogin();
  const urlParams = queryString.parse(location.search);

  // TODO Move this to index.js?
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Ubuntu:300,400,700"],
        urls: "https://fonts.googleapis.com/css?",
      },
    });

    const authToken = urlParams["jwt"];
    if (authToken) {
      tokenLoginMutation.mutate({ token: authToken });
      login({ token: authToken });
    }
  }, []);

  useEffect(() => {
    const machineId = cookies.mid;

    TrackerAB.register({ machineId });
  }, [cookies.mid]);

  // Everytime location changes
  useEffect(() => {
    const pathname = location.pathname;
    Tracker.logPageView(pathname);
  }, [location.pathname]);

  useEffect(() => {
    if (token) {
      Tracker.setUser(userId);
    } else {
      Tracker.setUser();
    }
  }, [token]);

  useEffect(() => {
    if (!hasConnection) {
      Tracker.logEvent("error", "no-connection");
    }
  }, [hasConnection]);

  const showModal = !hasConnection || hasMaintenance;
  let modalMessage;
  let modalAction;
  if (showModal) {
    if (!hasConnection) {
      modalMessage = "network-error";
      modalAction = () => setHasConnection(true);
    } else {
      modalMessage = "maintenance";
      modalAction = () => setHasMaintenance(false);
    }
  }

  const pages = requiredTos ? <TermsOfServicePage /> : <AppRoutes />;
  const footer = location === "/fulltest" ? <FullTestFooter /> : <Footer />;

  if (isLoadingUserData) {
    return null;
  }

  return (
    <div className="body-container-wrapper">
      <div className="body-container">
        <Header className="header-container" />
        {pages}
        {footer}
      </div>
      <LoginMethodsModal />
      <Modal show={showModal}>
        <Modal.Body>
          {modalMessage && <Translated translationKey={modalMessage} />}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={modalAction}>
            <Translated translationKey="close" />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default App;
