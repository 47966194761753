import { useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import classNames from "classnames";

import TextInput from "../TextInput";
import EmailUtil from "../../../utils/EmailUtil";
import Translated from "../Translated";
import { useTranslation } from "../TranslationProvider";

const CustomerInput = (props) => {
  const { translate } = useTranslation();
  const [email, setEmail] = useState(props.data?.email || "");
  const [name, setName] = useState(props.data?.name || "");
  const [phone, setPhone] = useState(props.data?.phone || "");
  const [error, setError] = useState(null);

  const areInputsValid = () => {
    return EmailUtil.isEmail(email.trim()) && name.trim();
  };

  const setInput = () => {
    if (areInputsValid()) {
      setError(null);
      const trimmed = {
        email: email.trim(),
        name: name.trim(),
        phone: phone.trim(),
      };
      props.setInput(trimmed);
    } else {
      setError("invalid-input");
    }
  };

  const renderError = () => {
    if (!error) {
      return null;
    }
    return (
      <div className="top-margin">
        <Alert>
          <Translated translationKey={error} />
        </Alert>
      </div>
    );
  };

  const emailText = translate("email_address");
  const nameText = translate("name");
  const phoneText = translate("phone_number");
  const classes = classNames(props.className);

  return (
    <div className={classes}>
      <h3>
        <Translated translationKey="purchaser_contact_details" />
      </h3>
      <div className="blue-inner-box tight">
        <TextInput
          listener={(name) => setName(name)}
          label={nameText}
          placeholder={nameText}
          value={name}
          className="long-input-field"
        />

        <TextInput
          listener={(phone) => setPhone(phone)}
          label={phoneText}
          placeholder={phoneText}
          value={phone}
          className="long-input-field"
        />

        <TextInput
          listener={(email) => setEmail(email)}
          type="email"
          label={emailText}
          placeholder={emailText}
          value={email}
          className="long-input-field"
        />

        <Button onClick={setInput}>
          <Translated translationKey="next" />
        </Button>
        {renderError()}
      </div>
    </div>
  );
};

export default CustomerInput;
