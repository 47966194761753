import classNames from "classnames";
import { useTranslation } from "../TranslationProvider";

const FullTestButton = (props) => {
  const { translate } = useTranslation();
  
  const classNameProps = {
    selected: props.selected,
    disabled: props.disabled,
  };
  const classes = classNames(
    "button uppercase",
    classNameProps,
    props.className
  );
  const clickHandler = props.disabled ? null : props.onClick;
  return (
    <div id={props.id} className={classes} onClick={clickHandler}>
      {translate(props.label)}
      {props.children}
    </div>
  );
};

export default FullTestButton;
