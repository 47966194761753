import { FaCircle, FaRegCircle } from "react-icons/fa";
import PropTypes from "prop-types";
import classnames from "classnames";

import Translated from "./Translated";

const OptionsMenu = (props) => {
  const createItems = () => {
    const items = [];
    props.itemData.forEach((item, index) => {
      let id = item.id;
      let locId = item.localizationId;

      const isSelected = index === props.selectedIndex;

      items.push(
        <div
          key={id}
          className="vertical-middle touchable"
          onClick={() => {
            props.onSelect(index, id);
          }}
        >
          {isSelected ? (
            <FaCircle className="right-margin" aria-hidden="true" />
          ) : (
            <FaRegCircle className="right-margin" aria-hidden="true" />
          )}
          <div id={id}>
            {isSelected ? (
              <strong>
                <Translated translationKey={locId} />
              </strong>
            ) : (
              <Translated translationKey={locId} />
            )}
          </div>
        </div>
      );
    });
    return items;
  };

  const classes = classnames("options-menu", props.className);
  const items = createItems();

  return <div className={classes}>{items}</div>;
};

OptionsMenu.propTypes = {
  selectedIndex: PropTypes.number.isRequired,
  itemData: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default OptionsMenu;
