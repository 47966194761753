import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Popover from "react-bootstrap/Popover";
import { Button } from "react-bootstrap";
import classNames from "classnames";
import { FiBook } from "react-icons/fi";
import Overlay from "react-bootstrap/Overlay";

import BookContentModal from "./BookContentModal";
import { Roles, fillsRole } from "../../data/roles";
import { useUserData } from "../../context/UserDataContext";
import { useBookStatuses } from "../../api-new";
import Translated from "./Translated";
import { useTranslation } from "./TranslationProvider";

const BookModalLink = (props) => {
  const { lang } = useTranslation();
  const { token, userId, role } = useUserData();
  const [isRead, setIsRead] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const buttonRef = useRef(null);
  const allowPopover = useRef(true);
  const booksStatusQuery = useBookStatuses(userId, props.lang || lang);

  useEffect(() => {
    if (booksStatusQuery.data) {
      const { isRead, showPopover } = getIsRead();

      setIsRead(isRead);
      setShowPopover(showPopover);
    }
  }, [booksStatusQuery.data]);

  const getIsRead = () => {
    const content = props.content;
    const contentId = content ? content.id : props.contentId;
    let isRead = true;
    let showPopover = false;
    const bookStatus = getBookStatus();
    if (bookStatus) {
      const chapterId = content ? content.chapterId : props.chapterId;
      isRead = bookStatus.readContent.some(
        (readContentId) =>
          readContentId === contentId || readContentId === chapterId
      );

      if (
        bookStatus.progress === 0 &&
        allowPopover.current &&
        role === Roles.STUDENT
      ) {
        showPopover = true;
        allowPopover.current = false;
      }
    }
    return { isRead, showPopover };
  };

  const onHide = () => {
    setShowPopover(false);
  };

  const shorten = (text) => {
    text = text.trim();
    const words = text.split(" ");
    let result = words[0];
    for (let i = 1; i < words.length; i++) {
      const word = words[i];
      const currentLength = result.length;
      const nextLength = currentLength + word.length + 1;
      if (currentLength > 15 || nextLength > 22) {
        break;
      }
      result += " " + word;
    }
    if (text !== result) {
      result += "...";
    }
    return result;
  };

  const getBookStatus = () => {
    const content = props.content;
    const materialId = content ? content.materialId : props.materialId;
    if (booksStatusQuery.data && materialId) {
      return booksStatusQuery.data.find(
        (status) => status.bookId === materialId
      );
    }
    return null;
  };

  const onButtonClick = () => {
    setShowModal(true);
  };

  let modal = null;
  const content = props.content;
  const contentId = content ? content.id : props.contentId;
  if (showModal) {
    modal = (
      <BookContentModal
        contentId={contentId}
        content={content}
        onClose={() => setShowModal(false)}
        bookStatus={getBookStatus()}
        showId={props.showId}
        showStatus={role === Roles.STUDENT}
        token={token}
        lang={props.lang}
      />
    );
  }

  let title;
  if (props.title) {
    title = <span>{props.title}</span>;
  } else if (props.showId) {
    title = <span>{contentId}</span>;
  } else {
    if (content && content.title) {
      title = <span>{shorten(content.title)}</span>;
    } else {
      title = <Translated translationKey="more_info" />;
    }
  }

  const style =
    isRead || fillsRole(props.role, Roles.TEACHER) ? "link" : "warning";

  let popover;
  if (showPopover && buttonRef.current) {
    popover = (
      <Overlay
        show={showPopover}
        target={buttonRef.current}
        placement="bottom"
        onHide={onHide}
        rootClose
      >
        <Popover id="book-link-popover">
          <Popover.Body>
            <Translated translationKey="unread_book_section" />
          </Popover.Body>
        </Popover>
      </Overlay>
    );
  }

  return (
    <div className="book-modal-link book-link">
      <Button
        bsstyle={style}
        onClick={onButtonClick}
        ref={buttonRef}
        className={classNames({ floatY: !isRead && props.animate })}
      >
        <FiBook className="larger text-bottom" />
        {title}
      </Button>
      {modal}
      {popover}
    </div>
  );
};

BookModalLink.propTypes = {
  title: PropTypes.string,
  contentId: PropTypes.string,
  materialId: PropTypes.string,
};

export default BookModalLink;
