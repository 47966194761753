import Alert from "react-bootstrap/Alert";
import { IoAlertOutline } from "react-icons/io5";

import ImageSet from "../ImageSet";
import Translated from "../Translated";
import classNames from "classnames";
import Spinner from "../Spinner";
import { useSubcategories } from "../../../api-new";

const ShopCategorySelector = (props) => {
  const subcategoriesQuery = useSubcategories("teaching_permit");

  if (subcategoriesQuery.isPending) {
    return <Spinner />;
  } else if (subcategoriesQuery.isError) {
    return (
      <Alert>
        <IoAlertOutline />{" "}
        <Translated translationKey={subcategoriesQuery.error.message} />
      </Alert>
    );
  }

  const categories = subcategoriesQuery.data.subCategories;
  const elements = categories.map((category) => {
    const imageAltText = "License category icon " + category;
    const imageSrc =
      "../../assets/category/" + category.toLowerCase() + "_white.png";
    return (
      <div
        className="shop-category-selector-item touchable"
        onClick={() => props.onSelect(category)}
        key={category}
      >
        <div className="heavy-bump-once">
          <ImageSet src={imageSrc} alt={imageAltText} />
          <div className="title">
            <Translated translationKey={"category_" + category} />
          </div>
        </div>
      </div>
    );
  });
  const useNarrow = categories.length % 2 === 0;
  const classes = classNames("shop-category-selector-items fade-in", {
    narrow: useNarrow,
  });
  return <div className={classes}>{elements}</div>;
};

export default ShopCategorySelector;
