import ProfileStudent from "../feature/ProfileStudent";
import UserStatusLoaderWrapper from "../feature/UserStatusLoaderWrapper";
import UserResultsPreview from "../feature/UserResultsPreview";
import UserCategoryStatus from "../feature/UserCategoryStatus";
import CategorySelector from "./../feature/CategorySelector";
import ActiveBookStatus from "../feature/ActiveBookStatus";
import StartTrainingButton from "../feature/StartTrainingButton";
import News from "../feature/News";
import ResetVehicleCategoryButton from "../feature/ResetVehicleCategoryButton";
import { useUserData } from "../../context/UserDataContext";

const MeStudentPage = ({ allowFacebook = false }) => {
  const { userSettings } = useUserData();
  const renderCategoryStatus = () => {
    const selectedCategory = userSettings.vehicleCategory;
    if (selectedCategory === null) {
      return <CategorySelector />;
    }

    return (
      <div>
        <UserStatusLoaderWrapper category={selectedCategory}>
          <UserCategoryStatus className="fade-in delay">
            <StartTrainingButton />
            <ResetVehicleCategoryButton bsstyle="link" />
          </UserCategoryStatus>
        </UserStatusLoaderWrapper>
        <ActiveBookStatus className="fade-in delay" />
        <UserStatusLoaderWrapper>
          <UserResultsPreview className="fade-in long-delay" />
        </UserStatusLoaderWrapper>
      </div>
    );
  };

  return (
    <div id="me-student-page" className="page-container no-page-gutter">
      <div className="user-profile-bg fade-in">
        <ProfileStudent
          showClosingDate={true}
          allowFacebook={allowFacebook}
          className="user-profile-wrapper max-width-container page-gutter"
        />
      </div>
      <div className="max-width-container page-gutter">
        <News />
        {renderCategoryStatus()}
      </div>
    </div>
  );
};

export default MeStudentPage;
