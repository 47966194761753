import Spinner from "./Spinner";
import Translated from "./Translated";
import classNames from "classnames";
import DateUtil from "../../utils/date";
import { useUserData } from "../../context/UserDataContext";
import { useUserProfile } from "../../api-new";

const ProfileStudent = ({
  showClosingDate,
  className,
}) => {
  const { userId } = useUserData();
  const profileQuery = useUserProfile(userId);

  const renderUserImage = () => {
    const userImageUrl = profile.facebookUser
      ? profile.facebookUser.pictureUrl
      : null;

    if (!userImageUrl) {
      return null;
    }
    return (
      <div className="user-profile-image-wrapper">
        <img src={userImageUrl} className="user-profile-image fade-in" />
      </div>
    );
  };

  const renderClosingDate = () => {
    if (!showClosingDate) {
      return null;
    }

    const closingDate = profile.userData.closingDate;
    if (!closingDate) {
      return null;
    }

    return (
      <div className="user-profile-usage-valid">
        <Translated
          translationKey="license_valid"
          values={{
            valid_until: DateUtil.date(profile.userData.closingDate),
          }}
        />
      </div>
    );
  };

  if (profileQuery.isPending) {
    return <Spinner className="padded primary" />;
  }

  if (profileQuery.isError) {
    return (
      <div className="white padded">
        <Translated translationKey={profileQuery.error.message} />
      </div>
    );
  }

  const profile = profileQuery.data;
  const classes = classNames(className, "user-profile-container", "fade-in");

  return (
    <div id="profile-student-container" className={classes}>
      {renderUserImage()}
      <div className="user-profile-data">
        <h2 className="uppercase">{profile.userData.name}</h2>
        <h3>{profile.userData.schoolName}</h3>
        {renderClosingDate()}
      </div>
    </div>
  );
};

export default ProfileStudent;
