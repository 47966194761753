import { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

import TextInput from "../TextInput";
import EmailUtil from "../../../utils/EmailUtil";
import Translated from "../Translated";
import DateUtil from "../../../utils/date";
import Spinner from "../Spinner";
import { useVerifyEmail } from "../../../api-new";
import { useTranslation } from "../TranslationProvider";

const ProductInputEmailCredential = (props) => {
  const { translate } = useTranslation();
  const [inputs, setInputs] = useState({
    email: "",
    emailAgain: "",
    name: "",
  });
  const [userData, setUserData] = useState(null);
  const [isVerified, setIsVerified] = useState(false);
  const [error, setError] = useState(null);
  const verifyEmailQuery = useVerifyEmail(inputs.email);

  useEffect(() => {
    if (areInputsValid()) {
      verifyEmail();
    } else {
      props.addComponentInput(props.component.id, null);
      setUserData(null);
      setIsVerified(false);
    }
  }, [inputs]);

  const areInputsValid = () => {
    let error;
    if (!inputs.name.trim()) {
      error = "invalid-input";
    } else if (!EmailUtil.isEmail(inputs.email.trim())) {
      error = "invalid_email_form";
    } else if (
      inputs.email.trim().toLowerCase() !==
      inputs.emailAgain.trim().toLowerCase()
    ) {
      error = "email-mismatch";
    }
    if (error) {
      setError(error);
    }
    return !error;
  };

  const verifyEmail = async () => {
    const { data, isError } = await verifyEmailQuery.refetch();
    if (isError) {
      setError(data.error.message);
      return;
    }

    if (data.email !== inputs.email) {
      setInputs((prev) => ({
        ...prev,
        email: data.email,
        emailAgain: data.email,
      }));
      return;
    }

    let isVerified = true;
    if (data.userData) {
      isVerified = data.userData.schoolId === "teachingpermit";
      setUserData(data.userData);
      setError(null);
    } else {
      setError(null);
    }

    setIsVerified(isVerified);
    if (isVerified) {
      props.addComponentInput(props.component.id, {
        email: inputs.email.trim(),
        name: inputs.name.trim(),
      });
    }
  };

  const updateInputValue = (prop, value) => {
    setInputs((prev) => ({ ...prev, [prop]: value }));
  };

  const renderComponent = (component) => {
    const emailText = translate("email_address");
    const emailAgainText = translate("email_address_again");
    const nameText = translate("name");

    return (
      <div key={component.id}>
        <h3>
          <Translated translationKey={component.title} />
        </h3>

        <div className="first-content bottom-margin">
          <Translated translationKey="email_credentials_info" />
        </div>

        <TextInput
          listener={(name) => updateInputValue("name", name)}
          label={nameText}
          placeholder={nameText}
          value={inputs.name}
          className="long-input-field"
        />

        <TextInput
          listener={(email) => {
            email = email.replace(/ /g, "");
            updateInputValue("email", email);
          }}
          type="email"
          label={emailText}
          placeholder={emailText}
          value={inputs.email}
          className="long-input-field"
        />

        <TextInput
          listener={(email) => {
            email = email.replace(/ /g, "");
            updateInputValue("emailAgain", email);
          }}
          type="email"
          label={emailAgainText}
          placeholder={emailAgainText}
          value={inputs.emailAgain}
          className="long-input-field"
        />
        {renderError()}
        {renderEmailVerification()}
      </div>
    );
  };

  const clearEmails = () => {
    const email = "";
    const emailAgain = "";
    setInputs((prev) => ({ ...prev, email, emailAgain }));

    setUserData(null);
    setIsVerified(false);
  };

  const renderError = () => {
    return (
      props.showErrors &&
      error && (
        <Alert>
          <Translated translationKey={error} />
        </Alert>
      )
    );
  };

  const renderEmailVerification = () => {
    if (verifyEmailQuery.isFetching) {
      return <Spinner />;
    }

    if (!userData || isVerified) {
      return null;
    }

    return (
      <Alert>
        <Translated
          translationKey="email_override_warning_shop"
          values={{
            email: inputs.email,
            userName: userData.userName,
            schoolName: userData.schoolName,
            createdDate: DateUtil.date(userData.createdDate),
          }}
        />
        <div className="top-margin">
          <Button bsstyle="info" onClick={() => setIsVerified(true)}>
            <Translated translationKey="continue" />
          </Button>

          <Button className="left-margin" onClick={clearEmails}>
            <Translated translationKey="cancel" />
          </Button>
        </div>
      </Alert>
    );
  };

  return <div>{renderComponent(props.component)}</div>;
};

export default ProductInputEmailCredential;
