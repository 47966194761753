import Card from "react-bootstrap/Card";
import classNames from "classnames";

import Spinner from "./Spinner";
import Translated from "./Translated";
import DateUtil from "../../utils/date";
import { useNews } from "../../api-new";
import { useTranslation } from "./TranslationProvider";

const News = (props) => {
  const { lang } = useTranslation();
  const newsQuery = useNews(lang);

  const renderLoader = () => {
    return (
      <div>
        <Spinner className="primary" />
      </div>
    );
  };

  const renderNewsItem = (item) => {
    return (
      <div key={item.id} className="news-item-wrapper top-margin">
        <div className="news-date">{DateUtil.date(item.date)}</div>
        <div className="news-content">{item.text}</div>
      </div>
    );
  };

  if (newsQuery.isPending) {
    return renderLoader();
  }

  if(newsQuery.isError) {
    return null;
  }

  const news = newsQuery.data;
  if (!news || news.length === 0) {
    return null;
  }

  const newsItems = news.map((item) => {
    return renderNewsItem(item);
  });

  let classes = classNames("semi-dark-inner-box", props.className);

  if (props.simple) {
    return (
      <div className={classes}>
        <h3 className="news-header">
          <Translated translationKey="news" />
        </h3>
        {newsItems}
      </div>
    );
  } else {
    return (
      <Card className={classes}>
        <h3 className="news-header">
          <Translated translationKey="news" />
        </h3>
        {newsItems}
      </Card>
    );
  }
};

export default News;
