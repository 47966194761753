import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import Translated from "../feature/Translated";
import Spinner from "./../feature/Spinner";
import DateUtil from "../../utils/date";
import { useUserData } from "../../context/UserDataContext";
import { useSchoolData, useUpdateSchoolData } from "../../api-new";
import { useTranslation } from "../feature/TranslationProvider";

const SchoolContactInfoPage = (props) => {
  const { schoolId } = useUserData();
  const { translate } = useTranslation();
  const [showOk, setShowOk] = useState(false);
  const [school, setSchool] = useState();
  const okTimeout = useRef(null);
  const schoolDataQuery = useSchoolData(schoolId);
  const schoolDataMutation = useUpdateSchoolData();

  useEffect(() => {
    return () => {
      if (okTimeout.current) {
        clearTimeout(okTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    if (schoolDataQuery.isSuccess) {
      setSchool(schoolDataQuery.data.school);
    }
  }, [schoolDataQuery.data]);

  const save = () => {
    schoolDataMutation.mutate(
      { schoolId, schoolData: school },
      {
        onSuccess: () => {
          setShowOk(true);
          okTimeout.current = setTimeout(() => setShowOk(false), 3000);
        },
      }
    );
  };

  const handleSchoolInputChange = (e) => {
    updateSchoolState(e.target.name, e.target.value);
  };

  const updateSchoolState = (field, value) => {
    setSchool((prev) => ({ ...prev, [field]: value }));
  };

  const makeFormGroup = (labelKey, fieldName, classes) => {
    const label = translate(labelKey);
    classes = classNames("settings-field", classes);
    return (
      <Form.Group className="mb-3">
        <Form.Label>{label}</Form.Label>
        <Form.Control
          className={classes}
          name={fieldName}
          value={school[fieldName] ?? ""}
          onChange={handleSchoolInputChange}
        />
      </Form.Group>
    );
  };

  const renderForm = () => {
    const errorMessage = schoolDataMutation.isError ? (
      <Alert bsstyle="danger">
        <Translated translationKey={schoolDataMutation.error.message} />
      </Alert>
    ) : null;

    const saveOkMessage = showOk ? (
      <Alert bsstyle="success">
        <Translated translationKey="ok" />
      </Alert>
    ) : null;

    const saveDisabled = schoolDataMutation.isPending;

    return (
      <div className="white-box">
        <form>
          <fieldset disabled={schoolDataMutation.isPending}>
            {makeFormGroup("street_address", "streetAddress")}
            {makeFormGroup(
              "zip_code",
              "postalNumber",
              "settings-limited-field"
            )}
            {makeFormGroup(
              "postal_location",
              "postalLocation",
              "settings-limited-field"
            )}
            {makeFormGroup(
              "phone_number",
              "phoneNumber",
              "settings-limited-field"
            )}
            {makeFormGroup("email", "email")}

            <h4 className="medium-top-margin">
              <Translated translationKey="invoice_details" />
            </h4>
            {makeFormGroup("invoice_receiver", "invoiceName")}
            {makeFormGroup("street_address", "invoiceStreetAddress")}
            {makeFormGroup(
              "zip_code",
              "invoicePostalNumber",
              "settings-limited-field"
            )}
            {makeFormGroup(
              "postal_location",
              "invoicePostalLocation",
              "settings-limited-field"
            )}
            {makeFormGroup("cost_center", "invoiceCostCenter")}

            <h4 className="medium-top-margin">
              <Translated translationKey="e_invoice_details" />
            </h4>
            {makeFormGroup("e_invoice_address", "eInvoiceAddress")}
            {makeFormGroup("e_invoice_operator_code", "eInvoiceOperatorCode")}

            <div className="centered top-margin">
              {errorMessage ? errorMessage : saveOkMessage}
              <Button
                id="school-settings-submit-button"
                disabled={saveDisabled}
                onClick={save}
              >
                {schoolDataMutation.isPending ? (
                  <Spinner />
                ) : (
                  <Translated translationKey="save" />
                )}
              </Button>
            </div>
          </fieldset>
        </form>
      </div>
    );
  };

  if (!school || schoolDataQuery.isPending) {
    return null;
  }

  const closingDateElem =
    school.closingDate && school.closingDate !== "" ? (
      <Translated
        translationKey="license_valid"
        values={{ valid_until: DateUtil.date(school.closingDate) }}
      />
    ) : null;

  return (
    <div
      id="school-settings-page"
      className={classNames(
        "page-container max-width-container fade-in",
        props.className
      )}
    >
      <h3 className="medium-top-margin">
        {school.name} (ID: {school.id.toUpperCase()})
      </h3>
      <div>
        <Translated translationKey="vat_id" />: {school.vatID}
      </div>
      {closingDateElem}
      {renderForm()}
    </div>
  );
};

export default SchoolContactInfoPage;
