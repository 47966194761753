import React from "react";
import { useNavigate } from "react-router-dom";
import { MdCopyright } from "react-icons/md";

import LanguageSelector from "../feature/LanguageSelector";
import Translated from "../feature/Translated";
import { useUserData } from "../../context/UserDataContext";

const Footer = (props) => {
  const { token } = useUserData();
  const navigate = useNavigate();

  let langSelector;
  if (token) {
    langSelector = (
      <div className="footer-item">
        <LanguageSelector
          dropdownLangs={props.dropdownLangs}
          drop="up"
          className="hide-xs"
        />
      </div>
    );
  }

  const companyName = COMPANY_NAME;
  // Note: Added year to footer, so that site feels more up to date.

  return (
    <div id="footer-container" className="footer-container">
      <div className="footer-item">
        <span className="touchable" onClick={() => navigate("/privacy-policy")}>
          {companyName} <MdCopyright />{" "}
          <Translated translationKey="dyn_year_inject" />
        </span>
      </div>
      {langSelector}
    </div>
  );
};

export default Footer;
