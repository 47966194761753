import classNames from "classnames";

import { useTranslation } from "./TranslationProvider";

const SubMenu = (props) => {
  const { translate } = useTranslation();

  const buildMenuItem = (id, labelKey, index) => {
    const label = translate(labelKey);
    const isActive = props.selectedIndex === index;

    return (
      <div
        className={classNames("sub_menu-item", { "active strong": isActive })}
        id={id}
        key={id}
        onClick={() => props.selectionListener(index)}
      >
        <span>{label}</span>
      </div>
    );
  };

  const menuItems = props.items.map((itemData, index) => {
    return buildMenuItem(itemData.pageId, itemData.localizationId, index);
  });

  return (
    <div id="sub_menu-container" className="sub_menu-container">
      {menuItems}
    </div>
  );
};

export default SubMenu;
