import Alert from "react-bootstrap/Alert";
import PropTypes from "prop-types";

import Translated from "../Translated";
import CategorySelector from "../CategorySelector";
import CategoryStart from "../CategoryStart";
import Spinner from "../Spinner";
import ResetVehicleCategoryButton from "../ResetVehicleCategoryButton";
import { useUserData } from "../../../context/UserDataContext";

const FullTestStart = ({ start, locale }) => {
  const { userSettings } = useUserData();

  const renderContent = () => {
    if (!userSettings.data) {
      return <Spinner />;
    }

    const category = userSettings.vehicleCategory;
    if (!category) {
      return <CategorySelector />;
    }

    const settingsData = userSettings.data;
    const categorySettings =
      settingsData.categorySettings[category.toLowerCase()];
    const isLanguageSupported = categorySettings.langs.indexOf(locale) !== -1;
    if (!isLanguageSupported) {
      return (
        <Alert>
          <Translated translationKey="category_unsupported_lang" />
        </Alert>
      );
    } else {
      return (
        <div id="full-test-start-container">
          <div>
            <h3>
              <Translated translationKey="full_test_title" />
            </h3>
            <Translated translationKey="full_test_description" />
          </div>
          <br />
          <CategoryStart
            id="full-test-start"
            category={category}
            start={start}
          />
          <ResetVehicleCategoryButton bsstyle="link" />
        </div>
      );
    }
  };

  return (
    <div id="full-test-start-page" className="max-width-container">
      {renderContent()}
    </div>
  );
};

FullTestStart.prototypes = {
  start: PropTypes.func.isRequired,
};

export default FullTestStart;
