const appendQuestions = (target, questions, type) => {
  if (!questions) {
    return;
  }
  questions.forEach((question) => {
    question.type = type;
    target.push(question);
  });
};

export function createFullTest(apiTestData) {
  {
    const questions = [];
    appendQuestions(questions, apiTestData.literalQuestions, "literal");
    appendQuestions(questions, apiTestData.signQuestions, "sign");
    appendQuestions(questions, apiTestData.riskQuestions, "risk");
    appendQuestions(questions, apiTestData.imageQuestions, "image");

    const { id, category } = apiTestData;
    return { id, category, questions };
  }
}

export function formatFulltestAnswers(questions, allAnswers) {
  const answers = {};
  // Group answers by question type
  questions.forEach((question) => {
    let answer = allAnswers[question.id];
    if (!answer) {
      answer = "d";
    }
    const prop = question.type + "Answers";
    if (!answers.hasOwnProperty(prop)) {
      answers[prop] = [];
    }
    answers[prop].push(answer);
  });

  return answers;
}
