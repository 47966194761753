import { useEffect } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { Roles, fillsRole } from "../data/roles";
import MePage from "./pages/MePage";
import LoginPage from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
import NotFoundPage from "./pages/NotFoundPage";
import ContactPage from "./pages/ContactPage";
import EditUserPage from "./pages/EditUserPage";
import FullTestPage from "./pages/FullTestPage";
import LoginTeacherPage from "./pages/LoginTeacherPage";
import LoginAAIntegrationPage from "./pages/LoginAAIntegrationPage";
import LoginAATeoriaIntegrationPage from "./pages/LoginAATeoriaIntegrationPage";
import LoginAutoliittoPage from "./pages/LoginAutoliittoPage";
import OrdersPage from "./pages/OrdersPage";
import PracticeLiteralsPage from "./pages/PracticeLiteralsPage";
import PracticeSignsPage from "./pages/PracticeSignsPage";
import PracticeImagesPage from "./pages/PracticeImagesPage";
import ResultPage from "./pages/ResultPage";
import ResultsPage from "./pages/ResultsPage";
import SubPagesContainer from "./pages/SubPagesContainer";
import BookPage from "./pages/BookPage";
import BookSimplePage from "./pages/BookSimplePage";
import BookSelectionPage from "./pages/BookSelectionPage";
import EmailRequestPage from "./pages/EmailRequestPage";
import EmailRequestRedirectPage from "./pages/EmailRequestRedirectPage";
import EmailRecoveryPage from "./pages/EmailRecoveryPage";
import UserSettingsPage from "./pages/UserSettingsPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import UserAddPage from "./pages/UserAddPage";
import UsersListPage from "./pages/UsersListPage";
import TeachersListPage from "./pages/TeachersListPage";
import UsersSearchPage from "./pages/UsersSearchPage";
import SchoolContactInfoPage from "./pages/SchoolContactInfoPage";
import SchoolLimitsPage from "./pages/SchoolLimitsPage";
import ShopFrontPage from "./pages/shop/ShopFrontPage";
import OrderStatusPage from "./pages/OrderStatusPage";
import ContactTrialPage from "./pages/ContactTrialPage";
import DemoImagesPage from "./pages/DemoImagesPage";
import TrackerAB from "../utils/trackerAB";
import TeachingPermitPage from "./pages/shop/TeachingPermitPage";
import TractorPage from "./pages/shop/TractorPage";
import MotorcyclePage from "./pages/shop/MotorcyclePage";
import MopedCarPage from "./pages/shop/MopedCarPage";
import ProductPage from "./pages/shop/ProductPage";
import MopedPage from "./pages/shop/MopedPage";
import TrailerPage from "./pages/shop/TrailerPage";
import LicenseAgreement from "./pages/LicenseAgreement";
import ShopDeliveryTermsPage from "./pages/shop/ShopDeliveryTermsPage";
import { useUserData } from "../context/UserDataContext";

const RequireLoginIndex = ({ children }) => {
  const { token } = useUserData();
  return token ? children : <HomePage />;
};

const RequireLogin = ({ children }) => {
  const { token } = useUserData();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!token) {
      return navigate("/login", { state: { redirect: location } });
    }
  }, [token, navigate, location]);

  return token ? children : null;
};

const RequireTeacherLogin = ({ children }) => {
  const { token, role } = useUserData();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!token || !fillsRole(role, Roles.TEACHER)) {
      return navigate("/login-teacher", { state: { redirect: location } });
    }
  }, [token, navigate, location]);

  return token ? children : null;
};

const AnonymousRoute = ({ children }) => {
  const path = null;
  TrackerAB.track("Anon path: " + path);
  return children;
};

const renderAnonymousRoute = (path, Component) => (
  <Route
    path={path}
    element={
      <AnonymousRoute>
        <Component />
      </AnonymousRoute>
    }
  />
);

const AppRoutes = () => {
  const renderIndexRoute = (path, Component) => (
    <Route
      path={path}
      element={
        <RequireLoginIndex>
          <Component />
        </RequireLoginIndex>
      }
    />
  );

  const renderRequireLoginRoute = (path, Component) => (
    <Route
      path={path}
      element={
        <RequireLogin>
          <Component />
        </RequireLogin>
      }
    />
  );

  const renderRequireTeacherLoginRoute = (path, Component, componentProps) => (
    <Route
      path={path}
      element={
        <RequireTeacherLogin>
          <Component {...componentProps} />
        </RequireTeacherLogin>
      }
    />
  );

  return (
    <Routes>
      {renderIndexRoute("/", MePage)}

      {renderAnonymousRoute("/login", LoginPage)}
      {renderAnonymousRoute("/contact", ContactPage)}
      {renderAnonymousRoute("/trial", ContactTrialPage)}

      {renderAnonymousRoute("/login-teacher", LoginTeacherPage)}
      {renderAnonymousRoute("/opettajat", LoginTeacherPage)}
      {renderAnonymousRoute("/opettaja", LoginTeacherPage)}
      {renderAnonymousRoute("/req", EmailRequestPage)}
      {renderAnonymousRoute("/req/:requestToken", EmailRequestRedirectPage)}
      {renderAnonymousRoute("/email-recovery", EmailRecoveryPage)}

      {renderAnonymousRoute("/kauppa", ShopFrontPage)}
      {renderAnonymousRoute("/opetuslupa", TeachingPermitPage)}
      {renderAnonymousRoute("/ajokorttikoulu", TeachingPermitPage)}
      {renderAnonymousRoute("/traktorikortti", TractorPage)}
      {renderAnonymousRoute("/moottoripyorakortti", MotorcyclePage)}
      {renderAnonymousRoute("/mopoautokortti", MopedCarPage)}
      {renderAnonymousRoute("/mopokortti", MopedPage)}
      {renderAnonymousRoute("/be-kortti-pikku-e", TrailerPage)}
      {renderAnonymousRoute("/tuoteinfo", ProductPage)}

      {renderAnonymousRoute("/liikennemerkit", PracticeSignsPage)}

      {renderAnonymousRoute("/order/:orderId/:token", OrderStatusPage)}
      {renderAnonymousRoute("/demo/images/:category", DemoImagesPage)}

      {renderAnonymousRoute("/login-aateoria", LoginAATeoriaIntegrationPage)}
      {renderAnonymousRoute("/login-aa", LoginAAIntegrationPage)}
      {renderAnonymousRoute("/aa/sessions", LoginAAIntegrationPage)}
      {renderAnonymousRoute("/login-autoliitto", LoginAutoliittoPage)}
      {renderAnonymousRoute("/autoliitto/sessions", LoginAutoliittoPage)}

      {renderRequireLoginRoute("/fulltest", FullTestPage)}
      {renderRequireLoginRoute("/literals", PracticeLiteralsPage)}
      {renderRequireLoginRoute("/me", MePage)}
      {renderRequireLoginRoute("/result/:id", ResultPage)}
      {renderRequireLoginRoute("/signs", PracticeSignsPage)}
      {renderRequireLoginRoute("/images", PracticeImagesPage)}
      {renderRequireLoginRoute("/results", ResultsPage)}
      {renderRequireLoginRoute("/book", BookPage)}
      {renderRequireLoginRoute("/book/:chapterId", BookPage)}
      {renderRequireLoginRoute("/settings", UserSettingsPage)}

      {renderRequireTeacherLoginRoute(
        "/users",
        SubPagesContainer,
        subMenuConfigs.users
      )}
      {renderRequireTeacherLoginRoute("/users/orders", OrdersPage)}
      {renderRequireTeacherLoginRoute(
        "/users/:subPage",
        SubPagesContainer,
        subMenuConfigs.users
      )}
      {renderRequireTeacherLoginRoute("/user/:userId", EditUserPage)}
      {renderRequireTeacherLoginRoute("/orders", OrdersPage)}
      {renderRequireTeacherLoginRoute(
        "/school-settings",
        SubPagesContainer,
        subMenuConfigs.schoolSettings
      )}
      {renderRequireTeacherLoginRoute(
        "/school-settings/:subPage",
        SubPagesContainer,
        subMenuConfigs.schoolSettings
      )}
      {renderRequireTeacherLoginRoute("/results/:userId", ResultsPage)}
      {renderRequireTeacherLoginRoute("/books", BookSelectionPage)}
      {renderRequireTeacherLoginRoute(
        "/books/:bookId/:language/:chapterId",
        BookSimplePage
      )}
      {renderRequireTeacherLoginRoute(
        "/books/:bookId/:language/",
        BookSimplePage
      )}

      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/cancelling-terms" element={<ShopDeliveryTermsPage />} />
      <Route path="/license-agreement" element={<LicenseAgreement />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

const subMenuConfigs = {
  users: {
    containerId: "teacher-users",
    baseUrl: "users/",
    subPageData: [
      {
        pageId: "search",
        localizationId: "search",
        component: UsersSearchPage,
      },
      { pageId: "add", localizationId: "add", component: UserAddPage },
      {
        pageId: "list",
        localizationId: "list_users",
        component: UsersListPage,
      },
      {
        pageId: "teachers",
        localizationId: "teachers",
        component: TeachersListPage,
      },
    ],
  },
  schoolSettings: {
    containerId: "teacher-school-settings",
    baseUrl: "school-settings/",
    subPageData: [
      {
        pageId: "contact",
        localizationId: "school_contact_info",
        component: SchoolContactInfoPage,
      },
      {
        pageId: "limits",
        localizationId: "practise_settings",
        component: SchoolLimitsPage,
      },
    ],
  },
};

export default AppRoutes;
