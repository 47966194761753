import { useNavigate } from "react-router-dom";
import Translated from "../Translated";

const OtherProductCategories = () => {
  const navigate = useNavigate();

  return (
    <div>
      <a
        className="touchable"
        onClick={() => {
          navigate("/kauppa");
        }}
      >
        <Translated translationKey="other_categories" />
      </a>
    </div>
  );
};
export default OtherProductCategories;
