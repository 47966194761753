import { createContext, useContext, useEffect, useState } from "react";
import queryString from "query-string";

import PreloadPage from "../pages/PreloadPage";
import { useLocalizations } from "../../api-new";
import { DEFAULT_LOCALE, replaceSecrets } from "../../utils/translationUtil";
import LocalStorageUtil from "../../utils/localStorageUtil";
import { availableLangsData } from "../../data/availableLangsData";

export const TranslationContext = createContext({
  lang: null,
  availableLangs: null,
  translate: (key, placeholders, options) => {},
  switchLanguage: (lang) => {},
});

export const useTranslation = () => useContext(TranslationContext);

const TranslationProvider = ({ injectedLocalizations, children }) => {
  const [lang, setLang] = useState(null);
  const translationQuery = useLocalizations(lang);
  const [translations, setTranslations] = useState(null);

  useEffect(() => {
    const queryLang = queryString.parse(window.location.search).lang;
    if (queryLang) {
      setLang(queryLang);
      return;
    }

    const storedLang = LocalStorageUtil.getPersistedLocaleCode();
    if (storedLang) {
      setLang(storedLang);
      return;
    }

    setLang(DEFAULT_LOCALE);
  }, []);

  useEffect(() => {
    if (translationQuery.isSuccess) {
      const queryTranslations = translationQuery.data;
      let translations = replaceSecrets(queryTranslations);
      setTranslations({ ...translations, ...injectedLocalizations?.[lang] });
    }
  }, [translationQuery.data]);

  const switchLanguage = (lang) => {
    LocalStorageUtil.setPersistedLocaleCode(lang);
    setLang(lang);
  };

  /**
   * Translate key if it is wrapped into {}, otherwise return the key
   * @param possibleKey
   * @param placeholders
   */
  const translateIfKey = (possibleKey, placeholders) => {
    if (
      possibleKey &&
      possibleKey.indexOf("{") === 0 &&
      possibleKey.indexOf("}") === possibleKey.length - 1
    ) {
      const key = possibleKey.substr(1, possibleKey.length - 2);
      return translate(key, placeholders);
    }
    return possibleKey;
  };

  /**
   * Translate the key to current locale and replace placeholders
   * @param key
   * @param placeholders optional, Object with key value pairs.
   * @param options optional {onlyIfKey: true}
   * @returns {*}
   */
  const translate = (key, placeholders, options) => {
    if (options) {
      if (options.onlyIfKey) {
        return translateIfKey(key, placeholders);
      }
    }

    let result = key;

    if (translations && translations[key]) {
      result = translations[key];
    }

    if (!placeholders) {
      return result;
    }

    // New placeholders with {}
    for (let p in placeholders) {
      if (placeholders.hasOwnProperty(p)) {
        result = result.replace(
          new RegExp("{" + p + "}", "g"),
          placeholders[p]
        );
      }
    }

    // Legacy placeholders with %%
    for (let p in placeholders) {
      if (placeholders.hasOwnProperty(p)) {
        result = result.replace(
          new RegExp("%" + p + "%", "g"),
          placeholders[p]
        );
      }
    }

    return result;
  };

  if (translationQuery.isPending) {
    return <PreloadPage />;
  }

  if (translationQuery.isError) {
    const errorMessage = translationQuery.error.message;
    const info =
      errorMessage === "no-database"
        ? "Huoltopäivitys, odota hetki. Service is being updated, please wait."
        : translationQuery.error.message;

    return <PreloadPage info={info} />;
  }

  const contextValue = {
    translate,
    lang,
    switchLanguage,
    availableLangs: availableLangsData,
  };

  return (
    <TranslationContext.Provider value={contextValue}>
      {children}
    </TranslationContext.Provider>
  );
};

export default TranslationProvider;
