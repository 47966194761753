import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import Translated from "./../feature/Translated";
import LocalStorageUtil from "../../utils/localStorageUtil";
import { Roles } from "../../data/roles";
import { useUserData } from "../../context/UserDataContext";

const LoginMethodsModal = () => {
  const { token, email, integrationPlatform, role, userId } = useUserData();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  useEffect(() => {
    const resolveStatus = () => {
      if (!token || email || integrationPlatform || role !== Roles.STUDENT) {
        return false;
      }

      let status = LocalStorageUtil.getForUser(userId, "login-methods-modal");
      if (!status) {
        status = {
          count: 0,
          prev: 0,
        };
      }

      const interval = 12 * 60 * 60 * 1000;
      const now = Date.now();

      if (status.count < 2 && now - status.prev > interval) {
        status.count++;
        status.prev = now;
        LocalStorageUtil.setForUser(userId, "login-methods-modal", status);
        return true;
      }

      return false;
    };

    setShow(resolveStatus);
  }, [token]);

  const close = () => {
    setShow(false);
  };

  const openSettings = () => {
    close();
    navigate("/settings");
  };

  if (!show) {
    return null;
  }

  return (
    <Modal show={true} className="centered">
      <Modal.Header>
        <Modal.Title>
          <Translated translationKey="login_methods_modal_title" />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Translated translationKey="login_methods_modal_body" />
      </Modal.Body>

      <Modal.Footer className="centered">
        <Button bsstyle="link" onClick={close}>
          <Translated translationKey="close" />
        </Button>
        <Button onClick={openSettings}>
          <Translated translationKey="view" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LoginMethodsModal;
