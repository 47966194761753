import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { IoMenu } from "react-icons/io5";
import Collapse from "react-bootstrap/Collapse";
import classNames from "classnames";

import NavigationPanel from "./../feature/NavigationPanel";
import ImageSet from "./ImageSet";
import MeMenu from "./MeMenu";
import { Roles, fillsRole } from "../../data/roles";
import LanguageSelector from "../feature/LanguageSelector";
import SettingsUtil from "../../utils/settingsUtil";
import { useUserData } from "../../context/UserDataContext";
import navigationData from "../../data/navigationData";
import { useTranslation } from "./TranslationProvider";

const Header = () => {
  const { userSettings, token, role, schoolName, logout } = useUserData();
  const { translate } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [navigation, setNavigation] = useState({
    isActive: false,
    data: navigationData,
  });

  const closeNavigation = () => {
    setNavigation((prev) => ({ ...prev, isActive: false }));
  };

  const toggleFullMenu = () => {
    setNavigation((prev) => ({ ...prev, isActive: !prev.isActive }));
  };

  const navigationListener = (eventId) => {
    switch (eventId) {
      case "logout":
        closeNavigation();
        logout(navigate);
        break;

      default:
        closeNavigation();
        navigate("/" + eventId);
        break;
    }
  };

  const buildHeaderNavItem = (id, labelKey) => {
    const label = translate(labelKey);
    const isActive = location.pathname.indexOf("/" + id) === 0;

    return (
      <div
        className={classNames("header-navi-item", { active: isActive })}
        id={id}
        key={id}
        onClick={() => navigationListener(id)}
      >
        <span>{label}</span>
      </div>
    );
  };

  const renderHeaderNavigation = () => {
    // Horizontal non-toggleable menu.
    // Header navigation is visible only on large screens.
    const data = navigation.data;

    if (!data) {
      // No data yet, render nothing
      return null;
    }

    const isTeacher = fillsRole(role, Roles.TEACHER);

    // Resolve which data to use
    let resolvedData;
    if (!token) {
      // Anonymous front content
      resolvedData = data.anonymousHeader;
    } else {
      if (!userSettings.data) {
        resolvedData = null;
      } else {
        resolvedData = isTeacher ? data.teacherHeader : data.studentHeader;
      }
    }

    let navItems = null;
    if (resolvedData) {
      // Remove 'book' from navigation if no books available or user is teacher.
      if (isTeacher || !SettingsUtil.hasBookForCurrentCategory(userSettings)) {
        resolvedData = resolvedData.filter((item) => item.id !== "book");
      }

      // Remove 'books' item from navigation if user has no licensed books (on any language)
      if (!SettingsUtil.hasLicensedBooks(userSettings)) {
        resolvedData = resolvedData.filter((item) => item.id !== "books");
      }

      // Build each item
      navItems = resolvedData.map((item) =>
        buildHeaderNavItem(item.id, item.titleKey)
      );
    }

    // Wrap navigation, it is visible only on large screens
    return <div className="header-navi hide-xs">{navItems}</div>;
  };

  const renderMobileNavigationPanel = () => {
    // Mobile navigation panel opens below header, mainly from the mobile burger menu button
    // It is not visible on large screens.
    const data = navigation.data;
    if (!data) {
      return null;
    }

    const isTeacher = fillsRole(role, Roles.TEACHER);

    let resolvedData;
    if (!token) {
      resolvedData = data.anonymousNavigation;
    } else {
      resolvedData = isTeacher
        ? data.teacherNavigation
        : data.studentNavigation;
    }

    if (
      isTeacher ||
      (!SettingsUtil.hasBookForCurrentCategory(userSettings) && resolvedData)
    ) {
      // Filter book menu out if user does not have a license for it
      resolvedData = resolvedData.filter((item) => item.id !== "book");
    }

    if (!SettingsUtil.hasLicensedBooks(userSettings)) {
      // Remove 'books' item from navigation if user has no licensed books (on any language)
      resolvedData = resolvedData.filter((item) => item.id !== "books");
    }

    return (
      <Collapse
        in={navigation.isActive}
        className="hide-sm-and-gt full-navigation"
      >
        <div>
          <NavigationPanel
            data={resolvedData}
            listener={navigationListener}
            languageSelector={true}
            closeNavigation={closeNavigation}
          />
        </div>
      </Collapse>
    );
  };

  const renderHeaderMenu = () => {
    // Renders burger toggle button menu when on small screen
    // and otherwise MeMenu or Language selector (depending on logged in status)

    let resolvedMeMenuData;
    const data = navigation.data;
    if (data && userSettings) {
      resolvedMeMenuData = fillsRole(role, Roles.TEACHER)
        ? data.teacherMeMenu
        : data.studentMeMenu;
      if (
        !SettingsUtil.hasBookForCurrentCategory(userSettings) &&
        resolvedMeMenuData
      ) {
        // Filter book menu out if user does not have a license for it
        resolvedMeMenuData = resolvedMeMenuData.filter(
          (item) => item.id !== "book"
        );
      }
    } else {
      resolvedMeMenuData = [];
    }
    // Language selector is at header when user is anonymous (at front) and in footer when logged in.
    const largeScreenMenu = token ? (
      <MeMenu menuData={resolvedMeMenuData} closeNavigation={closeNavigation} />
    ) : (
      <LanguageSelector />
    );

    const mobileBurgerMenu = (
      <Button
        aria-label="Main menu"
        role="presentation"
        onClick={toggleFullMenu}
      >
        <IoMenu />
      </Button>
    );

    return (
      <div className="header-menu">
        <div className="hide-xs">{largeScreenMenu}</div>
        <div className="hide-sm-and-gt">{mobileBurgerMenu}</div>
      </div>
    );
  };

  const renderLogo = () => {
    const logo = SERVICE_LOGO;
    const logoUrl = "../../assets/" + logo + "-small.png";
    // Logo is visible everywhere but on front page.
    const isOnHomePage = location.pathname === "/" && token === false;

    return isOnHomePage ? null : (
      <div className="header-logo">
        <ImageSet
          src={logoUrl}
          className="fade-in"
          onClick={() => navigationListener("")}
          alt="Home logo"
        />
      </div>
    );
  };

  const renderProfileSchool = () => {
    if (!fillsRole(role, Roles.TEACHER)) {
      return null;
    }
    return (
      <div className="header-content page-gutter header-school">
        {schoolName}
      </div>
    );
  };

  return (
    <div id="header-container" className="header-container">
      <div className="header-content page-gutter">
        {renderLogo()}
        {renderHeaderNavigation()}
        {renderHeaderMenu()}
      </div>
      {renderMobileNavigationPanel()}
      <div className="break" />
      {renderProfileSchool()}
    </div>
  );
};

export default Header;
