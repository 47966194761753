import { useEffect } from "react";
import { useSendBookContentStatus } from "../../api-new";

const DELAY_SHORT_S = 5;
const DELAY_LONG_S = 60;

const BookReadStatusMarker = (props) => {
  const contentMutation = useSendBookContentStatus();
  useEffect(() => {
    const markAsRead = (chapterIdPostfix) => {
      const id = props.chapter.id + "-simple-" + chapterIdPostfix;
      contentMutation.mutate({
        materialContentId: id,
        materialId: props.chapter.materialId,
      });
    };
    let timeoutShort = setTimeout(
      () => markAsRead(DELAY_SHORT_S),
      DELAY_SHORT_S * 1000
    );
    let timeoutLong = setTimeout(
      () => markAsRead(DELAY_LONG_S),
      DELAY_LONG_S * 1000
    );

    return () => {
      clearTimeout(timeoutShort);
      clearTimeout(timeoutLong);
    };
  }, []);
};

export default BookReadStatusMarker;
