import { useParams } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import classNames from "classnames";

import Spinner from "../feature/Spinner";
import Translated from "../feature/Translated";
import TeacherList from "../feature/TeacherList";
import UserCreate from "../feature/UserCreate";
import { Roles } from "../../data/roles";
import { useUserData } from "../../context/UserDataContext";
import { useListTeachers } from "../../api-new";

const TeachersListPage = (props) => {
  const { token, schoolId: schoolIdToken } = useUserData();
  const params = useParams();
  const schoolId = params.schoolId || schoolIdToken;
  const teachersQuery = useListTeachers(schoolId);

  const renderUserList = () => {
    if (teachersQuery.isPending) {
      return <Spinner className="primary" />;
    }

    if (!teachersQuery.isSuccess) {
      return null;
    }

    return (
      <div>
        <TeacherList
          users={teachersQuery.data}
          noUsersTranslationKey="no_search_results"
          className="fade-in"
        />
      </div>
    );
  };

  if (!token) {
    return null;
  }

  const classes = classNames(
    "page-container max-width-container fade-in",
    props.className
  );
  return (
    <div id="user-list-page" className={classes}>
      <h3>
        <Translated translationKey="add_teacher" />
      </h3>
      <Translated
        translationKey="help_add_teacher"
        className="top-margin medium-bottom-margin strong"
      />
      {teachersQuery.isError ? (
        <Alert>
          <Translated translationKey={teachersQuery.error.message} />
        </Alert>
      ) : null}
      <UserCreate role={Roles.TEACHER} schoolId={schoolId} />
      <h3>
        <Translated translationKey="teachers" />
      </h3>
      {renderUserList()}
    </div>
  );
};

export default TeachersListPage;
