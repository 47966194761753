import { availableLangsData } from "../data/availableLangsData";

export const DEFAULT_LOCALE = BUILD_DEFAULT_LOCALE;

export const replaceSecrets = (translations) => {
  Object.keys(translations).forEach((key) => {
    const value = translations[key];
    translations[key] = value.replace("{admin_email}", atob(ADMIN_EMAIL)); // Base64 decode
  });
  return translations;
};

export const validateLocale = (locale) => {
  locale = locale.toLowerCase();
  return availableLangsData.hasOwnProperty(locale) ? locale : DEFAULT_LOCALE;
};
