import classNames from 'classnames';
import PractiseLimits from '../feature/PractiseLimits';
import AutoCloseSettings from '../feature/AutoCloseSettings';

const SchoolLimitsPage = props => (
  <div id="school-limits-page" className={classNames('page-container max-width-container fade-in', props.className)}>
    <AutoCloseSettings/>
    <PractiseLimits/>
  </div>
);

export default SchoolLimitsPage;

