import { useProducts } from "../api-new";
import { useTranslation } from "../components/feature/TranslationProvider";

const useProductLoader = (productsCategory, subCategory) => {
  const { lang } = useTranslation();
  const productsQuery = useProducts(productsCategory, subCategory, lang);

  return {
    isLoadingProducts: productsQuery.isPending,
    products: productsQuery.isSuccess ? productsQuery.data.products : null,
    productsError: productsQuery.isError ? productsQuery.error.message : null,
  };
};

export default useProductLoader;
