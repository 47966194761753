import classNames from "classnames";

import Spinner from "./Spinner";
import Translated from "./Translated";
import ImageSet from "../feature/ImageSet";
import { useUserProfile } from "../../api-new";
import { useUserData } from "../../context/UserDataContext";

const ProfileAutoliitto = (props) => {
  const { userId } = useUserData();
  const profileQuery = useUserProfile(userId);

  if (profileQuery.isPending) {
    return <Spinner />;
  }

  if (profileQuery.isError) {
    return (
      <div className="white padded">
        <Translated translationKey={profileQuery.error.message} />
      </div>
    );
  }

  const profile = profileQuery.data;
  const logoSource = "../../assets/AL-logo.png";
  const classes = classNames(
    props.className,
    "user-profile-container",
    "fade-in"
  );

  return (
    <div id="profile-student-container" className={classes}>
      <div className="user-profile-data">
        <br />
        <ImageSet src={logoSource} />
        <h5>
          &nbsp;
          <Translated translationKey="user_id" />: {profile.userData.name}
        </h5>
      </div>
    </div>
  );
};

export default ProfileAutoliitto;
