import Alert from "react-bootstrap/Alert";

import Translated from "../../Translated";
import CategorySelector from "./../../CategorySelector";
import CategoryStart from "./../../CategoryStart";
import Spinner from "../../Spinner";
import ResetVehicleCategoryButton from "../../ResetVehicleCategoryButton";
import { useUserData } from "../../../../context/UserDataContext";

const LiteralsStart = (props) => {
  const { userSettings } = useUserData();

  if (!userSettings.data) {
    return <Spinner />;
  }

  const category = userSettings.vehicleCategory;
  if (category === null) {
    return <CategorySelector />;
  }

  const settingsData = userSettings.data;
  const settings = settingsData.categorySettings[category];
  const isLanguageSupported = settings.langs.indexOf(props.locale) !== -1;
  if (!isLanguageSupported) {
    return (
      <div>
        <Alert>
          <Translated translationKey="category_unsupported_lang" />
        </Alert>
      </div>
    );
  } else {
    return (
      <div>
        <div>
          <h3>
            <Translated translationKey="literals_title" />
          </h3>
          <Translated translationKey="literals_description" />
        </div>
        <br />
        <CategoryStart
          id="practice-literals-start"
          category={category}
          start={props.start}
        />
        <ResetVehicleCategoryButton bsstyle="link" />
      </div>
    );
  }
};

export default LiteralsStart;
