import Alert from "react-bootstrap/Alert";
import Spinner from "./Spinner";
import Translated from "./Translated";
import UserList from "../feature/UserList";
import { useUserData } from "../../context/UserDataContext";
import { useLatestAcceptedUsers } from "../../api-new";

const LatestAcceptedUsers = () => {
  const { schoolId } = useUserData();
  const latestAcceptedQuery = useLatestAcceptedUsers(schoolId);

  const renderUserList = () => {
    if (latestAcceptedQuery.isPending) {
      return <Spinner className="primary" />;
    }

    if (latestAcceptedQuery.isError) {
      return (
        <Alert>
          <Translated translationKey={latestAcceptedQuery.error.message} />
        </Alert>
      );
    }

    const acceptedStudents = latestAcceptedQuery.data.users;
    return (
      <div>
        <UserList
          users={acceptedStudents}
          noUsersTranslationKey="no_search_results"
        />
      </div>
    );
  };

  return (
    <div>
      <h3>
        <Translated translationKey="last_approved_students" />
      </h3>
      {renderUserList()}
    </div>
  );
};

export default LatestAcceptedUsers;
