import { useParams } from "react-router-dom";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { IoAlertOutline } from "react-icons/io5";

import Spinner from "./../feature/Spinner";
import Translated from "./../feature/Translated";
import DateFormat from "../../utils/date";
import { useUserData } from "../../context/UserDataContext";
import { useLatestOneTimeKeyOrders, useOneTimeKeyOrder } from "../../api-new";
import { useTranslation } from "../feature/TranslationProvider";

const READER_URL = "https://get.adobe.com/reader/";

const OrdersPage = () => {
  const { token, schoolId: schoolIdContext } = useUserData();
  const { translate } = useTranslation();
  const { schoolId: schoolIdParam } = useParams();
  const [deliveryMethod, setDeliveryMethod] = useState(null);
  const schoolId = schoolIdParam || schoolIdContext;
  const latestKeysQuery = useLatestOneTimeKeyOrders(schoolId);
  const keysOrderMutation = useOneTimeKeyOrder();

  const postOrder = () => {
    if (token) {
      keysOrderMutation.mutate({ schoolId, deliveryMethod });
    }
  };

  const renderLoadingForm = () => {
    return (
      <div>
        <Spinner className="primary" />
      </div>
    );
  };

  const renderErrorForm = (errorMessage) => {
    return (
      <div>
        <Alert>
          <Translated translationKey={errorMessage} />
        </Alert>
      </div>
    );
  };

  const renderCannotOrderMoreForm = () => {
    const lastOrder = latestKeysQuery.data.orders[0];
    const minDaysSinceLast = latestKeysQuery.data.minDaysSinceLast;

    return (
      <Alert className="extra-side-margin">
        <h4>
          <Translated translationKey="last_order" />
        </h4>
        <div>
          <Translated
            translationKey="order_quarantine"
            values={{ days: minDaysSinceLast }}
          />
        </div>
        <div>
          <div>
            <Translated translationKey="date" />:{" "}
            {DateFormat.dateTime(lastOrder.date)}
          </div>
          {lastOrder.deliveryMethod === "email" ? (
            <div>
              <Translated
                translationKey="previous_delivery"
                values={{ address: lastOrder.deliveryAddress }}
              />
            </div>
          ) : (
            <div>
              <Translated translationKey="delivery_address" />:{" "}
              {lastOrder.deliveryAddress}
            </div>
          )}
        </div>
      </Alert>
    );
  };

  const renderSubmittedForm = () => {
    const lastOrder = keysOrderMutation.data.orders[0];

    return (
      <Alert>
        <h4>
          <Translated translationKey="order_sent" />
        </h4>
        <div>
          <Translated translationKey="delivery_address" />:{" "}
          {lastOrder.deliveryAddress}
        </div>
      </Alert>
    );
  };

  const renderOpenForm = () => {
    const school = latestKeysQuery.data.school || "";
    const emailAddress = school?.email || "";
    const postAddress = school?.streetAddress || "";

    return (
      <div>
        <Form.Check
          type="radio"
          name="deliveryMethod"
          value="email"
          label={translate("by_email") + ": " + emailAddress}
          onChange={(e) => {
            if (e.target.checked) {
              setDeliveryMethod("email");
            }
          }}
        >
          {/*<Translated translationKey="by_email"/>: {emailAddress}*/}
        </Form.Check>
        <Form.Check
          type="radio"
          name="deliveryMethod"
          value="post"
          label={translate("by_post") + ": " + postAddress}
          onChange={(e) => {
            if (e.target.checked) {
              setDeliveryMethod("post");
            }
          }}
        >
          {/*<Translated translationKey="by_post"/>: {postAddress}*/}
        </Form.Check>
        <div className="field-spacer"></div>
        <Button disabled={deliveryMethod === null} onClick={postOrder}>
          <Translated translationKey="send" />
        </Button>
      </div>
    );
  };

  let form;
  if (latestKeysQuery.isPending || keysOrderMutation.isPending) {
    form = renderLoadingForm();
  } else if (keysOrderMutation.isSuccess) {
    form = renderSubmittedForm();
  } else if (keysOrderMutation.isError || latestKeysQuery.isError) {
    const errorMessage = keysOrderMutation.isError
      ? keysOrderMutation.error.message
      : latestKeysQuery.error.message;
    form = renderErrorForm(errorMessage);
  } else if (latestKeysQuery.isSuccess && !latestKeysQuery.data.canOrderMore) {
    form = renderCannotOrderMoreForm();
  } else {
    form = renderOpenForm();
  }

  return (
    <div
      id="orders-page"
      className="page-container max-width-container fade-in"
    >
      <h3>
        <Translated translationKey="order_passes" />
      </h3>
      <p>
        <Translated translationKey="order_passes_info" />
        <br />
        <span id="link-to-adobe-reader">
          <a href={READER_URL} target="_blank" rel="noopener noreferrer">
            <Translated translationKey="link_text_to_reader" />
          </a>
        </span>
      </p>
      <Alert>
        <IoAlertOutline />
        &nbsp;
        <Translated translationKey="one_time_key_usage" />
      </Alert>
      {form}
    </div>
  );
};

export default OrdersPage;
