import { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Button from "react-bootstrap/Button";
import FormGroup from "react-bootstrap/FormGroup";
import FormControl from "react-bootstrap/FormControl";
import { IoChatbubblesSharp } from "react-icons/io5";

import Translated from "../../feature/Translated";
import Spinner from "../../feature/Spinner";
import { useTranslation } from "../TranslationProvider";
import { useQuestionReport } from "../../../api-new";

const ResultFeedbackForm = (props) => {
  const { translate } = useTranslation();
  const [show, setShow] = useState(!!props.keepOpen);
  const [message, setMessage] = useState("");
  const questionReportMutation = useQuestionReport();

  const toggle = () => {
    setShow((prev) => !prev);
  };

  const handleSubmit = () => {
    questionReportMutation.mutate({
      questionType: props.questionType,
      questionId: props.questionId,
      message,
    });
  };

  let content;
  let form;

  if (questionReportMutation.isError) {
    console.log(questionReportMutation.error)
    content = (
      <Translated translationKey={questionReportMutation.error.message} />
    );
  } else if (questionReportMutation.isSuccess) {
    content = <Translated translationKey="feedback_sent" />;
  } else if (questionReportMutation.isPending) {
    content = <Spinner />;
  } else if (show) {
    let cancel;
    if (!props.keepOpen) {
      cancel = (
        <Button bsstyle="link" onClick={toggle}>
          <Translated translationKey="cancel" />
        </Button>
      );
    }
    const placeHolderKey = props.placeholderKey || "question_report_info";
    form = (
      <FormGroup onSubmit={handleSubmit}>
        <FormControl
          className="feedback-input"
          componentclass="textarea"
          placeholder={translate(placeHolderKey)}
          name="message"
          value={message}
          onChange={(e) => setMessage(e.currentTarget.value)}
        />
        <div className="field-spacer" />
        <div className="feedback-buttons">
          {cancel}
          <Button
            id="submit-result-feedback"
            type="submit"
            disabled={!message}
            onClick={handleSubmit}
          >
            <Translated translationKey="send" />
          </Button>
        </div>
      </FormGroup>
    );
  } else {
    content = (
      <div className="touchable" onClick={toggle}>
        <IoChatbubblesSharp className="larger text-bottom" />
        &nbsp;&nbsp;
        <Translated translationKey="question_report" className="underlined" />
      </div>
    );
  }

  return (
    <div
      className={classNames("question-feedback-form", props.className, {
        "full-width": show,
      })}
    >
      {content}
      {form}
    </div>
  );
};

ResultFeedbackForm.propTypes = {
  questionType: PropTypes.string.isRequired,
  questionId: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]),
  keepOpen: PropTypes.bool,
  placeholderKey: PropTypes.string,
};

export default ResultFeedbackForm;
