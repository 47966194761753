import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import BookMenuItem from "./BookMenuItem";
import MarkdownUtil from "../../utils/markdown";
import BookReadStatusMarker from "./BookReadStatusMarker";
import ActiveBookStatus from "./ActiveBookStatus";
import ResultFeedbackForm from "./results/ResultFeedbackForm";
import BookNavigation from "./BookNavigation";
import BookModalLink from "./BookModalLink";

/**
 * Book browser. Shows book main menu and chapters.
 * This browser shows also book read status and possible additional content.
 */
const BookBrowser = (props) => {
  const navigate = useNavigate();
  const chapterIndex = props.chapters.findIndex(
    (chapter) => chapter.id === props.chapterId
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [chapterIndex]);

  const openChapter = (index) => {
    if (index >= 0) {
      const chapter = props.chapters[index];
      navigate("/book/" + chapter.id);
    } else {
      navigate("/book");
    }
  };

  const renderChapter = () => {
    const chapter = chapterIndex >= 0 ? props.chapters[chapterIndex] : null;

    const navi = (
      <BookNavigation
        openChapter={openChapter}
        current={chapterIndex}
        total={props.chapters.length - 1}
      />
    );
    const status = getChapterStatus(chapter.id);
    const isRead = status.progress === 1;
    return (
      <div className="book-content">
        {navi}
        <BookReadStatusMarker
          key={chapter.id + "-status-top"}
          chapter={chapter}
          isRead={isRead}
          showProgress={false}
          sendStatus={false}
        />
        <div>
          {MarkdownUtil.render(chapter.markdown, chapter.id)}
          {renderChapterExtras(chapter)}
        </div>
        <BookReadStatusMarker
          key={chapter.id + "-status-bottom"}
          chapter={chapter}
          isRead={isRead}
          showProgress={true}
          sendStatus={true}
        />
        <ResultFeedbackForm
          key={chapter.id + "-feedback"}
          questionType="book-chapter"
          questionId={chapter.id}
          placeholderKey={"comment_book_chapter"}
        />
        {navi}
      </div>
    );
  };

  const renderChapterExtras = (chapter) => {
    // TODO Remove this hard coding when JSX Parser is usable again.
    // Add BookModalLink if chapter id ends with 'book_signs'
    if (chapter.id.endsWith("book_signs")) {
      const match = chapter.markdown.match(
        /<BookModalLink title="(.*)" contentId="public_all_signs".*\/>/
      );
      const title = Array.isArray(match) && match.length > 1 && match[1];
      return (
        title && (
          <BookModalLink
            title={title}
            contentId="public_all_signs"
            materialId="public"
          />
        )
      );
    }
    return null;
  };

  const renderMenu = () => {
    let useFlash = true;
    let menuItems = props.chapters.map((chapter, i) => {
      if (i === 0) {
        return null;
      }
      const status = getChapterStatus(chapter.id);

      let className;
      if (status.progress < 1 && useFlash) {
        className = "border-flash";
        useFlash = false;
      }

      return (
        <BookMenuItem
          key={i}
          index={i}
          title={chapter.title}
          onClick={() => openChapter(i)}
          progress={status.progress}
          showStatus={true}
          className={className}
        />
      );
    });

    return (
      <div className="fade-in">
        <ActiveBookStatus hideButton={true} />
        <div className="book-menu-items">{menuItems}</div>
      </div>
    );
  };

  const getChapterStatus = (chapterId) => {
    return props.status.chapterProgress.find(
      (progress) => progress.chapterId === chapterId
    );
  };

  if (chapterIndex > 0) {
    return renderChapter();
  } else {
    return renderMenu();
  }
};

export default BookBrowser;
