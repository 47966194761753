import { useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { IoChevronDown } from "react-icons/io5";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";

import FullTestButton from "./FullTestButton";
import Translated from "../Translated";
import { useTranslation } from "../TranslationProvider";

const FullTestQuestionSelectorForm = (props) => {
  const { translate } = useTranslation();
  const [showOverlay, setShowOverlay] = useState(false);
  const overlayTargetRef = useRef(null);

  const onChange = (e) => {
    e.target.blur(); // Mobile Safari cannot do unbinding without blurring.
    const index = parseInt(e.target.value);
    props.select(index);
  };

  const onHide = () => {
    setShowOverlay(false);
  };

  const renderOverlay = () => {
    return (
      <Overlay
        show={showOverlay}
        target={overlayTargetRef.current}
        placement="top"
        onHide={onHide}
        rootClose
      >
        <Popover id="question-selector-tooltip">
          <Popover.Body>
            <Translated translationKey="select_question_info" />
          </Popover.Body>
        </Popover>
      </Overlay>
    );
  };

  const allowSelecting =
    Object.values(props.answers).length === props.questions.length;
  const onClick = (e) => {
    if (!allowSelecting) {
      overlayTargetRef.current = e.target;
      setShowOverlay(true);
    }
  };
  let select;
  if (allowSelecting) {
    select = (
      <select onChange={onChange} value={props.index}>
        {props.questions.map((question, index) => {
          const marked =
            props.answers[question.id] === "d"
              ? " " + translate("marked")
              : null;
          return (
            <option key={index} value={index}>
              {index + 1 + marked}
            </option>
          );
        })}
      </select>
    );
  }

  return (
    <div className="hide-sm-and-gt">
      <FullTestButton onClick={onClick} id="full-test-question-selector-form">
        <div
          id="question-selector-form"
          className={classNames("question-selector-form", props.className)}
        >
          <span>{props.index + 1}</span>
          <IoChevronDown />
          {select}
        </div>
      </FullTestButton>
      {renderOverlay()}
    </div>
  );
};

FullTestQuestionSelectorForm.propTypes = {
  answers: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  select: PropTypes.func.isRequired,
};

export default FullTestQuestionSelectorForm;
