import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import Translated from "./../feature/Translated";
import Tracker from "../../utils/tracker";

const ErrorModal = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    Tracker.logEventLabel("error", "error-modal-shown", props.errorKey);
  }, []);

  const onCloseClick = () => {
    if (props.onClick) {
      props.onClick();
    } else {
      navigate("/");
    }
  };

  return (
    <div>
      <Modal show={props.show}>
        <Modal.Body>
          <Translated translationKey={props.errorKey} />
        </Modal.Body>
        <Modal.Footer>
          <Button id="close-error-modal-button" onClick={onCloseClick}>
            <Translated translationKey="close" />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ErrorModal;
