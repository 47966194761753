import { useRef } from "react";
import Card from "react-bootstrap/Card";
import Alert from "react-bootstrap/Alert";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";

import Spinner from "./Spinner";
import Translated from "./Translated";
import {
  useSchoolCategorySettings,
  useUpdateSchoolCategorySettings,
} from "../../api-new";
import { useUserData } from "../../context/UserDataContext";
import { useTranslation } from "./TranslationProvider";

const PractiseLimits = (props) => {
  const { schoolId } = useUserData();
  const { translate } = useTranslation();
  const updatingCategory = useRef(null);
  const schoolCatSettings = useSchoolCategorySettings(schoolId);
  const schoolCatMutation = useUpdateSchoolCategorySettings();

  const updateLimit = (category, property, value) => {
    const currentSettings = schoolCatSettings.data.settings[category];
    const newSettings = { ...currentSettings, [property]: value };
    updatingCategory.current = category;

    schoolCatMutation.mutate(
      {
        schoolId,
        category,
        settings: newSettings,
      },
      {
        onSettled: () => {
          updatingCategory.current = null;
        },
      }
    );
  };

  const renderLoadingContent = () => {
    return (
      <div>
        <Spinner />
      </div>
    );
  };

  const renderErrorContent = (errorMessage) => {
    return (
      <div>
        <Alert>
          <Translated translationKey={errorMessage} />
        </Alert>
      </div>
    );
  };

  const buildDropDown = (
    id,
    min,
    max,
    value,
    labelKey,
    isCloseToBottom,
    onChange
  ) => {
    const label = translate(labelKey);

    let selections = [];
    for (let i = min; i <= max; i++) {
      selections.push(i);
    }

    const dropDir = isCloseToBottom ? "up" : "down";

    return (
      <ButtonToolbar className="settings-category-limit">
        <DropdownButton
          id={id}
          bsstyle="warning"
          drop={dropDir}
          title={label + " " + value}
        >
          {selections.map((selectableValue) => {
            return (
              <Dropdown.Item
                id={selectableValue}
                key={selectableValue}
                onClick={() => {
                  onChange(selectableValue);
                }}
              >
                <span>
                  {selectableValue === value ? (
                    <span className="settings-selected-dropdown-value ">
                      {selectableValue}
                    </span>
                  ) : (
                    selectableValue
                  )}
                </span>
              </Dropdown.Item>
            );
          })}
        </DropdownButton>
      </ButtonToolbar>
    );
  };

  const buildPanelContent = (categoryData, isCloseToBottom) => {
    const category = categoryData.id;
    const limits = schoolCatSettings.data.limits;
    const litMin = limits.minSettableLiteralFaults;
    const litMax = limits.maxSettableLiteralFaults;
    const imgMin = limits.minSettableImageFaults;
    const imgMax = limits.maxSettableImageFaults;
    const riskMin = limits.minSettableRiskFaults;
    const riskMax = limits.maxSettableRiskFaults;
    const minReq = limits.minSettableRequired;
    const maxReq = limits.maxSettableRequired;

    const settings = schoolCatSettings.data.settings[category];
    const litCurr = settings.literalFaultsMax;
    const imgCurr = settings.imageFaultsMax;
    const riskCurr = settings.riskFaultsMax;
    const reqCurr = settings.requiredPasses;

    const savingThisCategory =
      category === updatingCategory.current && schoolCatMutation.isPending;

    return (
      <fieldset disabled={schoolCatMutation.isPending}>
        <div
          id={"practise-limits-category-" + category}
          className="settings-category-container"
        >
          <div className="settings-category-section">
            {categoryData.literals &&
              buildDropDown(
                category + "lit",
                litMin,
                litMax,
                litCurr,
                "literals",
                isCloseToBottom,
                (value) => {
                  updateLimit(category, "literalFaultsMax", value);
                }
              )}
            {categoryData.images &&
              buildDropDown(
                category + "img",
                imgMin,
                imgMax,
                imgCurr,
                "images",
                isCloseToBottom,
                (value) => {
                  updateLimit(category, "imageFaultsMax", value);
                }
              )}
            {categoryData.risks &&
              buildDropDown(
                category + "risk",
                riskMin,
                riskMax,
                riskCurr,
                "risks",
                isCloseToBottom,
                (value) => {
                  updateLimit(category, "riskFaultsMax", value);
                }
              )}
          </div>
          <div className="settings-category-section">
            {buildDropDown(
              category + "req",
              minReq,
              maxReq,
              reqCurr,
              "required",
              isCloseToBottom,
              (value) => {
                updateLimit(category, "requiredPasses", value);
              }
            )}
          </div>
          <div className="settings-category-section">
            {savingThisCategory && <Spinner />}
          </div>
        </div>
      </fieldset>
    );
  };

  const buildPanelHeader = (category) => {
    const imageSrc = require("../../assets/category/" + category.id + ".svg");
    const categoryKey = "category_" + category.id.toLowerCase();
    return (
      <div className="gray">
        <span className="settings-panel-header-icon-wrapper">
          <img
            className="settings-panel-header-icon touchable"
            src={imageSrc}
          />
        </span>
        <Translated className="touchable" translationKey={categoryKey} />
      </div>
    );
  };

  const renderCategory = (category, index, isCloseToBottom) => {
    return (
      <Card key={index} eventkey={index} className="bottom-margin">
        <Card.Header>{buildPanelHeader(category)}</Card.Header>
        <Card.Body>{buildPanelContent(category, isCloseToBottom)}</Card.Body>
      </Card>
    );
  };

  const renderCategories = () => {
    return (
      <div className="fade-in">
        {schoolCatSettings.data.openCategories.map((category, index, arr) => {
          let isCloseToBottom = index > arr.length - 3;
          return renderCategory(category, index, isCloseToBottom);
        })}
      </div>
    );
  };

  let content;
  if (schoolCatSettings.isPending) {
    content = renderLoadingContent();
  } else if (schoolCatMutation.isError || schoolCatSettings.isError) {
    content = renderErrorContent(
      schoolCatMutation.error?.message || schoolCatSettings.error?.message
    );
  } else {
    content = renderCategories();
  }

  return (
    <div id="practise-limits" className={props.className}>
      <h3>
        <Translated translationKey="practise_settings" />
      </h3>
      <p>
        <Translated translationKey="practise_settings_info" />
      </p>
      {content}
    </div>
  );
};

export default PractiseLimits;
