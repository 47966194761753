import { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import { IoSearch } from "react-icons/io5";
import FormGroup from "react-bootstrap/FormGroup";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";

const TextInputForm = (props) => {
  const [fieldValue, setFieldValue] = useState(props.initialValue || "");

  const getInputPlaceholderText = () => {
    return props.placeholderText ? props.placeholderText : "";
  };

  const getSubmitText = () => {
    return props.submitText ? props.submitText : "";
  };

  const isInputValid = () => {
    const minLength = props.minLength ? props.minLength : 1;
    return fieldValue && fieldValue.length >= minLength;
  };

  const onSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (isInputValid()) {
      props.onSubmit(fieldValue);
    }
  };

  const renderIcon = (iconId) => {
    switch (iconId) {
      case "search":
        return <IoSearch />;
      default:
        return null;
    }
  };

  const renderAddon = () => {
    if (props.iconAddon) {
      return <InputGroup.Text>{renderIcon(props.iconAddon)}</InputGroup.Text>;
    }
    return null;
  };

  const renderSubmit = () => {
    return (
      <InputGroup>
        <Button
          id="text-field-input"
          disabled={props.disabled || !isInputValid()}
          onClick={onSubmit}
        >
          {getSubmitText()}
        </Button>
      </InputGroup>
    );
  };

  const renderField = () => {
    return (
      <FormControl
        disabled={props.disabled}
        type="text"
        name="fieldValue"
        placeholder={getInputPlaceholderText()}
        value={fieldValue}
        onChange={(e) => setFieldValue(e.target.value)}
      />
    );
  };

  return (
    <div>
      <form onSubmit={onSubmit} className={props.className}>
        <FormGroup>
          <InputGroup>
            {renderAddon()}
            {renderField()}
            {renderSubmit()}
          </InputGroup>
        </FormGroup>
      </form>
    </div>
  );
};

TextInputForm.propTypes = {
  onSubmit: PropTypes.func,
  placeholderText: PropTypes.string,
  submitText: PropTypes.string,
  minLength: PropTypes.number,
  iconAddon: PropTypes.string,
  className: PropTypes.string,
  initialValue: PropTypes.string,
};

export default TextInputForm;
