import TestResultsList from "../feature/results/TestResultsList";
import Translated from "../feature/Translated";
import { useUserData } from "../../context/UserDataContext";
import { useNavigate, useParams } from "react-router-dom";

const ResultsPage = () => {
  const { userId: userIdContext } = useUserData();
  const { userId: userIdParam } = useParams();
  const navigate = useNavigate();

  const userId = userIdParam ?? userIdContext;

  const userInfo = !userIdParam ? null : (
    <div
      className="blue-box vertical-margins touchable"
      onClick={() => navigate("/user/" + userId)}
    >
      <Translated translationKey="user_id" />: {userId}
    </div>
  );

  return (
    <div id="results-page" className="page-container max-width-container">
      <h3>
        <Translated translationKey="all_test_results" />
      </h3>
      {userInfo}
      <TestResultsList userId={userId} />
    </div>
  );
};

export default ResultsPage;
